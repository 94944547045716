import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useForm,
} from 'react-hook-form-6';
import { useCallback, useState } from 'react';
import { Input } from 'components/react-hook-form-6/input';
import { Dropdown } from 'components/dropdown';
import { TextArea } from 'components/text-area';
import { problemTypeOptions } from 'utils/dropdown-options';
import { Button } from 'components/button';
import {
  CreateSequenceGetBasicInfoQuery,
  CreateSequenceMutation,
  CreateSequenceMutationVariables,
  CreateSequenceNodeInput,
  ProblemType,
} from 'graphql/types';
import { Label } from 'components/label';
import { ValueType } from 'react-select';
import { gql, useMutation, useQuery } from '@apollo/client';
import { requiredValidation } from 'utils/form-validation';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useNotifications } from 'notifications';
import { SequenceEditor } from 'components/sequence-editor/editor';
import { Node } from 'components/sequence-editor/node';
import { Loading } from 'components/loading';
import Creatable from 'react-select/creatable';

const formSectionClasses = 'border-b border-gray-600 pb-6';
const formSectionHeadingClasses = 'heading-md py-2';

type CreateSequenceForm = {
  friendlyName: string;
  internalName: string;
  problemTypes: ProblemType[];
  description: string;
  nodes: Array<Node>;
  tags: {
    label: string;
    value: string;
  }[];
};

export const CreateSequencePage = (): JSX.Element => {
  const history = useHistory();
  const showNotification = useNotifications();

  const featureFlagClient = useFeatureFlagClient();
  const validInternalTriggerTopics = featureFlagClient.getJson(
    'internal-trigger-valid-message-topics',
    { defaultValue: [] },
  );

  const { handleSubmit, register, control, errors, setValue } =
    useForm<CreateSequenceForm>({
      defaultValues: { nodes: [], problemTypes: [] },
      reValidateMode: 'onChange',
    });
  const [pendingNodeChanges, setPendingNodeChanges] = useState(false);
  // These useCallbacks are annoying, but required to avoid multiple calls to
  // these functions on a rerender of CreateSequencePage
  const setPendingNodeChangesTrue = useCallback(
    () => setPendingNodeChanges(true),
    [],
  );
  const setPendingNodeChangesFalse = useCallback(
    () => setPendingNodeChanges(false),
    [],
  );

  const { data, error, loading } = useQuery<CreateSequenceGetBasicInfoQuery>(
    gql`
      query CreateSequenceGetBasicInfo {
        variants {
          id
          name
          inventory {
            id
            sku
          }
          product {
            id
            name
            flexiCompatible
            productType
          }
        }
        selectableSequenceTags
      }
    `,
  );

  const [createSequenceMutation] = useMutation<
    CreateSequenceMutation,
    CreateSequenceMutationVariables
  >(
    gql`
      mutation CreateSequence($createSequenceInput: CreateSequenceInput!) {
        createSequence(input: $createSequenceInput) {
          sequence {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        history.push(`${routes.sequences}/${data.createSequence?.sequence.id}`);

        showNotification({
          type: 'success',
          message: 'Sequence created successfully',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error creating sequence',
        });
        // Ensure save / discard buttons are shown in sequence editor
        // to allow another attempt at saving
        setValue('nodes', []);
      },
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        Error - Unable to load variants, please contact #help-technology.
      </div>
    );
  }

  const submitHandler = async (
    formValues: CreateSequenceForm,
  ): Promise<void> => {
    const sn: CreateSequenceNodeInput[] = [];

    for (const n of formValues.nodes) {
      let node: CreateSequenceNodeInput;

      switch (n.nodeType) {
        case 'wait': {
          node = {
            id: n.id,
            nextNodeId: n.nextNodeId,
            type: 'WAIT',
            wait: {
              count: n.count,
              interval: n.intervalType,
            },
          };
          break;
        }
        case 'event': {
          node = {
            id: n.id,
            nextNodeId: n.nextNodeId,
            type: 'EVENT',
            event: {
              body: '',
              name: n.name,
            },
          };
          break;
        }
        case 'variant': {
          node = {
            id: n.id,
            nextNodeId: n.nextNodeId,
            type: 'VARIANT',
            variant: {
              id: n.variantId,
              price: n.price,
              quantity: n.quantity,
              maximumDurationInDaysToNextVariantNode:
                n.maximumDurationInDaysToNextVariantNode,
            },
          };
          break;
        }
        case 'internal_trigger': {
          node = {
            id: n.id,
            nextNodeId: n.nextNodeId,
            type: 'INTERNAL_TRIGGER',
            internalTrigger: {
              name: n.triggerName,
              topic: n.topic,
              label: n.label,
            },
          };
          break;
        }
      }

      sn.push(node);
    }

    const createSequenceInput: CreateSequenceMutationVariables['createSequenceInput'] =
      {
        description: formValues.description,
        internalName: formValues.internalName,
        friendlyName: formValues.friendlyName,
        nodes: sn,
        problemTypes: formValues.problemTypes ?? [],
        startNodeId: sn[0].id,
        status: 'DRAFT',
        tags: formValues.tags.map(({ value }) => value),
      };

    await createSequenceMutation({
      variables: { createSequenceInput },
    });
  };

  const variants: Parameters<typeof SequenceEditor>[0]['variants'] = [];
  for (const v of data?.variants ?? []) {
    if (v.product.flexiCompatible) {
      variants.push({
        id: v.id,
        name: v.name,
        productId: v.product.id,
        productName: v.product.name,
        productType: v.product.productType,
        sku: v.inventory?.sku || '',
        maximumDurationInDaysToNextVariantNode: null,
      });
    }
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={formSectionClasses}>
          <div className={formSectionHeadingClasses}>general</div>
          <div className="flex flex-col space-y-4">
            <Input
              name="internalName"
              label="Internal Name"
              ref={register(requiredValidation('name'))}
              errorMessage={errors.internalName?.message}
            />
            <Input
              name="friendlyName"
              label="Patient Facing Friendly Name"
              ref={register(requiredValidation('patient facing name'))}
              errorMessage={errors.friendlyName?.message}
            />
            <Dropdown
              name="problemTypes"
              label="Problem Types"
              control={control}
              options={problemTypeOptions}
              rules={{
                ...requiredValidation('problemType'),
                validate: {
                  notEmpty: (v) =>
                    v.length === 0 ? 'Problem type is required' : undefined,
                },
              }}
              isMulti
              errorMessage={
                (errors.problemTypes as unknown as FieldError | undefined)
                  ?.message
              }
            />
            <TextArea
              name="description"
              label="Description"
              rows={8}
              placeholder=""
              ref={register(requiredValidation('description'))}
              errorMessage={errors.description?.message}
            />
            <div>
              <div className="mb-3 flex flex-col gap-1">
                <Label
                  htmlFor={'tags'}
                  tooltip={{
                    hoverText: 'Add tags to a sequence.',
                  }}
                >
                  Tags
                </Label>
              </div>
              <Controller
                defaultValue={[]}
                render={({ value, onChange, onBlur, name, ref }) => (
                  <Creatable
                    isMulti={true}
                    inputId="tags"
                    options={(data?.selectableSequenceTags ?? []).map(
                      (tag) => ({
                        label: tag,
                        value: tag,
                      }),
                    )}
                    className="dropdown"
                    classNamePrefix="dd"
                    innerRef={ref}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={(v: ValueType<{ value: string }, true>): void => {
                      if (v === null || v === undefined) {
                        onChange([]);
                      } else {
                        onChange(v);
                      }
                    }}
                  />
                )}
                name="tags"
                control={control}
              />
            </div>
          </div>
        </div>

        <div className={formSectionClasses}>
          <div className={formSectionHeadingClasses}>Nodes</div>
          <div className="h-128 bg-white rounded shadow">
            <Controller
              name="nodes"
              control={control}
              render={({
                onChange,
                value,
              }: {
                onChange: ControllerRenderProps['onChange'];
                value: Array<Node>;
              }) => (
                <SequenceEditor
                  variants={variants}
                  onSave={onChange}
                  onDirty={setPendingNodeChangesTrue}
                  onReset={setPendingNodeChangesFalse}
                  nodes={value}
                  validTopics={validInternalTriggerTopics}
                />
              )}
            ></Controller>
          </div>
        </div>
        <div className="m-2 w-1/4">
          <Button
            fullWidth
            type="submit"
            disabled={pendingNodeChanges}
            hoverText={
              pendingNodeChanges
                ? 'There are changes to nodes that need saving'
                : undefined
            }
          >
            Create Sequence
          </Button>
        </div>
      </form>
    </div>
  );
};
