import React from 'react';
import { useForm } from 'react-hook-form-6';
import { gql, useMutation } from '@apollo/client';
import {
  UpdateTrackerEntryMutation,
  UpdateTrackerEntryMutationVariables,
} from 'graphql/types';
import { Button } from 'components/button';
import { Input } from 'components/react-hook-form-6/input';
import { Modal } from 'components/modal';
import { TrackerActionModalProps } from './types';
import {
  combineRules,
  maxLengthValidation,
  minLengthValidation,
  positiveFloatValidation,
  requiredValidation,
} from 'utils/form-validation';
import { useNotifications } from 'notifications';
import { TrackerDisplayInfo } from './tracker-display-info';

export const UpdateModal = ({
  observationId,
  show,
  onClose,
  entryDate,
  lastUpdatedAtDate,
  type,
  value,
  onActionComplete,
}: TrackerActionModalProps): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const showNotification = useNotifications();
  const { register, errors, handleSubmit } = useForm<{
    value: string;
    description: string;
  }>();
  const [updateTrackerEntry] = useMutation<
    UpdateTrackerEntryMutation,
    UpdateTrackerEntryMutationVariables
  >(gql`
    mutation UpdateTrackerEntry($input: UpdateTrackerEntryInput!) {
      updateTrackerEntry(input: $input) {
        ok
      }
    }
  `);

  const handleFormSubmit = handleSubmit(async ({ value, description }) => {
    try {
      setLoading(true);
      await updateTrackerEntry({
        variables: {
          input: {
            observationId,
            value,
            description,
          },
        },
      });
      showNotification({
        type: 'success',
        message: 'Update succesful',
      });
      await onActionComplete();
    } catch {
      showNotification({
        type: 'success',
        message: 'Update failed',
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal show={show} onClose={onClose} width="max-w-screen-sm">
      <form
        className="bg-white rounded-md p-6 space-y-4"
        onSubmit={handleFormSubmit}
      >
        <h3 className="heading-md">Update tracker entry</h3>
        <div className="grid grid-cols-3 gap-x-3 gap-y-4">
          <TrackerDisplayInfo
            {...{ entryDate, lastUpdatedAtDate, type, value }}
          />
          <label htmlFor="value" className="font-medium col-span-1">
            New value
          </label>
          <div className="col-span-2 w-1/2">
            <Input
              name="value"
              ref={register(
                combineRules(
                  requiredValidation('value'),
                  positiveFloatValidation('value'),
                ),
              )}
              errorMessage={errors?.value?.message}
            />
          </div>
          <label htmlFor="description" className="font-medium col-span-1">
            Reason
          </label>
          <div className="col-span-2">
            <Input
              name="description"
              ref={register(
                combineRules(
                  requiredValidation('reason'),
                  minLengthValidation('reason', 5),
                  maxLengthValidation('reason', 100),
                ),
              )}
              errorMessage={errors?.description?.message}
            />
          </div>
        </div>
        <div className="flex space-x-3 max-w-xs ml-auto">
          <Button
            fullWidth
            variant="outline"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="success"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};
