import { gql, useQuery } from '@apollo/client';
import { Loading } from '../../../components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePageIndex,
  usePaginatingSortingTable,
} from '../../../components/table';
import { Column } from 'react-table';
import {
  GetCouponCampaignSummariesQuery,
  GetCouponCampaignSummariesQueryVariables,
} from 'graphql/types';
import { formatDate } from 'utils/misc';
import { Tag } from 'components/tag';
import { useUrlQuery } from 'utils/use-url-query';
import { useForm } from 'react-hook-form-6';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import { useCallback, useState } from 'react';
import { useChangeUrl } from 'utils/user-change-url';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Button } from 'components/button';
import { useHasPermissions } from 'components/permissions';
import { CreateCampaignModal } from './create-campaign-modal';

const pageSize = 20;
type CouponCampaignSummary = NonNullable<
  GetCouponCampaignSummariesQuery['couponCampaignSummaries']
>['summaries'][number];

let updateSearchParamTimeout: NodeJS.Timeout | undefined = undefined;

export const CouponCampaignSummaries = (): React.ReactElement => {
  const pageIndex = usePageIndex();
  const history = useHistory();

  const canEditCouponCampaigns = useHasPermissions(['EDIT_COUPON_CAMPAIGNS']);

  const urlQuery = useUrlQuery();

  const filter = {
    searchText: urlQuery.get('searchText')?.trim() ?? '',
    strategy: urlQuery.get('strategy') ?? '',
    stage: urlQuery.get('stage') ?? '',
    status: urlQuery.get('status') ?? '',
  };

  const hasActiveFilters = !!Object.values(filter).join('');

  const form = useForm<typeof filter>({
    defaultValues: filter,
  });

  const [showCampaignCreationModal, setShowCampaignCreationModal] =
    useState(false);

  const changeUrl = useChangeUrl();

  const debounceUpdate = useCallback(
    (timeout = 0) => {
      clearTimeout(updateSearchParamTimeout);
      updateSearchParamTimeout = setTimeout(() => {
        changeUrl({ params: form.getValues() });
      }, timeout);
    },
    [changeUrl, form],
  );

  const {
    data: currentData,
    previousData,
    loading,
    error,
  } = useQuery<
    GetCouponCampaignSummariesQuery,
    GetCouponCampaignSummariesQueryVariables
  >(
    gql`
      query GetCouponCampaignSummaries(
        $input: CouponCampaignSummariesInput!
        $campaignCreationModalOpen: Boolean!
      ) {
        couponCampaignSummaries(input: $input) {
          summaries {
            id
            createdAt
            couponCampaignId
            displayName
            description
            status
            strategy
            stage
          }
          totalCount
        }
        ...CampaignCreationModal @include(if: $campaignCreationModalOpen)
      }
      ${CreateCampaignModal.fragment}
    `,
    {
      variables: {
        campaignCreationModalOpen: showCampaignCreationModal,
        input: {
          page: {
            pageSize,
            page: pageIndex + 1,
          },
          status: (() => {
            switch (filter.status) {
              case 'RUNNING':
              case 'ARCHIVED':
              case 'DISABLED':
              case 'SCHEDULED':
                return filter.status;
              default:
                return undefined;
            }
          })(),
          strategy: (() => {
            switch (filter.strategy) {
              case 'FIXED':
              case 'PERCENTAGE':
                return filter.strategy;
              default:
                return undefined;
            }
          })(),
          stage: (() => {
            switch (filter.stage) {
              case 'MID_SEQUENCE':
              case 'INITIAL':
                return filter.stage;
              default:
                return undefined;
            }
          })(),
          searchText: filter.searchText !== '' ? filter.searchText : undefined,
        },
      },
    },
  );

  const data = currentData ?? previousData;
  const total = data?.couponCampaignSummaries?.totalCount ?? 1;

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.couponCampaignSummaries?.summaries ?? [],
    pageNumber: Math.ceil(total / pageSize),
    disableSortBy: true,
    pageSize,
    pageIndex,
  });

  if (loading && !data) {
    return <Loading />;
  }

  if (error && !data?.couponCampaignSummaries?.summaries.length) {
    return (
      <div>
        Error - Unable to load campaign summaries, please contact
        #help-technology.
      </div>
    );
  }

  return (
    <div
      ref={(el) => {
        if (!el) {
          clearTimeout(updateSearchParamTimeout);
        }
      }}
    >
      <form>
        <>
          <div className="flex justify-between mb-8">
            <div className="w-1/3">
              <Input
                ref={form.register()}
                onChange={() => debounceUpdate(1000)}
                name="searchText"
                label=""
                placeholder="Campaign name"
              />
            </div>
            <div className="flex gap-2 items-center">
              {hasActiveFilters && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    form.reset({});
                    changeUrl({ params: {}, reset: true });
                  }}
                >
                  Clear filters
                </Button>
              )}
              <Button
                type="button"
                onClick={() => {
                  setShowCampaignCreationModal(true);
                }}
                disabled={!canEditCouponCampaigns}
                loading={false}
              >
                Create new
              </Button>
            </div>
          </div>
          <div className="flex space-x-2 mb-8">
            <div className="w-1/4">
              <Dropdown
                name="strategy"
                handleSingleSelect={() => debounceUpdate(0)}
                label="Discount type"
                options={[
                  { label: 'Fixed', value: 'FIXED' },
                  { label: 'Percentage', value: 'PERCENTAGE' },
                ]}
                control={form.control}
              />
            </div>
            <div className="w-1/4">
              <Dropdown
                name="stage"
                handleSingleSelect={() => debounceUpdate(0)}
                label="Stage"
                options={[
                  { label: 'First order', value: 'INITIAL' },
                  { label: 'Mid-sequence', value: 'MID_SEQUENCE' },
                ]}
                control={form.control}
              />
            </div>
            <div className="w-1/4">
              <Dropdown
                name="status"
                handleSingleSelect={() => debounceUpdate(0)}
                label="Status"
                options={[
                  { label: 'Active', value: 'RUNNING' },
                  { label: 'Archived', value: 'ARCHIVED' },
                  { label: 'Disabled', value: 'DISABLED' },
                  { label: 'Scheduled', value: 'SCHEDULED' },
                ]}
                control={form.control}
              />
            </div>
          </div>
        </>
      </form>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={(): void => {
                        history.push(
                          `${routes.couponCampaignSummaries}/${cell.row.original.couponCampaignId}`,
                        );
                      }}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
      <Pagination total={total} tableInstance={tableInstance} />
      <CreateCampaignModal
        loading={loading}
        variants={data?.variants ?? []}
        show={showCampaignCreationModal}
        templates={data?.couponCampaignTemplates}
        onClose={() => setShowCampaignCreationModal(false)}
      />
    </div>
  );
};

const columns: Column<CouponCampaignSummary>[] = [
  {
    Header: 'Campaign name',
    Cell: (c) => <div>{c.row.original.displayName}</div>,
    link: (c) => `${routes.couponCampaignSummaries}/${c.couponCampaignId}`,
    className: 'w-1/5',
  },
  {
    Header: 'Discount type',
    Cell: (c) => (
      <div>
        {(() => {
          switch (c.row.original.strategy) {
            case 'FIXED':
              return 'Fixed';
            case 'PERCENTAGE':
              return 'Percentage';
            default:
              return 'Unknown';
          }
        })()}
      </div>
    ),
    className: 'w-1/5',
  },
  {
    Header: 'Stage',
    Cell: (c) => (
      <div>
        {(() => {
          switch (c.row.original.stage) {
            case 'INITIAL':
              return 'First order';
            case 'MID_SEQUENCE':
              return 'Mid-sequence';
            default:
              return 'Unknown';
          }
        })()}
      </div>
    ),
    className: 'w-1/5',
  },
  {
    Header: 'Status',
    Cell: (c) => (
      <div>
        {(() => {
          switch (c.row.original.status) {
            case 'RUNNING':
              return (
                <Tag size="small" color="green">
                  Active
                </Tag>
              );
            case 'ARCHIVED':
              return (
                <Tag size="small" color="gray">
                  Archived
                </Tag>
              );
            case 'DISABLED':
              return (
                <Tag size="small" color="red">
                  Disabled
                </Tag>
              );
            case 'SCHEDULED':
              return (
                <Tag size="small" color="orange">
                  Scheduled
                </Tag>
              );
            default:
              <Tag size="small" color="blue">
                Unknown
              </Tag>;
          }
        })()}
      </div>
    ),
    className: 'w-1/5',
  },
  {
    Header: 'Created',
    Cell: (c) => <div>{formatDate(c.row.original.createdAt)}</div>,
    className: 'w-1/5',
  },
];
