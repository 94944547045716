import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Input } from 'components/react-hook-form-6/input';
import { TextArea } from 'components/text-area';

import {
  AddSafetyInformationMutation,
  AddSafetyInformationMutationVariables,
  DeleteSequenceSafetyInfoMutation,
  DeleteSequenceSafetyInfoMutationVariables,
  SequencePageQuery,
  UpdateSafetyInformationMutation,
  UpdateSafetyInformationMutationVariables,
} from 'graphql/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form-6';
import { requiredValidation } from 'utils/form-validation';

type SequenceSafetyInformationForm = {
  cmiUrl: string;
  doctorLetterTemplate: string;
  cmiCopyMarkdown: string;
};

export const SequenceSafetyInformation = ({
  sequenceId,
  safetyInformation,
  hideShowSafetyInformation,
}: {
  sequenceId: string;
  safetyInformation: NonNullable<
    SequencePageQuery['sequence']
  >['safetyInformation'];
  hideShowSafetyInformation: () => void;
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    reset,
    control,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<SequenceSafetyInformationForm>({
    defaultValues: {
      cmiCopyMarkdown: safetyInformation?.cmiCopyMarkdown,
      cmiUrl: safetyInformation?.cmiUrl,
      doctorLetterTemplate: safetyInformation?.doctorLetterTemplate,
    },
  });
  // react-hook-form magic to reset form state isDirty on submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues(), {});
    }
  }, [getValues, isSubmitSuccessful, reset]);

  const [updateSafetyInfo, { loading: loadingUpdateSafetyInfo }] = useMutation<
    UpdateSafetyInformationMutation,
    UpdateSafetyInformationMutationVariables
  >(gql`
    mutation UpdateSafetyInformation($input: UpdateSequenceSafetyInfoInput!) {
      updateSequenceSafetyInfo(input: $input) {
        safetyInformation {
          id
          cmiUrl
          doctorLetterTemplate
          cmiCopyMarkdown
        }
      }
    }
  `);
  const [addSafetyInfo, { loading: loadingAddSafetyInfo }] = useMutation<
    AddSafetyInformationMutation,
    AddSafetyInformationMutationVariables
  >(gql`
    mutation AddSafetyInformation($input: AddSequenceSafetyInfoInput!) {
      addSequenceSafetyInfo(input: $input) {
        sequence {
          id
          safetyInformation {
            id
            cmiUrl
            doctorLetterTemplate
            cmiCopyMarkdown
          }
        }
      }
    }
  `);
  const [deleteSafetyInfo, { loading: loadingDeleteSequenceSafetyInfo }] =
    useMutation<
      DeleteSequenceSafetyInfoMutation,
      DeleteSequenceSafetyInfoMutationVariables
    >(gql`
      mutation DeleteSequenceSafetyInfo(
        $input: DeleteSequenceSafetyInfoInput!
      ) {
        deleteSequenceSafetyInfo(input: $input) {
          sequence {
            id
            safetyInformation {
              id
            }
          }
        }
      }
    `);

  const handleUpdateSafetyInfo = async (
    formValues: SequenceSafetyInformationForm,
  ): Promise<void> => {
    if (safetyInformation) {
      updateSafetyInfo({
        variables: {
          input: {
            id: safetyInformation.id,
            cmiUrl: formValues.cmiUrl,
            cmiCopyMarkdown: formValues.cmiCopyMarkdown,
            doctorLetterTemplate: formValues.doctorLetterTemplate,
          },
        },
      });
    }
    if (!safetyInformation) {
      addSafetyInfo({
        variables: {
          input: {
            sequenceId,
            cmiUrl: formValues.cmiUrl,
            cmiCopyMarkdown: formValues.cmiCopyMarkdown,
            doctorLetterTemplate: formValues.doctorLetterTemplate,
          },
        },
      });
    }
  };
  const isLoading =
    loadingAddSafetyInfo ||
    loadingUpdateSafetyInfo ||
    loadingDeleteSequenceSafetyInfo;
  return (
    <form onSubmit={handleSubmit(handleUpdateSafetyInfo)}>
      <div className="space-y-2 py-5">
        <Input
          name="cmiUrl"
          label="CMI File URL"
          ref={register(requiredValidation('cmiUrl'))}
          errorMessage={errors.cmiUrl?.message}
        />
        <TextArea
          name="cmiCopyMarkdown"
          label="CMI Markdown"
          rows={8}
          placeholder={safetyInformation?.cmiCopyMarkdown || ''}
          errorMessage={errors.cmiCopyMarkdown?.message}
          ref={register(requiredValidation('cmiCopyMarkdown'))}
        />
        <TextArea
          name="doctorLetterTemplate"
          label="Practitioner Letter Template"
          rows={8}
          placeholder={safetyInformation?.doctorLetterTemplate || ''}
          errorMessage={errors.doctorLetterTemplate?.message}
          ref={register(requiredValidation('doctorLetterTemplate'))}
        />
        <div className="float-right">
          <div className="flex space-x-2">
            <Button
              fullWidth
              loading={loadingAddSafetyInfo || loadingUpdateSafetyInfo}
              type="submit"
              disabled={!isDirty || isLoading}
            >
              {safetyInformation ? 'Update' : 'Add'}
            </Button>
            {safetyInformation && !isDirty && (
              <Button
                fullWidth
                onClick={async (): Promise<void> => {
                  await deleteSafetyInfo({
                    variables: { input: { id: safetyInformation.id } },
                  });
                }}
                loading={loadingDeleteSequenceSafetyInfo}
                disabled={isLoading}
                color="danger"
              >
                Delete
              </Button>
            )}
            {safetyInformation && isDirty && (
              <Button
                fullWidth
                color="danger"
                variant="outline"
                onClick={(): void => {
                  reset(control.defaultValuesRef.current);
                }}
              >
                Cancel
              </Button>
            )}
            {!safetyInformation && (
              <Button
                fullWidth
                color="danger"
                variant="outline"
                onClick={(): void => hideShowSafetyInformation()}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
