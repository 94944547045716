import { Button, ProtectedButton } from 'components/button';
import { DropdownStatus, Option } from 'components/dropdown-status';
import { ControlledFileUpload } from 'components/file-upload';
import { Input } from 'components/react-hook-form-6/input';
import { Label } from 'components/label';
import { TextArea } from 'components/text-area';
import { config } from 'config';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import {
  emailIsValid,
  internationalNumber,
  noUppercaseLetters,
  requiredValidation,
} from 'utils/form-validation';
import { HealthcareProviderIdentifierSection } from './HealthcareProviderIdentifierSection';
import AddressInput from '../../components/address-input';
import { Props, Fields } from './types';
import { ClinicianTypeDropdown } from './clinician-type-dropdown';
import { useNotifications } from 'notifications';
import { gql, useMutation } from '@apollo/client';
import {
  GeneratePrescriberSigningBundleMutation,
  GeneratePrescriberSigningBundleMutationVariables,
} from 'graphql/types';
import { Checkbox } from '../../components/checkbox';
import { useHasPermissions } from '../../components/permissions';

const ClinicianForm = (props: Props): React.ReactElement => {
  const { submitting, onSubmit } = props;
  const showNotification = useNotifications();

  const canEditAsyncConsultsApproval = useHasPermissions([
    'EDIT_APPROVED_FOR_ASYNC_CONSULTATION',
  ]);
  let defaultValues = {
    state: '', // Needed default value to ensure controlled input throughout
    country: config.country as string,
    isAvailable: 'false',
  };
  if (props.type === 'update') {
    defaultValues = { ...defaultValues, ...props.initialValues };
  }
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm<Fields>({
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const [generatePrescriberBundle] = useMutation<
    GeneratePrescriberSigningBundleMutation,
    GeneratePrescriberSigningBundleMutationVariables
  >(
    gql`
      mutation GeneratePrescriberSigningBundle($id: ID!) {
        generatePrescriberBundle(input: { prescriberId: $id }) {
          prescriber {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Prescriber bundle successfully created',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Failed to create prescriber bundle',
        });
      },
    },
  );

  const providerNumberValue = watch('providerNumber');
  const prescriberNumberValue = watch('prescriberNumber');

  const availabilityOptions: Option[] = [
    {
      label: 'Available',
      value: 'true',
    },
    {
      label: 'Unavailable',
      value: 'false',
    },
  ];

  return (
    <form
      className="flex flex-col w-full pr-12"
      onSubmit={handleSubmit((fields: Fields) =>
        onSubmit(fields, dirtyFields, reset),
      )}
    >
      <div>
        <div className="flex flex-row w-1/3 justify-end float-right">
          {props.type === 'update' && (
            <div className="w-auto pr-4">
              <Button
                fullWidth
                type="button"
                color="danger"
                onClick={props.onDeactivate}
              >
                Deactivate
              </Button>
            </div>
          )}
          <div className="w-auto">
            <Button
              fullWidth
              type="submit"
              loading={submitting}
              disabled={!isDirty}
            >
              {props.type === 'create' ? 'Create' : 'Save changes'}
            </Button>
          </div>
        </div>
      </div>
      <div className="pt-8 flex flex-row">
        <div className="pb-8 pr-8 flex flex-wrap flex-row w-2/3">
          <span className="pl-2 heading-md">Personal Info</span>
          <div className="flex flex-wrap w-full">
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="First name"
                name="firstName"
                autoFocus={true}
                errorMessage={errors.firstName?.message}
                ref={register(requiredValidation('firstName'))}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="Last name"
                name="lastName"
                errorMessage={errors.lastName?.message}
                ref={register(requiredValidation('lastName'))}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="Email address"
                name="emailAddress"
                errorMessage={errors.emailAddress?.message}
                ref={register({
                  ...requiredValidation('emailAddress'),
                  ...emailIsValid,
                  ...noUppercaseLetters,
                })}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Input
                label="Phone"
                name="phone"
                errorMessage={errors.phone?.message}
                ref={register({
                  ...requiredValidation('phone'),
                  ...(config.requireInternationalClinicianPhone
                    ? internationalNumber('phone')
                    : {}),
                })}
              />
            </div>
          </div>
          <span className="pl-2 pt-6 heading-md">Address</span>
          <div className="flex flex-wrap w-full">
            <AddressInput
              registerField={register}
              errors={errors}
              control={control}
            />
          </div>
          <span className="pl-2 pt-6 heading-md">Medical Info</span>
          <div className="flex flex-wrap w-full">
            <div className="w-full lg:w-1/2 p-2 space-y-4">
              <div>
                <ClinicianTypeDropdown
                  name="clinicianType"
                  control={control}
                  errorMessage={errors.clinicianType?.message}
                />
              </div>
              <div>
                <Input
                  label="Prescriber number"
                  name="prescriberNumber"
                  errorMessage={errors.prescriberNumber?.message}
                  ref={register({
                    required: providerNumberValue
                      ? false
                      : 'Either a prescriber number or provider number is required',
                  })}
                />
              </div>
              <div>
                <Input
                  label="Qualifications"
                  name="qualifications"
                  errorMessage={errors.qualifications?.message}
                  ref={register(requiredValidation('qualifications'))}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2 space-y-4">
              <div>
                <Input
                  label="Practitioner Title"
                  name="clinicianTitle"
                  errorMessage={errors.clinicianTitle?.message}
                  ref={register(requiredValidation('clinicianTitle'))}
                />
              </div>
              <div>
                <Input
                  label="Provider number"
                  name="providerNumber"
                  errorMessage={errors.providerNumber?.message}
                  ref={register({
                    required: prescriberNumberValue
                      ? false
                      : 'Either a prescriber number or provider number is required',
                  })}
                />
              </div>
              <div>
                <Input
                  label="AHPRA number"
                  name="ahpraNumber"
                  errorMessage={errors.ahpraNumber?.message}
                  ref={register}
                />
              </div>
              <div>
                <Input
                  label="eRx Entity ID"
                  name="erxEntityId"
                  errorMessage={errors.erxEntityId?.message}
                  ref={register}
                />
              </div>
            </div>
          </div>
          <span className="pl-2 pt-6 heading-md">Profile</span>
          <div className="w-full p-2">
            <TextArea
              name="bio"
              label="Practitioner Bio"
              placeholder="Add a practitioner bio"
              rows={10}
              ref={register}
            />
          </div>
          <div className="w-full p-2">
            <TextArea
              name="introduction"
              label="Practitioner Introduction"
              placeholder="Add an introduction for patients to get to know the practitioner"
              rows={10}
              ref={register}
            />
          </div>
        </div>
        <div className="pb-8 w-1/3 flex flex-wrap content-start border-l-2 pl-12">
          <span className="heading-md">Practitioner availability</span>
          <div className="w-full pt-2 mb-3">
            <DropdownStatus
              name="isAvailable"
              control={control}
              options={availabilityOptions}
            />
          </div>
          <div className="w-full pt-2">
            <Checkbox
              name="approvedForAsynConsult"
              ref={register()}
              label={'Approved for async consult queue'}
              disabled={!canEditAsyncConsultsApproval}
            />
          </div>
          <span className="heading-md pt-2">Practitioner Images</span>
          <div className="w-full pt-2">
            <div className="mb-3">
              <Label htmlFor="avatar">Avatar</Label>
            </div>
            <ControlledFileUpload
              control={control}
              name="avatar"
              errorMessage={errors.avatar?.message}
              rules={requiredValidation('avatar')}
            />
            <div className="mb-3 pt-4">
              <Label htmlFor="signature">Signature</Label>
            </div>
            <ControlledFileUpload
              type="legacy-image"
              control={control}
              name="signature"
              errorMessage={errors.signature?.message}
              rules={requiredValidation('signature')}
            />
          </div>
          {props.refetch && (
            <HealthcareProviderIdentifierSection
              clinician={props.clinician}
              refetch={props.refetch}
            />
          )}
          {config.isDigitalSigningEnabled && props.clinician && (
            <div className="w-full pt-2">
              <ProtectedButton
                fullWidth
                loading={false}
                requiredPermissions={['USE_DISTURBED_RESOURCES']}
                onClick={() => {
                  if (!props.clinician?.id) {
                    return;
                  }
                  generatePrescriberBundle({
                    variables: { id: props.clinician?.id },
                  });
                }}
              >
                Generate Digital Signature
              </ProtectedButton>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ClinicianForm;
