import React from 'react';
import { Modal } from 'components/modal';
import { Button } from 'components/button';
import { useForm } from 'react-hook-form-6';
import { Input } from 'components/react-hook-form-6/input';
import { gql, useMutation } from '@apollo/client';
import { requiredValidation } from 'utils/form-validation';
import {
  DispatchPausePurchaseBatchMutation,
  DispatchPausePurchaseBatchMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { DisplayableFilter } from './types';

type BulkPauseButtonProps = {
  batchId: string;
  jobCount: number;
  displayableFilters: DisplayableFilter[];
};

type FormValues = {
  reason: string;
};

export const BulkPauseButton: React.FC<BulkPauseButtonProps> = ({
  batchId,
  jobCount,
  displayableFilters,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const showNotification = useNotifications();

  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      reason: '',
    },
    mode: 'onChange',
  });

  const [dispatchPausePurchaseBatch] = useMutation<
    DispatchPausePurchaseBatchMutation,
    DispatchPausePurchaseBatchMutationVariables
  >(
    gql`
      mutation DispatchPausePurchaseBatch(
        $input: DispatchPausePurchaseBatchInput!
      ) {
        dispatchPausePurchaseBatch(input: $input) {
          batch {
            id
            status
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setShowModal(false);
        showNotification({
          type: 'success',
          message: 'Successfully dispatched batch',
        });
      },
    },
  );

  const submit = handleSubmit((values: FormValues) => {
    dispatchPausePurchaseBatch({
      variables: {
        input: {
          batchId,
          pauseReason: values.reason?.trim(),
        },
      },
    });
  });

  return (
    <>
      <Button size="large" onClick={() => setShowModal(true)}>
        <p className="whitespace-nowrap">Run bulk action</p>
      </Button>
      {showModal && (
        <Modal
          show
          onClose={(): void => setShowModal(false)}
          isAutoOverflow={false}
          width="w-5/6"
        >
          <div className="bg-white flex flex-col rounded p-4 w-full">
            <h2 className="text-xl">Bulk pause</h2>
            <p>
              Pause <b>{jobCount}</b> patients RX purchases that meet the
              criteria:
            </p>
            <ul className="list-disc pl-4">
              {displayableFilters.map((filter) => (
                <li className="" key={filter.title}>
                  {filter.values.length === 1 ? (
                    filter.values[0]
                  ) : (
                    <div>
                      {filter.title}:
                      <ul className="list-disc ml-4">
                        {filter.values.map((f) => (
                          <li key={f}>{f}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <Input
              label="Pause reason"
              name="reason"
              errorMessage={errors.reason?.message}
              ref={register({
                ...requiredValidation('reason'),
                validate: {
                  isNotEmpty: (value: string) =>
                    !!value.trim() || 'cannot be only spaces',
                },
              })}
            />
            <div className="w-full flex flex-row gap-4 mt-4">
              <div className="flex-1">
                <Button
                  fullWidth
                  variant="outline"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
              <div className="flex-1">
                <Button fullWidth type="button" onClick={submit}>
                  Confirm pausing {jobCount} purchases
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
