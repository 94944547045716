import { ReactElement } from 'react';
import { Input } from 'components/react-hook-form-6/input';
import { useForm } from 'react-hook-form-6';
import { SubscriptionPlanInterval } from 'graphql/types';
import { Dropdown, Option } from 'components/dropdown';
import { Button } from 'components/button';
import {
  positiveIntegerValidation,
  requiredValidation,
} from 'utils/form-validation';
import clsx from 'clsx';
import { ControlledFileUpload } from 'components/file-upload';
import LineItemDropDown from './line-item-dropdown';

export type Fields = {
  name: string;
  amount: number;
  description: string;
  interval: SubscriptionPlanInterval;
  intervalCount: number;
  lineItem: string;
  photo: string;
};

type SharedProps = {
  onSubmit: (fields: Fields) => void;
  submitting: boolean;
};

type ViewProps = {
  type: 'view';
  initialValues: Fields;
};

type CreateProps = {
  type: 'create';
};

type Props = SharedProps & (ViewProps | CreateProps);

const intervals: (Option & { value: SubscriptionPlanInterval })[] = [
  { label: 'Days', value: 'DAYS' },
  { label: 'Months', value: 'MONTHS' },
];

const SubscriptionPlanForm = ({
  onSubmit,
  submitting,
  ...props
}: Props): ReactElement => {
  const {
    control,
    errors,
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<Fields>({
    defaultValues: props.type === 'view' ? props.initialValues : {},
    mode: 'onChange',
  });

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={handleSubmit((fields: Fields) => {
        onSubmit(fields);
      })}
    >
      <div className="flex gap-4">
        <div className="w-1/2">
          <Input
            name="name"
            label="Name"
            autoFocus
            ref={register(requiredValidation('name'))}
            errorMessage={errors.name?.message}
            disabled={props.type === 'view'}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="amount"
            label="Amount in cents"
            type="text"
            ref={register({
              ...requiredValidation('amount'),
              ...positiveIntegerValidation('amount'),
            })}
            errorMessage={errors.amount?.message}
            disabled={props.type === 'view'}
          />
        </div>
      </div>
      <div>
        <Input
          name="description"
          label="Description"
          ref={register(requiredValidation('description'))}
          errorMessage={errors.description?.message}
          disabled={props.type === 'view'}
        />
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <Dropdown
            name="interval"
            label="Interval"
            options={intervals}
            control={control}
            rules={requiredValidation('interval')}
            errorMessage={errors.interval?.message}
            disabled={props.type === 'view'}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="intervalCount"
            label="Interval Count"
            type="text"
            ref={register({
              ...requiredValidation('interval count'),
              ...positiveIntegerValidation('interval count'),
            })}
            errorMessage={errors.intervalCount?.message}
            disabled={props.type === 'view'}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <LineItemDropDown
            control={control}
            disabled={props.type === 'view'}
          />
        </div>
        <div className="w-1/2">
          <ControlledFileUpload
            name="photo"
            label="Product photo"
            disabled={props.type === 'view'}
            control={control}
            errorMessage={errors.photo?.message}
            rules={requiredValidation('photo')}
          />
        </div>
      </div>
      <div className="flex">
        <div
          className={clsx('w-1/2 space-y-3', { hidden: props.type === 'view' })}
        >
          <p className="font-bold uppercase">⚠️ Warning</p>
          <p>You cannot edit subscription plans once they have been created.</p>
          <Button
            fullWidth
            type="submit"
            loading={submitting}
            disabled={!isValid}
          >
            Create subscription plan
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SubscriptionPlanForm;
