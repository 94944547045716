import React from 'react';
import { Styles } from 'react-select';
import { Dropdown, Option } from './dropdown';
import { Control, RegisterOptions } from 'react-hook-form-6';

interface Props {
  control: Control;
  name: string;
  disabled?: boolean;
  rules?: RegisterOptions;
  errorMessage?: string | JSX.Element;
  options: Option[];
}

const statusStyles: Styles<Option<string>, boolean> = {
  singleValue: (base, state) => ({
    ...base,
    alignItems: 'center',
    display: 'flex',
    [':before' as string]: {
      backgroundColor: state.data.value === 'true' ? 'green' : 'red',
      borderRadius: 10,
      float: 'left',
      content: '" "',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
  option: (base, state) => ({
    ...base,
    alignItems: 'center',
    display: 'flex',
    [':before' as string]: {
      backgroundColor: state.data.value === 'true' ? 'green' : 'red',
      borderRadius: 10,
      float: 'left',
      content: '" "',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
};

export const DropdownStatus = ({
  control,
  disabled = false,
  errorMessage,
  rules,
  options,
  name,
}: Props): React.ReactElement => (
  <Dropdown
    name={name}
    control={control}
    disabled={disabled}
    options={options}
    rules={rules}
    customStyles={statusStyles}
    errorMessage={errorMessage}
  />
);
export type { Option };
