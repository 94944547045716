import { gql, useMutation, useQuery } from '@apollo/client';
import { useAlert } from 'alert';
import { Loading } from 'components/loading';
import { config } from 'config';
import {
  DeactivateDoctorMutation,
  DeleteDoctorMutation,
  DeleteDoctorMutationVariables,
  DoctorQuery,
  DoctorQueryVariables,
  DoctorUpdateInput,
  FileCreateInput,
  UpdateDoctorAndAvailabilityMutation,
  UpdateDoctorAndAvailabilityMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { DeepMap } from 'react-hook-form-6';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import ClinicianForm from './clinician-form';
import { Fields } from './types';
import { useHasPermissions } from '../../components/permissions';

const doctorQuery = gql`
  query Doctor($doctorId: String!) {
    user(where: { id: $doctorId }) {
      id
      firstName
      lastName
      fullName
      email
      role
      phone
      bio
      isAvailable
      avatar {
        id
        url
        filename
        mimetype
      }
      approvedForAsyncConsultations
      incompleteConsultations: doctorConsultations(
        where: {
          status: {
            notIn: [
              CANCELED
              ORDER_COMPLETED
              CUSTOMER_COMPLETED
              DOCTOR_COMPLETED
            ]
          }
        }
      ) {
        id
      }
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        company
        building
      }
      healthcareProviderIdentifer {
        id
        status
        hpii
        createdAt
      }
      provider {
        id
        providerNumber
        prescriberNumber
        ahpraNumber
        introduction
        signature {
          id
          url
          filename
          mimetype
        }
        qualifications
        clinicianTitle
        clinicianType
        erxEntityId
      }
    }
  }
`;

const updateDoctorMutation = gql`
  mutation updateDoctorAndAvailability(
    $id: String!
    $doctor: DoctorUpdateInput!
    $doctorsAvailability: Boolean!
    $skipAsyncConsultApproval: Boolean!
    $doctorAsyncConsultApprovalInput: SetPractitionerAsyncConsultationsApprovalInput!
  ) {
    updateDoctorAvailability(
      doctorId: $id
      makeAvailable: $doctorsAvailability
    ) {
      id
    }
    updateDoctor(doctor: $doctor) {
      id
      firstName
      lastName
      fullName
      email
      role
      phone
      bio
      isAvailable
      avatar {
        id
        url
        filename
        mimetype
      }
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        company
        building
      }
      healthcareProviderIdentifer {
        id
        status
        hpii
        createdAt
      }
      provider {
        id
        providerNumber
        prescriberNumber
        ahpraNumber
        introduction
        signature {
          id
          url
          filename
          mimetype
        }
        qualifications
        clinicianTitle
        clinicianType
        erxEntityId
      }
    }
    revalidateHpii(doctorId: $id) {
      id
      healthcareProviderIdentifer {
        id
        status
        hpii
        createdAt
      }
    }
    setPractitionerAsyncConsultationsApproval(
      input: $doctorAsyncConsultApprovalInput
    ) @skip(if: $skipAsyncConsultApproval) {
      practitioner {
        id
        approvedForAsyncConsultations
      }
    }
  }
`;

const deleteDoctorMutation = gql`
  mutation DeleteDoctor($id: String!) {
    deleteOneUser(where: { id: $id }) {
      id
    }
  }
`;

const deactivateDoctorMutation = gql`
  mutation DeactivateDoctor($id: String!) {
    deactivateDoctor(doctorId: $id) {
      id
    }
  }
`;

const mapFieldsToGraphQLVariables = (
  id: string,
  fields: Fields,
  dirtyFields: DeepMap<Fields, true>,
): DoctorUpdateInput => {
  const modifiedFields: Fields = Object.assign(
    {},
    ...Object.keys(dirtyFields).map((key) => {
      return { [key as keyof Fields]: fields[key as keyof Fields] };
    }),
  );

  const variables: DoctorUpdateInput = {
    id,
    firstName: modifiedFields.firstName,
    lastName: modifiedFields.lastName,
    phone: modifiedFields.phone?.trim(),
    email: modifiedFields.emailAddress?.trim(),
    bio: modifiedFields.bio,
    avatar: modifiedFields.avatar
      ? (JSON.parse(modifiedFields.avatar) as FileCreateInput)
      : undefined,
    signature: modifiedFields.signature
      ? (JSON.parse(modifiedFields.signature) as FileCreateInput)
      : undefined,
  };

  if (
    modifiedFields.line1 ||
    modifiedFields.line2 ||
    modifiedFields.city ||
    modifiedFields.postalCode ||
    modifiedFields.state ||
    modifiedFields.country
  ) {
    variables.address = {
      line1: modifiedFields.line1?.trim() ?? undefined,
      line2: modifiedFields.line2?.trim() ?? undefined,
      city: modifiedFields.city?.trim() ?? undefined,
      postalCode: modifiedFields.postalCode?.trim() ?? undefined,
      state: modifiedFields.state?.trim() ?? undefined,
      country: modifiedFields.country?.trim() ?? undefined,
    };
  }

  if (
    modifiedFields.prescriberNumber !== undefined ||
    modifiedFields.ahpraNumber ||
    modifiedFields.introduction ||
    modifiedFields.providerNumber !== undefined ||
    modifiedFields.hpii ||
    modifiedFields.qualifications ||
    modifiedFields.clinicianTitle ||
    modifiedFields.clinicianType ||
    modifiedFields.erxEntityId !== undefined
  ) {
    variables.provider = {
      prescriberNumber: modifiedFields.prescriberNumber?.trim() ?? undefined,
      ahpraNumber: modifiedFields.ahpraNumber?.trim() ?? undefined,
      providerNumber: modifiedFields.providerNumber?.trim() ?? undefined,
      introduction: modifiedFields.introduction ?? undefined,
      qualifications: modifiedFields.qualifications ?? undefined,
      clinicianTitle: modifiedFields.clinicianTitle ?? undefined,
      clinicianType: modifiedFields.clinicianType ?? undefined,
      erxEntityId: modifiedFields.erxEntityId?.trim() ?? undefined,
    };
  }

  return variables;
};

const mapGraphQLSchemaToFields = (
  doctor: Partial<DoctorQuery['user']>,
): Fields => {
  return {
    firstName: doctor?.firstName || '',
    lastName: doctor?.lastName || '',
    emailAddress: doctor?.email || '',
    phone: doctor?.phone || '',
    prescriberNumber: doctor?.provider?.prescriberNumber || '',
    ahpraNumber: doctor?.provider?.ahpraNumber || '',
    introduction: doctor?.provider?.introduction || '',
    providerNumber: doctor?.provider?.providerNumber || '',
    hpii: doctor?.healthcareProviderIdentifer?.hpii || '',
    qualifications: doctor?.provider?.qualifications || '',
    line1: doctor?.address?.line1 || '',
    line2: doctor?.address?.line2 || '',
    city: doctor?.address?.city || '',
    postalCode: doctor?.address?.postalCode || '',
    state: doctor?.address?.state || '',
    country: doctor?.address?.country || config.country,
    clinicianType: doctor?.provider?.clinicianType || 'DOCTOR',
    clinicianTitle: doctor?.provider?.clinicianTitle || '',
    erxEntityId: doctor?.provider?.erxEntityId || '',

    bio: doctor?.bio || '',
    avatar: doctor?.avatar
      ? JSON.stringify({
          url: doctor.avatar.url,
          filename: doctor.avatar.filename,
          mimetype: doctor.avatar.mimetype,
        })
      : '',
    signature: doctor?.provider?.signature
      ? JSON.stringify({
          url: doctor.provider.signature?.url,
          filename: doctor.provider.signature.filename,
          mimetype: doctor.provider.signature.mimetype,
        })
      : '',
    isAvailable: doctor?.isAvailable?.toString() || 'false',
    approvedForAsynConsult: !!doctor?.approvedForAsyncConsultations,
  };
};

const Clinician = (): React.ReactElement => {
  const showNotification = useNotifications();
  const showAlert = useAlert();
  const history = useHistory();
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const canEditAsyncConsultsApproval = useHasPermissions([
    'EDIT_APPROVED_FOR_ASYNC_CONSULTATION',
  ]);
  const { data, loading, error, refetch } = useQuery<
    DoctorQuery,
    DoctorQueryVariables
  >(doctorQuery, {
    variables: { doctorId: clinicianId },
    onCompleted: (user) =>
      !user.user &&
      showNotification({
        type: 'error',
        message: 'No matching practitioner was found',
      }),
    onError: () => {
      showNotification({
        type: 'error',
        message: 'There was an error attempting to retrieve this practitioner',
      });
    },
  });
  const [updateClinician, { loading: processing }] = useMutation<
    UpdateDoctorAndAvailabilityMutation,
    UpdateDoctorAndAvailabilityMutationVariables
  >(updateDoctorMutation, {
    onError: () =>
      showNotification({
        type: 'error',
        message: 'There was an error updating this practitioner',
      }),
  });
  const [deleteClinician] = useMutation<
    DeleteDoctorMutation,
    DeleteDoctorMutationVariables
  >(deleteDoctorMutation, {
    onCompleted: () => {
      showNotification({
        type: 'success',
        message: 'Practitioner successfully deleted',
      });
      history.replace(routes.clinicians);
    },
    onError: () =>
      showNotification({
        type: 'error',
        message: 'Failed to delete practitioner',
      }),
  });
  const [deactivateClinician] = useMutation<
    DeactivateDoctorMutation,
    DeleteDoctorMutationVariables
  >(deactivateDoctorMutation, {
    onCompleted: () => {
      showNotification({
        type: 'success',
        message: 'Practitioner successfully deactivated',
      });
      history.replace(routes.clinicians);
    },
    onError: () =>
      showNotification({
        type: 'error',
        message: 'Failed to deactivate practitioner',
      }),
  });

  const onSubmit = async (
    fields: Fields,
    dirtyFields: DeepMap<Fields, true>,
    reset: (values?: Fields) => void,
  ): Promise<void> => {
    const updateResponse = await updateClinician({
      variables: {
        id: clinicianId,
        doctor: mapFieldsToGraphQLVariables(clinicianId, fields, dirtyFields),
        doctorsAvailability: fields.isAvailable === 'true',
        skipAsyncConsultApproval:
          !canEditAsyncConsultsApproval ||
          (canEditAsyncConsultsApproval &&
            dirtyFields.approvedForAsynConsult === undefined),
        doctorAsyncConsultApprovalInput: {
          approved: fields.approvedForAsynConsult,
          practitionerId: clinicianId,
        },
      },
    });

    showNotification({
      type: 'success',
      message: 'Practitioner successfully updated.',
    });

    if (updateResponse?.data?.updateDoctor) {
      reset(
        mapGraphQLSchemaToFields({
          ...updateResponse?.data?.updateDoctor,
          ...updateResponse?.data?.setPractitionerAsyncConsultationsApproval
            ?.practitioner,
        }),
      );
    }
  };

  const handleClinicianDelete = async (): Promise<void> => {
    if (
      await showAlert({
        content: 'Are you sure you want to delete this practitioner?',
      })
    ) {
      await deleteClinician({ variables: { id: clinicianId } });
    }
  };

  const handleClinicianDeactivate = async (): Promise<void> => {
    if (
      await showAlert({
        content: 'Are you sure you want to deactivate this practitioner?',
      })
    ) {
      try {
        await deactivateClinician({
          variables: {
            id: clinicianId,
          },
        });
        history.replace(routes.clinicians);
      } catch {
        return showNotification({
          type: 'error',
          message: 'Unable to deactivate this practitioner',
        });
      }
    }
  };

  const clinician = data?.user;
  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && !error && clinician && (
        <>
          <ClinicianForm
            clinician={clinician}
            initialValues={mapGraphQLSchemaToFields(clinician)}
            onSubmit={onSubmit}
            submitting={processing}
            type="update"
            onDelete={handleClinicianDelete}
            onDeactivate={handleClinicianDeactivate}
            refetch={refetch}
          />
        </>
      )}
      {!loading && (error || !clinician) && (
        <div>No matching practitioner was found</div>
      )}
    </React.Fragment>
  );
};

export default Clinician;
