import { gql, useMutation } from '@apollo/client';
import {
  UpdateCustomerProfileMutation,
  UpdateCustomerProfileMutationVariables,
} from 'graphql/types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-6';
import { useNotifications } from '../../notifications';
import { Customer, CustomerDetailsSubmit } from './types';
import { useHasPermissions } from '../../components/permissions';
import { ResetPasswordModal } from './resetPasswordModal';
import {
  mobileNumberValidation,
  requiredValidation,
} from '../../utils/form-validation';
import { InputError } from '../../components/input-error';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { ImpersonateCustomerModal } from 'components/impersonation/impersonate-customer-modal';
import { Copyable } from 'components/copyable';
import { noUppercaseRegex, validEmailRegex } from 'utils/regex-expressions';
import { usePrevious } from 'react-use';

const buttonClass =
  'whitespace-nowrap flex-1 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out';

export const CustomerSection = ({
  customer,
}: {
  // FIXME: We should use a GraphQL fragment here instead.
  customer: Customer;
}): React.ReactElement => {
  const notify = useNotifications();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);
  const [showModal, setShowModal] = useState(false);
  const featureFlagClient = useFeatureFlagClient();

  const canImpersonateCustomer =
    useHasPermissions(['IMPERSONATE_CUSTOMER']) &&
    featureFlagClient.getBoolean('enable-login-as-patient');

  const form = useForm({
    defaultValues: {
      firstName: customer.legalFirstName || '',
      lastName: customer.lastName || '',
      phone: customer.phone || '',
      email: customer.email || '',
    },
  });

  const prevFirstName = usePrevious(customer.legalFirstName);
  if (prevFirstName !== customer.legalFirstName) {
    form.setValue('firstName', customer.legalFirstName, {
      shouldDirty: false,
    });
  }

  const prevLastName = usePrevious(customer.lastName);
  if (prevLastName !== customer.lastName) {
    form.setValue('lastName', customer.lastName, { shouldDirty: false });
  }

  const prevPhone = usePrevious(customer.phone);
  if (prevPhone !== customer.phone) {
    form.setValue('phone', customer.phone, { shouldDirty: false });
  }

  const prevEmail = usePrevious(customer.email);
  if (prevEmail !== customer.email) {
    form.setValue('email', customer.email, { shouldDirty: false });
  }

  const [customerUpdateMutation, { loading }] = useMutation<
    UpdateCustomerProfileMutation,
    UpdateCustomerProfileMutationVariables
  >(gql`
    mutation UpdateCustomerProfile(
      $id: String!
      $firstName: String
      $lastName: String
      $email: String
      $phone: String
    ) {
      updateCustomerProfile(
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
      ) {
        id
        firstName
        lastName
        email
        phone
      }
    }
  `);

  const submit = form.handleSubmit(
    async (formData: CustomerDetailsSubmit): Promise<void> => {
      try {
        await customerUpdateMutation({
          variables: {
            id: customer.id,
            firstName: formData.firstName ?? undefined,
            lastName: formData.lastName ?? undefined,
            phone: formData.phone ?? undefined,
            email: formData.email ?? undefined,
          },
        });
        notify({ type: 'success', message: 'Updated patient details' });
        form.reset({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
        });
      } catch {
        // error events show notifications already
        return;
      }
    },
  );

  return (
    <div className="bg-white shadow overflow-hidden rounded my-4">
      <div className="flex flex-row justify-between px-4 pt-5 pb-4 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Patient Details
        </h3>
        <Copyable text={customer.id}>
          {(copied) => (
            <pre className="text-sm cursor-pointer text-gray-500">
              {copied ? 'Copied' : customer.id.slice(-6)}
            </pre>
          )}
        </Copyable>
      </div>
      <form
        onSubmit={submit}
        className="overflow-hidden bg-white p-4 space-y-3 text-sm"
      >
        <div className="flex items-center">
          <div className="w-1/3">
            <label
              className="block text-gray-500 font-semibold text-left pr-4"
              htmlFor="firstName"
            >
              Legal Name
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="firstName"
              type="text"
              ref={form.register()}
              name="firstName"
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-1/3">
            <label
              className="block text-gray-500 font-bold text-left pr-4"
              htmlFor="lastName"
            >
              Last Name
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="lastName"
              type="text"
              ref={form.register()}
              name="lastName"
            />
          </div>
        </div>
        <div className="flex items-center ">
          <div className="w-1/3">
            <label
              className="block text-gray-500 font-bold text-left pr-4"
              htmlFor="email"
            >
              Email
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="email"
              type="text"
              ref={form.register({
                ...requiredValidation('email'),
                validate: {
                  validEmail: (value) =>
                    validEmailRegex.test(value)
                      ? true
                      : 'Please enter a valid email',
                  noUppercase: (value) =>
                    noUppercaseRegex.test(value)
                      ? true
                      : 'Please use only lowercase letters',
                },
              })}
              name="email"
            />
            <InputError>{form.errors.email?.message}</InputError>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-1/3">
            <label
              className="block text-gray-500 font-bold text-left pr-4"
              htmlFor="phone"
            >
              Phone
            </label>
          </div>
          <div className="w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="phone"
              type="text"
              ref={form.register(
                mobileNumberValidation('phone', customer.phone),
              )}
              name="phone"
            />
            <InputError>{form.errors.phone?.message}</InputError>
          </div>
        </div>
        {customer.referralUrl && (
          <div className="flex items-center">
            <div className="w-1/3">
              <p className="block text-gray-500 font-semibold text-left pr-4">
                Referral URL
              </p>
            </div>
            <div className="w-2/3">
              <Copyable text={customer.referralUrl}>
                {(copied) => (
                  <pre className="text-sm cursor-pointer text-gray-500">
                    {copied
                      ? 'Copied'
                      : new URL(customer.referralUrl ?? '').searchParams.get(
                          'discountCode',
                        )}
                  </pre>
                )}
              </Copyable>
            </div>
          </div>
        )}
        <div className="flex justify-between pt-4 gap-2">
          <button
            type="button"
            className={buttonClass}
            onClick={(): void => setShowModal(true)}
            disabled={!canImpersonateCustomer}
          >
            Impersonate
          </button>
          <button
            type="button"
            className={buttonClass}
            onClick={(): void => setShowResetPasswordModal(true)}
            disabled={passwordReset}
          >
            {passwordReset ? 'Password reset' : 'Reset password'}
          </button>
          <button
            type="submit"
            className={buttonClass}
            disabled={
              form.formState.isSubmitting ||
              !form.formState.isDirty ||
              loading ||
              !canEditPatients
            }
          >
            Save changes
          </button>
        </div>
      </form>
      <ResetPasswordModal
        show={showResetPasswordModal}
        onResetEmailSent={(): void => setPasswordReset(true)}
        onClose={(): void => setShowResetPasswordModal(false)}
        customerId={customer.id}
        email={customer.email}
      />
      <ImpersonateCustomerModal
        show={showModal}
        onClose={() => setShowModal(false)}
        customerId={customer.id}
      />
    </div>
  );
};
