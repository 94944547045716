import { gql, useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { config } from 'config';
import {
  CancelEvermedOrderMutation,
  CancelEvermedOrderMutationVariables,
  FulfillmentStatus,
  OrderPaymentStatus,
  OrderQuery,
  OrderQueryVariables,
  OrderStatus,
  ProductType,
  ProblemType,
  RecreateShopifyOrderMutation,
  RecreateShopifyOrderMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-6';
import { FaQuestionCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Colors, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { routes } from 'utils/routes';
import {
  fulfillmentStatuses,
  orderStatuses,
  paymentStatuses,
} from 'utils/statuses';
import { cardHeading } from 'utils/styles';
import { AuditLogTable } from '../../components/audit/audit-log-table';
import { Card } from '../../components/card';
import { CardField } from './card-field';
import { FulfillmentInfo } from './fulfillment-info';
import { useIsEvermedOrder } from './useIsEvermedOrder';
import { Tag } from 'components/tag';

const orderQuery = gql`
  query Order($where: OrderWhereUniqueInput!) {
    order(where: $where) {
      id
      status
      paymentStatus
      fulfillment
      scriptId
      customerId
      invoiceFilename
      ... @defer {
        evermedInvoiceUrl
      }
      treatment {
        id
        supersededByFlexiPlans
        type
        status
        experimentPaymentPlan {
          id
          version
          paymentPlan
        }
      }
      substitutedWithCompounded
      failedVendorCreationReason
      vendorComment
      shipping {
        id
        ... on AponsOrderShippingDetails {
          aponsStatus: status
          carrier
          trackingNumber
          fulfilledAt
          trackingUrl
          isTest
        }
        ... on ShopifyOrderShippingDetails {
          trackingNumber
        }
        ... on PluripharmOrderShippingDetails {
          pluripharmStatus: status
          pluripharmOrderId
          parcels {
            id
            trackingId
            trackingUrl
            carrier
            status
          }
        }
        ... on EvermedOrderShippingDetails {
          trackingNumber
          trackingUrl
        }
      }
      fulfillmentPharmacy {
        id
        name
        address
      }
      customer {
        id
        email
      }
      lineItems {
        id
        variant {
          id
          inventory {
            id
            sku
          }
          product {
            id
            name
            productType
            problemTypes
          }
        }
        quantity
      }
      payments {
        id
        gateway
      }
    }
  }
`;

type OrderSubmit = {
  status: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  fulfillmentStatus: FulfillmentStatus;
};

type OrderUpdateParams = {
  status?: OrderStatus;
  paymentStatus?: OrderPaymentStatus;
  fulfillment?: FulfillmentStatus;
};

const orderUpdateQuery = gql`
  mutation updateOrder($orderId: String!, $data: OrderUpdateParams!) {
    updateOrder(orderId: $orderId, data: $data) {
      id
    }
  }
`;

const fulfillSubscriptionOrderDocument = gql`
  mutation fulfillSubscriptionOrder($orderId: String!) {
    fulfillSubscriptionOrder(orderId: $orderId) {
      id
    }
  }
`;

const treatmentSetActiveQuery = gql`
  mutation updateTreatment($treatmentId: String!) {
    updateOneTreatment(
      where: { id: $treatmentId }
      data: {
        status: { set: ACTIVE }
        logs: { create: { status: ACTIVE, event: "Admin Fulfilled Order" } }
      }
    ) {
      id
    }
  }
`;

const OrderComponent = (): React.ReactElement => {
  const showNotification = useNotifications();
  const { orderId } = useParams<{ orderId: string }>();
  const {
    data,
    loading,
    refetch: refetchOrder,
  } = useQuery<OrderQuery, OrderQueryVariables>(orderQuery, {
    variables: { where: { id: orderId } },
  });
  const [treatmentSetActiveMutation] = useMutation(treatmentSetActiveQuery);
  const [fulfillSubscriptionOrder] = useMutation(
    fulfillSubscriptionOrderDocument,
  );

  const [
    recreateShopifyOrderMutation,
    { loading: recreateShopifyOrderSubmitting },
  ] = useMutation<
    RecreateShopifyOrderMutation,
    RecreateShopifyOrderMutationVariables
  >(gql`
    mutation recreateShopifyOrder($orderId: String!) {
      recreateShopifyOrder(orderId: $orderId) {
        id
      }
    }
  `);

  const [
    cancelEvermedOrderMutation,
    { loading: cancelEvermedOrderSubmitting },
  ] = useMutation<
    CancelEvermedOrderMutation,
    CancelEvermedOrderMutationVariables
  >(gql`
    mutation cancelEvermedOrder($input: CancelEvermedOrderInput!) {
      cancelEvermedOrder(input: $input) {
        order {
          id
          status
          fulfillment
          paymentStatus
        }
      }
    }
  `);

  const submitRecreateShopifyOrder = async () => {
    try {
      await recreateShopifyOrderMutation({ variables: { orderId } });
      showNotification({
        type: 'success',
        message: 'Recreated Shopify order',
      });
      refetchOrder();
    } catch (e) {
      if (e instanceof Error) {
        showNotification({
          type: 'error',
          message: e.message,
        });
      }
    }
  };

  const submitCancelEvermedOrder = async () => {
    try {
      const order = await cancelEvermedOrderMutation({
        variables: { input: { engineOrderId: orderId } },
      });
      showNotification({
        type: 'success',
        message: 'Cancelled Evermed order',
      });
      resetForm({
        status: order.data?.cancelEvermedOrder?.order?.status || undefined,
        paymentStatus:
          order.data?.cancelEvermedOrder?.order?.paymentStatus || undefined,
        fulfillmentStatus:
          order.data?.cancelEvermedOrder?.order?.fulfillment || undefined,
      });
    } catch (e) {
      if (e instanceof Error) {
        showNotification({
          type: 'error',
          message: e.message,
        });
      }
    }
  };

  const order = data?.order;
  const supersededByFlexiPlans = !!order?.treatment?.supersededByFlexiPlans;

  useEffect(() => {
    if (supersededByFlexiPlans) {
      showNotification({
        type: 'info',
        message:
          'The treatment related to this order has been migrated to flexi-plans and cannot be edited.',
      });
    }
  }, [showNotification, supersededByFlexiPlans]);

  const {
    register,
    handleSubmit,
    reset: resetForm,
    watch,
    formState: { dirtyFields, isDirty, isSubmitting: orderUpdateSubmitting },
  } = useForm<OrderSubmit>({});

  const [orderUpdateMutation] = useMutation(orderUpdateQuery);
  const submitOrderUpdate = async (formData: OrderSubmit): Promise<void> => {
    const data: OrderUpdateParams = {
      status: 'status' in dirtyFields ? formData.status : undefined,
      paymentStatus:
        'paymentStatus' in dirtyFields ? formData.paymentStatus : undefined,
      fulfillment:
        'fulfillmentStatus' in dirtyFields
          ? formData.fulfillmentStatus
          : undefined,
    };

    await orderUpdateMutation({ variables: { orderId, data } });
    if (formData.fulfillmentStatus === 'FULFILLED') {
      if (order?.treatment?.status === 'PROCESSING_ORDER') {
        await treatmentSetActiveMutation({
          variables: { treatmentId: order.treatment.id },
        });
      }

      await fulfillSubscriptionOrder({
        variables: { orderId },
      });
    }
    showNotification({
      type: 'success',
      message: 'Updated order details',
    });
    const orderRefetchResult = await refetchOrder();
    resetForm({
      status: orderRefetchResult.data?.order?.status || undefined,
      paymentStatus: orderRefetchResult.data?.order?.paymentStatus || undefined,
      fulfillmentStatus:
        orderRefetchResult.data?.order?.fulfillment || undefined,
    });
  };

  const isEvermedOrder = useIsEvermedOrder(
    order?.lineItems.map((li) => li.variant.inventory?.sku ?? '') ?? [],
  );
  if (!order) {
    return loading ? <Loading /> : <div>Order {orderId} not found.</div>;
  }

  return (
    <>
      <form
        className="bg-white shadow overflow-hidden rounded"
        onSubmit={handleSubmit(submitOrderUpdate)}
      >
        <h2 className={clsx(cardHeading)}>Patient Info</h2>
        <div>
          <CardField label="Patient ID">
            <Link
              className="underline"
              to={`${routes.customers}/${order.customer?.id || ''}?pt=${order
                .treatment?.type}`}
            >
              <span className="text-gray-500">{order.customer?.id || ''}</span>
            </Link>
          </CardField>
          <CardField label="Patient">{order.customer?.email || ''}</CardField>
          <CardField label="Script">
            {order.scriptId ? (
              <Link
                className="underline mr-2"
                to={`${routes.scripts}/${order.scriptId}`}
              >
                <span className="text-gray-500">{order.scriptId}</span>
              </Link>
            ) : null}
          </CardField>
          <CardField label="Substituted">
            {/*
              Naming here is annoying, but this solely communicates whether the order was substituted with anything
              other than what was originally requested. Not that it was specifcally a compounded medication.
            */}
            <SubstitutedTag substituted={order.substitutedWithCompounded} />
          </CardField>
        </div>
        <h2 className={clsx(cardHeading)}>Order Info</h2>
        <div>
          <CardField label="Status">
            <div className={selectContainerClassName}>
              <select
                className={selectClassName}
                disabled={supersededByFlexiPlans}
                name="status"
                id="status"
                ref={register}
                defaultValue={order.status || undefined}
              >
                {orderStatuses.map((s) => (
                  <option key={s} value={s}>
                    {upperSnakeCaseToCapitalCase(s)}
                  </option>
                ))}
              </select>
              <SelectChevron />
            </div>
          </CardField>
          {order.failedVendorCreationReason && (
            <CardField label="Failed Vendor Creation Reason">
              {order.failedVendorCreationReason}
            </CardField>
          )}
          <CardField label="Payment Status">
            <div className={selectContainerClassName}>
              <select
                className={selectClassName}
                disabled={supersededByFlexiPlans}
                name="paymentStatus"
                id="paymentStatus"
                ref={register}
                defaultValue={order.paymentStatus || undefined}
              >
                {paymentStatuses.map((s) => (
                  <option key={s} value={s}>
                    {upperSnakeCaseToCapitalCase(s)}
                  </option>
                ))}
              </select>
              <SelectChevron />
            </div>
          </CardField>
          <CardField label="Payment Gateway">
            {order.payments[0]?.gateway}
          </CardField>
          <CardField label="Fulfillment Status">
            <div className="flex">
              <div className={selectContainerClassName}>
                <select
                  className={selectClassName}
                  disabled={supersededByFlexiPlans}
                  name="fulfillmentStatus"
                  id="fulfillmentStatus"
                  ref={register}
                  defaultValue={order.fulfillment || undefined}
                >
                  {fulfillmentStatuses.map((s) => (
                    <option key={s} value={s}>
                      {upperSnakeCaseToCapitalCase(s)}
                    </option>
                  ))}
                </select>
                <SelectChevron />
              </div>
              {watch('fulfillmentStatus') !== order.fulfillment && (
                <div className="pl-4 flex items-center">
                  <p className="text-red-500">
                    Changing this will stop automated status updates.
                  </p>
                </div>
              )}
            </div>
          </CardField>
          <CardField label="Treatment">
            <Link
              key={order.treatment?.id}
              className="underline mr-2"
              to={`${routes.customers}/${order.customerId}`}
            >
              {order.treatment?.id}
            </Link>
          </CardField>
          <CardField label="Experiment Payment Plan">
            {order.treatment?.experimentPaymentPlan?.paymentPlan}{' '}
            <div className="inline-flex items-center leading-loose text-xs text-white bg-teal-500 rounded-md p-0">
              <span className="px-2 inline-flex items-center whitespace-nowrap">
                <span>
                  {order.treatment?.experimentPaymentPlan?.version
                    ? `Version ${order.treatment?.experimentPaymentPlan?.version}`
                    : 'Legacy'}
                </span>
                {order.treatment?.experimentPaymentPlan?.paymentPlan && (
                  <FaQuestionCircle
                    className="ml-1 inline align-top text-sm"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="'Legacy' -> discount only, same shipping frequency. 'Version 3' -> discounted and shipped at once."
                  />
                )}
              </span>
            </div>
          </CardField>
          {order.evermedInvoiceUrl ? (
            <CardField label="Invoice URL">
              <a
                target="_blank"
                rel="noreferrer"
                className="underline"
                href={order.evermedInvoiceUrl}
              >
                {order.evermedInvoiceUrl}
              </a>
            </CardField>
          ) : (
            <CardField label="Pharmacy Invoice">
              <span className="text-gray-500">
                Pharmacy invoices are generated by the dispensing pharmacy
              </span>
            </CardField>
          )}
          {isDirty && (
            <CardField label="">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
                disabled={orderUpdateSubmitting}
              >
                Save Changes
              </button>
            </CardField>
          )}
        </div>
        <h2 className={clsx(cardHeading)}>Fulfilment Vendor Details</h2>
        <FulfillmentInfo shipping={order.shipping} />
        {config.fulfilmentVendors.includes('shopify') && !isEvermedOrder && (
          <button
            onClick={submitRecreateShopifyOrder}
            className="ml-2 inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out mb-3"
            disabled={supersededByFlexiPlans || recreateShopifyOrderSubmitting}
          >
            Recreate Shopify Order
          </button>
        )}
        {isEvermedOrder && order.vendorComment && (
          <CardField label="Vendor Note">{order.vendorComment}</CardField>
        )}

        {isEvermedOrder &&
          order.fulfillment === 'UNFULFILLED' &&
          order.status === 'CREATED' && (
            <button
              onClick={submitCancelEvermedOrder}
              className="ml-2 inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out mb-3"
              disabled={supersededByFlexiPlans || cancelEvermedOrderSubmitting}
            >
              Cancel Evermed Order
            </button>
          )}
        {order.fulfillmentPharmacy && (
          <>
            <h2 className={clsx(cardHeading)}>Fulfilment Pharmacy Details</h2>
            <div>
              <CardField label="Pharmacy Name">
                {order.fulfillmentPharmacy.name}
              </CardField>
              <CardField label="Pharmacy Address">
                {order.fulfillmentPharmacy.address}
              </CardField>
            </div>
          </>
        )}
        <h2 className={clsx(cardHeading)}>Order Details</h2>
        <LineItemsTable lineItems={order.lineItems} />
      </form>
      <Card className="mt-5">
        <AuditLogTable targetId={orderId} targetType={'ORDER'} />
      </Card>
    </>
  );
};

const LineItemsTable = ({
  lineItems,
}: {
  lineItems: {
    id: string;
    variant: {
      id: string;
      inventory?: {
        id: string;
        sku?: string | null;
      } | null;
      product: {
        id: string;
        name?: string;
        productType?: ProductType;
        problemTypes?: ProblemType[];
      };
    };
    quantity: number;
  }[];
}): React.ReactElement => {
  return (
    <div className="bg-gray-100 py-3 px-4">
      <table className="table-auto w-full">
        <thead>
          <tr className="text-left heading-sm">
            <th className="px-4 pb-3">Type</th>
            <th className="pb-3">Quantity</th>
            <th className="pb-3">Product</th>
            <th className="pb-3">Sku</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((lineItem, index) => (
            <React.Fragment key={lineItem.id}>
              <tr className="h-12 bg-white shadow-card">
                <td className="px-4 py-2">
                  {lineItem.variant.product?.productType}
                </td>
                <td className="py-2 pr-4">{lineItem.quantity}</td>
                <td className="py-2 underline">
                  <Link
                    key={lineItem.id}
                    to={`${routes.products}/${lineItem.variant.product?.id}`}
                  >
                    {lineItem.variant.product?.name}
                  </Link>
                </td>
                <td className="py-2 underline">
                  <Link
                    key={lineItem.id}
                    to={`${routes.products}/${lineItem.variant.product?.id}`}
                  >
                    {lineItem.variant.inventory?.sku}
                  </Link>
                </td>
              </tr>
              {lineItems.length !== index + 1 && (
                <tr className="h-2 bg-transparent"></tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const selectContainerClassName = 'inline-block relative';
const selectClassName =
  'block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:ring';
const SelectChevron = (): React.ReactElement => (
  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
    <svg
      className="fill-current h-4 w-4"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  </div>
);

const SubstitutedTag = ({
  substituted,
}: {
  substituted?: boolean | null;
}): JSX.Element => {
  let tagColor: Colors = 'gray';
  let tagText: string = '';
  switch (substituted) {
    case true:
      tagColor = 'blue';
      tagText = 'Yes';
      break;
    case false:
      tagColor = 'orange';
      tagText = 'No';
      break;
    default:
      tagColor = 'gray';
      tagText = 'Unknown';
  }

  return (
    <Tag size="small" color={tagColor}>
      {tagText}
    </Tag>
  );
};

export default OrderComponent;
