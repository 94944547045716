import { Dropdown } from 'components/dropdown';
import { RadioButton } from 'components/radio-button';
import {
  CreateBatchJobPageQuery,
  CreateBatchJobPageQueryVariables,
  CreateStockoutToolingBatchMutation,
  CreateStockoutToolingBatchMutationVariables,
  ProblemType,
  PurchaseStatusFilter,
} from 'graphql/types';
import { useForm } from 'react-hook-form-6';
import { requiredValidation } from 'utils/form-validation';
import {
  problemTypeOptions,
  purchaseStatusFilterOptions,
} from 'utils/dropdown-options';
import { Label } from 'components/label';
import { Button } from 'components/button';
import { DoctorSelect } from 'components/doctor-select';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InputError } from 'components/input-error';
import { routes } from 'utils/routes';
import { useNotifications } from 'notifications';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { useAuth } from 'auth';
import { parse } from 'csv-parse/browser/esm/sync';
import { validEmailRegex } from 'utils/regex-expressions';

type Fields = {
  batchType: 'review' | 'pause';
  problemType: ProblemType;
  statuses: PurchaseStatusFilter[];
  nextOrderContainsVariantIds: string[];
  nextOrderDueBefore: string;
  nextOrderDueAfter: string;
  lastAssignedDoctorIds: string[];
  jobLimit: number | undefined;
  emails: string[];
  ids: string[];
};

const TWENTY_THREE_HOURS = 23 * 60 * 60 * 1000;

export const CreateBatchJob = (): JSX.Element => {
  const history = useHistory();
  const showNotification = useNotifications();
  const idempotencyKey = useRef(v4());
  const [submitting, setSubmitting] = useState(false);

  const [segmentType, setSegmentType] = useState<'filters' | 'customers'>(
    'filters',
  );
  const auth = useAuth();
  const userPermissions = new Set(auth.permissions);

  const [createStockoutToolingBatchMutation] = useMutation<
    CreateStockoutToolingBatchMutation,
    CreateStockoutToolingBatchMutationVariables
  >(
    gql`
      mutation CreateStockoutToolingBatch(
        $createStockoutToolingBatchInput: CreateStockoutToolingBatchInput!
      ) {
        createStockoutToolingBatch(input: $createStockoutToolingBatchInput) {
          batch {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        history.push(
          `${routes.bulkActions}/${data.createStockoutToolingBatch?.batch?.id}`,
        );

        showNotification({
          type: 'success',
          message: 'Batch created successfully',
        });
        setSubmitting(false);
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error creating batch',
        });
        setSubmitting(false);
      },
    },
  );

  const { data, error, loading } = useQuery<
    CreateBatchJobPageQuery,
    CreateBatchJobPageQueryVariables
  >(gql`
    query CreateBatchJobPage {
      brandConditions {
        id
        type
        supportsFlexiPlans
      }
      variants {
        id
        name
        inventory {
          id
          sku
        }
        product {
          id
          name
          flexiCompatible
          productType
          problemTypes
        }
      }
    }
  `);

  const {
    register,
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    getValues,
    formState: { isValid },
    watch,
    trigger,
  } = useForm<Fields>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      batchType: 'review',
      problemType: 'WEIGHT_LOSS',
      statuses: [],
      nextOrderContainsVariantIds: [],
      nextOrderDueBefore: '',
      nextOrderDueAfter: '',
      lastAssignedDoctorIds: [],
      jobLimit: 500,
      emails: [],
      ids: [],
    },
  });

  const { batchType, problemType } = watch();

  useEffect(() => {
    trigger();
  }, [batchType, trigger]);

  if (
    getValues().batchType === 'review' &&
    !userPermissions.has('BULK_CREATE_CONSULTATION')
  ) {
    control.setValue('batchType', 'pause');
  }

  if (
    getValues().batchType === 'pause' &&
    !userPermissions.has('BULK_PAUSE_PURCHASE')
  ) {
    control.setValue('batchType', 'review');
  }

  const validateDates = useCallback(() => {
    const { nextOrderDueBefore, nextOrderDueAfter } = getValues();

    const nextOrderDueBeforeDate = nextOrderDueBefore
      ? new Date(nextOrderDueBefore)
      : undefined;
    const nextOrderDueAfterDate = nextOrderDueAfter
      ? new Date(nextOrderDueAfter)
      : undefined;

    // Note: We use 23 not 24 because otherwise the datetime picker needs to have the
    // date AND time value incremented to be a valid selection.
    const earliestAllowableDate = new Date(
      new Date().getTime() + TWENTY_THREE_HOURS,
    );

    if (nextOrderDueBeforeDate) {
      if (nextOrderDueBeforeDate <= earliestAllowableDate) {
        setError('nextOrderDueBefore', {
          message: 'must be at least one day in the future',
        });
      } else if (
        nextOrderDueAfterDate &&
        nextOrderDueBeforeDate <= nextOrderDueAfterDate
      ) {
        setError('nextOrderDueBefore', {
          message: 'must be after NEXT ORDER AFTER',
        });
      } else {
        clearErrors('nextOrderDueBefore');
      }
    }

    if (nextOrderDueAfterDate) {
      if (nextOrderDueAfterDate <= earliestAllowableDate) {
        setError('nextOrderDueAfter', {
          message: 'must be at least one day in the future',
        });
      } else if (
        nextOrderDueBeforeDate &&
        nextOrderDueBeforeDate <= nextOrderDueAfterDate
      ) {
        setError('nextOrderDueAfter', {
          message: 'must be before NEXT ORDER BEFORE',
        });
      } else {
        clearErrors('nextOrderDueAfter');
      }
    }
  }, [getValues, setError, clearErrors]);

  const csvUploadInputRef = useRef<HTMLInputElement>(null);

  const handleCsvInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    control.setValue('emails', []);
    control.setValue('ids', []);

    const file = e.target.files?.[0];
    if (!file) {
      showNotification({
        type: 'error',
        message: 'File not found',
      });
      return;
    }
    const text = await file.text();
    const records = parse(text, {
      columns: true,
    });

    const ids: string[] = [];
    const emails: string[] = [];
    for (const record of records) {
      if ('id' in record) {
        if (
          record.id[0] !== 'c' ||
          record.id.length < 25 ||
          record.id.length > 28
        ) {
          showNotification({
            type: 'error',
            message: `ID "${record.id}" is invalid`,
          });
          break;
        }
        ids.push(record.id);
      } else if ('email' in record) {
        if (!validEmailRegex.test(record.email)) {
          showNotification({
            type: 'error',
            message: `Email "${record.email}" is invalid`,
          });
          break;
        }

        emails.push(record.email);
      } else {
        showNotification({
          type: 'error',
          message: `Expected column with header 'id' or 'email'`,
        });
        return;
      }
    }

    control.setValue('emails', emails);
    control.setValue('ids', ids);
  };

  const variantOptions = useMemo(() => {
    return (
      (data?.variants || [])
        .filter((variant) => variant.product.flexiCompatible)
        .filter((v) => v.product.problemTypes.includes(problemType))
        .map((variant) => ({
          value: variant.id,
          label: `${variant.product.productType} - ${variant.product.name} - ${
            variant.name
          }${variant.inventory?.sku || ''}`,
        })) || []
    );
  }, [data, problemType]);

  const onSubmit = handleSubmit(
    async ({
      batchType,
      problemType,
      statuses,
      nextOrderContainsVariantIds,
      nextOrderDueBefore,
      nextOrderDueAfter,
      lastAssignedDoctorIds,
      jobLimit,
      emails,
      ids,
    }) => {
      setSubmitting(true);

      switch (`${batchType}-${segmentType}`) {
        case `review-customers`:
          await createStockoutToolingBatchMutation({
            variables: {
              createStockoutToolingBatchInput: {
                idempotencyKey: idempotencyKey.current,
                createReviewConsultationCustomers: {
                  emails,
                  ids,
                  problemType,
                },
              },
            },
          });
          break;
        case `review-filters`:
          await createStockoutToolingBatchMutation({
            variables: {
              createStockoutToolingBatchInput: {
                idempotencyKey: idempotencyKey.current,
                createReviewConsultationFilters: {
                  problemType,
                  statuses,
                  nextOrderContainsVariantIds,
                  nextOrderDueBefore: nextOrderDueBefore
                    ? new Date(nextOrderDueBefore).toISOString()
                    : null,
                  nextOrderDueAfter: nextOrderDueAfter
                    ? new Date(nextOrderDueAfter).toISOString()
                    : null,
                  lastAssignedDoctorIds,
                },
                jobLimit: jobLimit || undefined,
              },
            },
          });
          break;
        case `pause-customers`:
          await createStockoutToolingBatchMutation({
            variables: {
              createStockoutToolingBatchInput: {
                idempotencyKey: idempotencyKey.current,
                pausePurchaseCustomers: {
                  emails,
                  ids,
                  problemType,
                },
              },
            },
          });
          break;
        case `pause-filters`:
          await createStockoutToolingBatchMutation({
            variables: {
              createStockoutToolingBatchInput: {
                idempotencyKey: idempotencyKey.current,
                pausePurchaseFilters: {
                  problemType,
                  nextOrderContainsVariantIds,
                  nextOrderDueBefore: nextOrderDueBefore
                    ? new Date(nextOrderDueBefore).toISOString()
                    : null,
                  nextOrderDueAfter: nextOrderDueAfter
                    ? new Date(nextOrderDueAfter).toISOString()
                    : null,
                  lastAssignedDoctorIds,
                },
                jobLimit: jobLimit || undefined,
              },
            },
          });
          break;
        default:
          throw new Error(
            `Batch type: "${batchType}" with segment type: "${segmentType}" is not supported`,
          );
      }
    },
  );

  if (error) {
    return (
      <div>
        Error - Unable to load variants, please contact #help-technology.
      </div>
    );
  }

  const supportedProblemTypes = new Set<string>();
  for (const bc of data?.brandConditions ?? []) {
    if (bc.supportsFlexiPlans) {
      supportedProblemTypes.add(bc.type);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <form onSubmit={onSubmit}>
        <h2 className="heading-md my-2">Bulk Action</h2>
        <div>
          <RadioButton
            id="review"
            name="batchType"
            label="Create Review Consultations"
            disabled={!userPermissions.has('BULK_CREATE_CONSULTATION')}
            ref={register()}
          />
          <RadioButton
            id="pause"
            name="batchType"
            label="Pause Customer Purchases"
            disabled={!userPermissions.has('BULK_CREATE_CONSULTATION')}
            ref={register()}
          />
        </div>
        <h2 className="heading-md my-2">Segment By</h2>
        <fieldset>
          <div className="w-1/2 border rounded flex justify-between">
            <label
              className={`bg-gray-100 hover:bg-gray-300 w-full text-center border-r border-gray-400 ${
                segmentType === 'customers' ? 'bg-gray-200' : ''
              }`}
            >
              Customer
              <input
                type="radio"
                name="filter-type"
                value="customers"
                checked={segmentType === 'customers'}
                onChange={() => setSegmentType('customers')}
                className="hidden"
              />
            </label>
            <label
              className={`bg-gray-100 hover:bg-gray-300 w-full text-center  ${
                segmentType === 'filters' ? 'bg-gray-200' : ''
              }`}
            >
              Filters
              <input
                type="radio"
                name="filter-type"
                value="filters"
                checked={segmentType === 'filters'}
                onChange={() => setSegmentType('filters')}
                className="hidden"
              />
            </label>
          </div>
        </fieldset>

        {(() => {
          switch (segmentType) {
            case 'customers':
              return (
                <>
                  <div className="my-4">
                    <Dropdown
                      name="problemType"
                      label="RX Purchase Problem Types"
                      errorMessage={errors.problemType?.message}
                      options={problemTypeOptions.filter(
                        (pto) =>
                          pto.value && supportedProblemTypes.has(pto.value),
                      )}
                      control={control}
                      rules={requiredValidation('problemType')}
                    />
                  </div>
                  <div className="flex space-x-2 items-baseline">
                    <label>
                      <Button
                        onClick={() => csvUploadInputRef.current?.click()}
                      >
                        Upload .csv
                      </Button>
                      <input
                        ref={csvUploadInputRef}
                        className="hidden"
                        type="file"
                        accept=".csv"
                        onChange={handleCsvInputChange}
                      />
                    </label>
                    {!!(
                      getValues().emails.length || getValues().ids.length
                    ) && (
                      <span>
                        {getValues().emails.length + getValues().ids.length}{' '}
                        customers detected in csv
                      </span>
                    )}
                  </div>
                </>
              );
            case 'filters':
              return (
                <>
                  <h2 className="heading-md my-2">Purchase Filters</h2>
                  <div className="my-4">
                    <Dropdown
                      name="problemType"
                      label="RX Purchase Problem Types"
                      errorMessage={errors.problemType?.message}
                      options={problemTypeOptions.filter(
                        (pto) =>
                          pto.value && supportedProblemTypes.has(pto.value),
                      )}
                      control={control}
                      rules={requiredValidation('problemType')}
                    />
                  </div>
                  <div className="my-4">
                    {batchType === 'review' && (
                      <Dropdown
                        name="statuses"
                        label="RX Purchase Statuses"
                        isMulti
                        errorMessage={errors.statuses
                          ?.map((ptErr) => ptErr?.message)
                          .join('. ')}
                        options={purchaseStatusFilterOptions}
                        control={control}
                      />
                    )}
                  </div>
                  <div className="my-4">
                    <Dropdown
                      name="nextOrderContainsVariantIds"
                      label="Variants in next order"
                      control={control}
                      isMulti
                      options={variantOptions}
                      errorMessage={errors.nextOrderContainsVariantIds
                        ?.map((ptErr) => ptErr?.message)
                        .join('. ')}
                    />
                  </div>
                  <div className="mb-5">
                    <div className="mb-1">
                      <Label htmlFor="dob">Next order before</Label>
                    </div>
                    <div>
                      <input
                        id="nextOrderDueBefore"
                        name="nextOrderDueBefore"
                        type="datetime-local"
                        onChange={validateDates}
                        ref={register()}
                        className="hover:border-gray-500 rounded p-3"
                      />
                      <InputError>
                        {errors.nextOrderDueBefore?.message}
                      </InputError>
                    </div>
                  </div>
                  <div className="mb-5">
                    <div className="mb-1">
                      <Label>Next order after</Label>
                    </div>
                    <div>
                      <input
                        id="nextOrderDueAfter"
                        name="nextOrderDueAfter"
                        type="datetime-local"
                        onChange={validateDates}
                        ref={register()}
                        className="hover:border-gray-500 rounded p-3"
                      />
                      <InputError>
                        {errors.nextOrderDueAfter?.message}
                      </InputError>
                    </div>
                  </div>

                  <h2 className="heading-md my-2">Customer Filters</h2>
                  <DoctorSelect
                    name="lastAssignedDoctorIds"
                    label="Most recently assigned doctor"
                    control={control}
                    showAllDoctors
                    isMulti
                    hasAllOption={false}
                    errorMessage={errors.lastAssignedDoctorIds
                      ?.map((ptErr) => ptErr?.message)
                      .join('. ')}
                  />
                  <h2 className="heading-md my-2">Size limit</h2>
                  <div className="mb-5">
                    <div className="mb-1">
                      <Label>
                        {(() => {
                          switch (batchType) {
                            case 'pause':
                              return 'How many purchases would you like to pause? (leave empty for no limit)';
                            case 'review':
                              return 'How many patients would you like to put into review consultations?';
                            default:
                              return <></>;
                          }
                        })()}
                      </Label>
                    </div>
                    <div>
                      <input
                        id="jobLimit"
                        name="jobLimit"
                        type="number"
                        ref={register({
                          valueAsNumber: true,
                          validate: (jobLimit) => {
                            if (batchType === 'pause' && jobLimit === '') {
                              return true;
                            }
                            if (!jobLimit || jobLimit <= 0) {
                              return 'Job limit must be greater than 0';
                            }
                            if (batchType === 'review' && jobLimit > 500) {
                              return 'Only 500 review consultations can be created at a time';
                            }
                            return true;
                          },
                        })}
                        className="hover:border-gray-500 rounded p-3"
                      />
                      <InputError>{errors.jobLimit?.message}</InputError>
                    </div>
                  </div>
                </>
              );
          }
        })()}

        <div className="border-b border-gray-600 pb-6 mb-4" />
        <Button
          type="submit"
          loading={loading || submitting}
          disabled={!isValid}
        >
          Create Batch
        </Button>
      </form>
    </div>
  );
};
