import { gql } from '@apollo/client';
import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import { Mermaid } from 'components/mermaid';
import { Modal } from 'components/modal';
import { OfferingSelectionsFragment } from 'graphql/types';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-6';
import { usePrevious } from 'react-use';
import { logger } from '../../logging';

type SequenceSetFormValues = {
  ssId: string;
  name: string;
  selectedSequenceId: string;
  isDisabled: boolean;
  sequences: { id: string; name: string }[];
};

type FormValues = {
  search?: string;
  sequenceSets: SequenceSetFormValues[];
};

type Props = {
  onClose: () => void;
  offerings?: OfferingSelectionsFragment[] | null;
  selection?: {
    offeringId?: string;
    sequences?: {
      sequenceId: string;
      isDisabled: boolean;
    }[];
  };
  onOfferingSelected: (selection: {
    offeringId: string;
    sequenceSelections: { sequenceSetId: string; sequenceId: string }[];
  }) => void;
  ctaText?: string;
  loading: boolean;
  updating: boolean;
};

function OfferingSelectModal({
  onClose,
  updating,
  loading,
  offerings,
  selection,
  ctaText = 'Select Offering',
  onOfferingSelected,
}: Props) {
  const [selectedOfferingId, setSelectedOfferingId] = useState<string>(
    selection?.offeringId ?? '',
  );

  const form = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: false,
  });

  const { remove, append } = useFieldArray({
    control: form.control,
    name: 'sequenceSets',
  });

  const sequenceSets = form.watch('sequenceSets');

  const canSubmit =
    selectedOfferingId && sequenceSets.every((ss) => !!ss.selectedSequenceId);

  const submit = form.handleSubmit((values: FormValues) => {
    onOfferingSelected({
      offeringId: selectedOfferingId,
      sequenceSelections: values.sequenceSets.map((ss) => ({
        sequenceSetId: ss.ssId,
        sequenceId: ss.selectedSequenceId,
      })),
    });
  });

  const selectedSequences = useMemo(() => {
    return new Map(
      selection?.sequences?.map((s) => [
        s.sequenceId,
        { id: s.sequenceId, disabled: s.isDisabled },
      ]) ?? [],
    );
  }, [selection?.sequences]);

  const previousOfferingId = usePrevious(selectedOfferingId);

  useEffect(() => {
    if (
      selectedOfferingId === previousOfferingId ||
      selectedOfferingId === ''
    ) {
      return;
    }

    const offering = offerings?.find((o) => o.id === selectedOfferingId);
    if (!offering) {
      logger.error(`expected to find offering with id: ${selectedOfferingId}`);
      return;
    }

    const activeSequenceSetOptions = [];

    for (const ss of offering.sequenceSets) {
      const sequences = ss.sequences;

      if (!sequences) {
        logger.error(`expected sequenceSet: ${ss.id} to have sequences`);
        return;
      }

      let matchingSequence: { id: string; disabled: boolean } | undefined;

      for (const s of sequences) {
        matchingSequence = selectedSequences.get(s.id);
        if (matchingSequence) {
          break;
        }
      }

      activeSequenceSetOptions.push({
        ssId: ss.id,
        name: ss.name,
        selectedSequenceId: matchingSequence?.id,
        isDisabled: matchingSequence?.disabled,
        sequences:
          ss.sequences?.map((s) => ({
            id: s.id,
            name: s.internalName,
          })) ?? [],
      });
    }

    append(activeSequenceSetOptions);
  }, [
    offerings,
    selectedOfferingId,
    previousOfferingId,
    append,
    selectedSequences,
  ]);

  const search = form.watch('search');
  const searchOfferings = useMemo(() => {
    if (!offerings) {
      return [];
    }
    if (!search) {
      return offerings;
    }
    return offerings.filter(
      (c) =>
        c.friendlyName &&
        c.friendlyName.toLowerCase().includes(search.toLowerCase()),
    );
  }, [offerings, search]);

  const SequenceView = ({
    sequenceSetId,
    sequenceId,
  }: {
    sequenceId: string;
    sequenceSetId: string;
  }) => {
    const sequence = offerings
      ?.find((o) => o.id === selectedOfferingId)
      ?.sequenceSets.find((ss) => ss.id === sequenceSetId)
      ?.sequences?.find((s) => s.id === sequenceId);

    if (!sequence) {
      logger.error(
        `expected to find sequence: ${sequenceId} for sequenceSet: ${sequenceSetId} in offering: ${selectedOfferingId}`,
      );
      return;
    }

    return <Mermaid key={sequence.id}>{sequence?.mermaid ?? ''}</Mermaid>;
  };

  return (
    <Modal show={true} onClose={onClose} width="w-2/3">
      <section className="bg-gray-200 p-5">
        <form onSubmit={submit}>
          <h2 className="heading-md mb-3">Offerings</h2>
          <div className="flex flex-col gap-3 mb-3 bg-gray-100 border-gray-500 border rounded p-4">
            <Input
              name="search"
              placeholder="Search..."
              ref={form.register()}
            />
          </div>
          <div className="flex flex-col gap-3 mb-3 bg-gray-100 border-gray-500 border rounded p-4 h-72 overflow-y-scroll">
            {searchOfferings.length === 0 && (
              <p className="text-gray-700">No Matching Offerings Found</p>
            )}
            {searchOfferings.map((offering) => (
              <div
                key={offering.id}
                className="flex gap-3 items-start cursor-pointer"
              >
                <input
                  id={offering.id}
                  onChange={() => {
                    setSelectedOfferingId(offering.id);
                    remove();
                  }}
                  value={offering.id}
                  checked={selectedOfferingId === offering.id}
                  type="radio"
                  className="cursor-pointer mt-1.5"
                />
                <label htmlFor={offering.id} className="cursor-pointer">
                  <p className="text-gray-600">{offering.friendlyName}</p>
                  <p className="text-xs text-gray-600">
                    {Array.from(
                      offering.sequenceSets?.map((ss) => ss.name),
                    ).join(', ')}
                  </p>
                </label>
              </div>
            ))}
          </div>
          {selectedOfferingId && (
            <div className="flex flex-col gap-3 mb-3 bg-gray-100 border-gray-500 border rounded p-4">
              <h2 className="heading-md">Selected Offering</h2>
              {
                offerings?.find((o) => o.id === selectedOfferingId)
                  ?.friendlyName
              }
            </div>
          )}
          {selectedOfferingId && (
            <div className="flex flex-col mb-3 bg-gray-100 border-gray-600 border rounded p-4">
              {!!sequenceSets?.length &&
                sequenceSets
                  .filter(
                    (seqSet): seqSet is SequenceSetFormValues => !!seqSet.ssId,
                  )
                  .map((ss, i) => (
                    <div key={ss.ssId} className="flex flex-col gap-3">
                      <h2 className="heading-md">{ss.name}</h2>
                      {(() => {
                        if (ss.selectedSequenceId) {
                          return (
                            <>
                              {ss.isDisabled ? (
                                <p>
                                  {
                                    ss.sequences.find(
                                      (s) => s.id === ss.selectedSequenceId,
                                    )?.name
                                  }{' '}
                                  <span className="text-xs text-gray-600">
                                    (This sequence cannot be changed)
                                  </span>
                                </p>
                              ) : (
                                <div className="flex gap-4">
                                  <p>
                                    {
                                      ss.sequences.find(
                                        (s) => s.id === ss.selectedSequenceId,
                                      )?.name
                                    }{' '}
                                  </p>
                                  <Button
                                    variant="outline"
                                    size="small"
                                    onClick={() => {
                                      form.setValue(
                                        `sequenceSets[${i}].selectedSequenceId`,
                                        undefined,
                                      );
                                    }}
                                  >
                                    change
                                  </Button>
                                </div>
                              )}
                              <div className="w-full bg-gray-100 border-gray-500 border rounded h-72">
                                <SequenceView
                                  sequenceId={ss.selectedSequenceId}
                                  sequenceSetId={ss.ssId}
                                />
                              </div>
                            </>
                          );
                        }
                        return (
                          <Dropdown
                            menuPlacement="top"
                            disabled={ss.isDisabled}
                            name={`sequenceSets[${i}].selectedSequenceId`}
                            label="Select Sequence"
                            options={
                              ss.sequences?.map((s) => ({
                                label: s.name,
                                value: s.id,
                              })) ?? []
                            }
                            control={form.control}
                          />
                        );
                      })()}
                      {sequenceSets.length - 1 !== i && (
                        <hr className="border-t border-gray-600 pb-4 mt-2" />
                      )}
                    </div>
                  ))}
            </div>
          )}
          <div className="flex gap-2">
            <Button
              color="danger"
              onClick={onClose}
              fullWidth
              disabled={loading || updating}
            >
              Cancel
            </Button>
            <Button
              color="success"
              type="submit"
              fullWidth
              disabled={!canSubmit || loading || updating}
              loading={loading || updating}
            >
              {ctaText}
            </Button>
          </div>
        </form>
      </section>
    </Modal>
  );
}

OfferingSelectModal.offeringFragment = gql`
  fragment OfferingSelections on Offering {
    id
    friendlyName
    sequenceSets {
      id
      name
      sequences {
        id
        internalName
        mermaid @include(if: $includeMermaid)
      }
    }
  }
`;

export { OfferingSelectModal };
