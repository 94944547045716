import React from 'react';
import { useForm } from 'react-hook-form-6';
import { Button } from 'components/button';
import { gql, useMutation } from '@apollo/client';
import { DoctorSelect } from 'components/doctor-select';

const assignDocument = gql`
  mutation AssignToDoctor($consultationIds: [String!]!, $doctorId: String!) {
    assignToDoctor(consultationIds: $consultationIds, doctorId: $doctorId)
  }
`;

export const AssignModal = ({
  consultationIds,
  onAssigned,
}: {
  consultationIds: string[];
  onAssigned: () => void;
}): React.ReactElement => {
  const { control, handleSubmit } = useForm<{
    doctorId: string;
  }>({});

  const [assignMutation, { loading }] = useMutation(assignDocument);

  return (
    <div className="bg-white rounded p-4">
      <p className="mb-3">
        Assign {consultationIds.length} consultation
        {consultationIds.length === 1 ? '' : 's'} to practitioner
      </p>
      <form
        onSubmit={handleSubmit(async (data) => {
          await assignMutation({
            variables: {
              consultationIds,
              doctorId: data.doctorId,
            },
          });

          onAssigned();
        })}
      >
        <DoctorSelect
          name="doctorId"
          control={control}
          showAllDoctors={false}
        />
        <div className="pt-4">
          <Button fullWidth type="submit" loading={loading}>
            Assign
          </Button>
        </div>
      </form>
    </div>
  );
};
