import { Button } from 'components/button';
import { Label } from 'components/label';
import { Snipplet } from 'components/snipplet';
import { config } from 'config';
import cuid from 'cuid';
import { useNotifications } from 'notifications';
import React, { useCallback, useEffect, useRef } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form-6';
import { FaRegImage } from 'react-icons/fa';
import { InputError } from 'components/input-error';
import { useAuth } from '../auth';

type FileUploadTypes = 'image' | 'pdf' | 'legacy-image';

interface ControlledFileUploadProps {
  control: Control;
  name: string;
  type?: FileUploadTypes;
  rules?: RegisterOptions;
  label?: string;
  disabled?: boolean;
  bucket?: 'PATHOLOGY' | 'ASSETS';
  errorMessage?: string;
}

export const ControlledFileUpload = (
  props: ControlledFileUploadProps,
): JSX.Element => {
  return (
    <Controller
      defaultValue=""
      control={props.control}
      rules={props.rules}
      name={props.name}
      render={({ onChange, value }): JSX.Element => (
        <FileUpload
          onChange={onChange}
          value={value}
          label={props.label}
          type={props.type}
          disabled={props.disabled}
          bucket={props.bucket}
          errorMessage={props.errorMessage}
        />
      )}
    />
  );
};

const FileUpload = ({
  type = 'image',
  onChange,
  value,
  label,
  disabled,
  bucket,
  errorMessage,
}: {
  type: FileUploadTypes | undefined;
  onChange: (event: string) => void;
  value: string;
  label?: string;
  disabled?: boolean;
  bucket?: 'PATHOLOGY' | 'ASSETS';
  errorMessage?: string;
}): JSX.Element => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const showNotification = useNotifications();
  const { accessToken: token } = useAuth();

  let acceptedFileTypes: string[] = [];

  switch (type) {
    case 'pdf':
      acceptedFileTypes = ['application/pdf'];
      break;
    case 'legacy-image':
      acceptedFileTypes = ['image/jpeg', 'image/png'];
      break;
    case 'image':
    default:
      acceptedFileTypes = ['image/webp', 'image/avif'];
      break;
  }

  const getPathologySignedGetAndUploadUrl = useCallback(
    async (fileName: string): Promise<Response> => {
      const url = `${config.restApiUrl}/rest/aws/pathology-signed-get-url`;

      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          name: fileName,
        }),
      });
    },
    [token],
  );

  // Handle initial value of image upload
  useEffect(() => {
    if (value && !fileUrl) {
      try {
        const { filename, url } = JSON.parse(value);
        if (bucket === 'PATHOLOGY') {
          (async (): Promise<void> => {
            if (filename.toLowerCase().includes('redacted')) {
              setFileUrl('[REDACTED]');
              return;
            }
            const response = await getPathologySignedGetAndUploadUrl(filename);
            const json = await response.json();
            setFileUrl(json.getUrl);
          })();
        } else {
          setFileUrl(url);
        }
      } catch {
        return;
      }
    }
  }, [value, fileUrl, bucket, getPathologySignedGetAndUploadUrl]);

  const handleClick = (): void => {
    fileInput?.current?.click();
  };

  function buildImageString(
    url: string,
    filename: string,
    mimetype: string,
  ): string {
    return JSON.stringify({
      url,
      filename,
      mimetype,
    });
  }

  const checkFileTypeAndSize = (file: File): boolean => {
    const incorrectFileType = !acceptedFileTypes.includes(file.type);

    const fileSizeExceeded =
      type === 'pdf' ? file.size > 20000000 : file.size > 300000;

    if (incorrectFileType || fileSizeExceeded) {
      showNotification({
        type: 'error',
        message: incorrectFileType
          ? 'Incorrect file type.'
          : 'File must be less than 20 MB for PDFs and 300KB for images.',
      });
      return true;
    }

    return false;
  };

  const getSignedUrl = async (
    bucket: 'ASSETS' | 'PATHOLOGY' = 'ASSETS',
    fileName: string,
    fileType: string,
  ): Promise<Response> => {
    const baseUrl = `${config.restApiUrl}/rest/aws/`;
    const endpoint =
      bucket === 'ASSETS'
        ? 'assets-signed-upload-url'
        : 'pathology-signed-upload-url';

    return fetch(baseUrl + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({
        name: fileName,
        type: fileType,
      }),
    });
  };

  const uploadToSignedUrl = (
    uploadURL: string,
    data: string | ArrayBuffer | null,
    type: string,
  ): Promise<Response> | undefined => {
    if (!data) {
      return;
    }

    return fetch(uploadURL, {
      method: 'PUT',
      body: new Blob([data], { type }),
    });
  };

  const handleFileOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileList: FileList | null = e?.target?.files;
    const file = fileList?.[0];
    if (!file) {
      return;
    }

    if (checkFileTypeAndSize(file)) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (): Promise<void> => {
      const fileExtension = file.name.match(/\.[0-9a-z]+$/i);
      const fileName = `${cuid()}${fileExtension}`;

      try {
        setLoading(true);
        const signedUrl = await getSignedUrl(bucket, fileName, file.type);
        const json = await signedUrl.json();
        await uploadToSignedUrl(json.uploadURL, reader.result, file.type);
        const url = json.getUrl
          ? json.getUrl
          : `${config.assetsUrl}/${fileName}`;
        setFileUrl(url);
        onChange(buildImageString(url, fileName, file.type));
      } catch {
        showNotification({
          type: 'error',
          message: 'Unable to upload image.',
        });
      } finally {
        setLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      {label && (
        <div className="mb-3">
          <Label>{label}</Label>
        </div>
      )}
      {(type === 'image' || type === 'legacy-image') && (
        <div className="w-full h-64 bg-gray-300 flex items-center justify-center mb-5">
          {fileUrl ? (
            <img
              src={`${fileUrl}`}
              alt="Upload preview"
              className="w-auto max-w-full max-h-full"
            />
          ) : (
            <FaRegImage className="text-gray-700 text-xl" />
          )}
        </div>
      )}
      {type === 'pdf' && (
        <>
          {fileUrl && (
            <div className="mb-5 test">
              <Snipplet text={fileUrl} type="link" />
            </div>
          )}
        </>
      )}
      <Button
        fullWidth
        onClick={handleClick}
        loading={loading}
        disabled={loading || disabled}
      >
        Upload
      </Button>
      <input
        type="file"
        ref={fileInput}
        className="hidden"
        onChange={handleFileOnChange}
        accept={acceptedFileTypes.join(', ')}
      />
      {type === 'image' && (
        <a
          href="https://squoosh.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline text-center block m-2 "
        >
          Convert and compress your images to WebP format here
        </a>
      )}
      <InputError>{errorMessage}</InputError>
    </div>
  );
};
