import { useState, type FunctionComponent, useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Modal } from 'components/modal';
import { Button } from 'components/button';
import type {
  DayOfWeek,
  PractitionerBookingWindowConfigsQuery,
  PractitionerBookingWindowConfigsQueryVariables,
  ProblemType,
  QueueSummaryWindowFragment,
  UpdatePractitionerBookingWindowConfigsMutation,
  UpdatePractitionerBookingWindowConfigsMutationVariables,
} from 'graphql/types';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { Loading } from 'components/loading';
import { useForm } from 'react-hook-form-6';
import { capitaliseString } from 'utils/misc';
import { Input } from 'components/react-hook-form-6/input';
import { useNotifications } from 'notifications';
import QueueTable from './queue-table';

export type EditCapacityModalProps = {
  show: boolean;
  onClose: () => void;
  onComplete?: () => void;
  problemType: ProblemType;
  queueTableProps: {
    columnHeadings: string[];
    rowHeadings: string[];
    cells: Map<string, QueueSummaryWindowFragment>;
    currentRow?: string;
  };
};

const formatTime = (hours: number) =>
  `${hours % 12 === 0 ? 12 : hours % 12}${
    Math.floor(hours / 12) % 2 === 0 ? 'am' : 'pm'
  }`;

export const EditCapacityModal: FunctionComponent<EditCapacityModalProps> = ({
  show,
  onClose,
  onComplete,
  problemType,
  queueTableProps,
}) => {
  const showNotification = useNotifications();

  const { data, loading: configsLoading } = useQuery<
    PractitionerBookingWindowConfigsQuery,
    PractitionerBookingWindowConfigsQueryVariables
  >(
    gql`
      query PractitionerBookingWindowConfigs($problemTypes: [ProblemType!]!) {
        practitionerBookingWindowConfigs(
          input: { problemTypes: $problemTypes }
        ) {
          id
          name
          startAt
          duration
          capacity
          dayOfWeek
        }
      }
    `,
    {
      variables: {
        problemTypes: [problemType],
      },
    },
  );

  const { columnHeadings, rowHeadings, cells, defaultValues } = useMemo(
    () => getTableConfig(data?.practitionerBookingWindowConfigs),
    [data],
  );

  const [updateWindowConfigs, { loading: updateLoading }] = useMutation<
    UpdatePractitionerBookingWindowConfigsMutation,
    UpdatePractitionerBookingWindowConfigsMutationVariables
  >(gql`
    mutation UpdatePractitionerBookingWindowConfigs(
      $updates: [UpdatePractitionerBookingWindowConfigsInput!]!
    ) {
      updatePractitionerBookingWindowConfigs(updates: $updates) {
        practitionerBookingWindowConfigs {
          id
          name
          startAt
          duration
          capacity
          dayOfWeek
        }
      }
    }
  `);

  const { register, watch, handleSubmit, errors, reset } = useForm({
    defaultValues,
  });

  const currentValues = watch();

  let newFormValues = defaultValues;
  const onSubmit = handleSubmit(async (formData) => {
    try {
      const updates = Object.keys(formData)
        .filter((id) => formData[id] !== defaultValues[id])
        .map((id) => ({ id, capacity: formData[id] }));

      if (updates.length) {
        const { data } = await updateWindowConfigs({ variables: { updates } });
        const tableConfig = getTableConfig(
          data?.updatePractitionerBookingWindowConfigs
            ?.practitionerBookingWindowConfigs,
        );
        newFormValues = tableConfig.defaultValues;

        showNotification({
          type: 'success',
          message: 'Practitioner booking window configs successfully updated',
        });
      }
    } catch {
      showNotification({
        type: 'error',
        message: 'Unable to update practitioner booking window configs',
      });
    }

    onComplete?.();
    onClose();
    reset(newFormValues);
  });

  const onModalClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal show={show} onClose={onModalClose} width="w-3/4">
      <div className="bg-gray-200 p-6">
        <div className="flex flex-row gap-4 justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Edit cap on queue windows</h2>
          <ProblemTypeTag problemType={problemType} />
        </div>

        <p className="text-sm mb-2">
          Once a window has reached capacity it will be automatically blocked.
          Admin can override this function by manually blocking or increasing
          the cap on the window.
        </p>

        <p className="text-sm italic mb-8">
          [This is because our new recommendation is to increase the cap on the
          window rather than unlocked manually]
        </p>

        <form onSubmit={onSubmit}>
          <div className="mb-8">
            {configsLoading ? (
              <Loading />
            ) : (
              <table className="w-full table-fixed border-collapse text-sm">
                <thead>
                  <tr>
                    <th></th>
                    {columnHeadings.map((day) => (
                      <th key={day}>{capitaliseString(day.toLowerCase())}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {rowHeadings.map((interval) => (
                    <tr key={interval}>
                      <th>{interval}</th>

                      {columnHeadings.map((day) => {
                        const windowConfigId = cells[`${day}_${interval}`];

                        return (
                          <td key={day} className="p-2">
                            {windowConfigId ? (
                              <Input
                                ref={register({ valueAsNumber: true })}
                                type="number"
                                name={windowConfigId}
                                errorMessage={errors[windowConfigId]?.message}
                                defaultValue={defaultValues[windowConfigId]}
                                readOnly={updateLoading}
                                isDirty={
                                  currentValues[windowConfigId] !==
                                  defaultValues[windowConfigId]
                                }
                              />
                            ) : (
                              'N/A'
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="mb-8">
            <h3 className="font-semibold mb-4">Reference: current queue</h3>
            <QueueTable {...queueTableProps} />
          </div>

          <div className="flex flex-row gap-4">
            <Button fullWidth color="danger" onClick={onModalClose}>
              Cancel
            </Button>

            <Button
              fullWidth
              loading={configsLoading || updateLoading}
              type="submit"
              color="primary"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export type EditCapacityModalButtonProps = {
  onComplete?: () => void;
  problemType: ProblemType;
  queueTableProps: {
    columnHeadings: string[];
    rowHeadings: string[];
    cells: Map<string, QueueSummaryWindowFragment>;
    currentRow?: string;
  };
};

export const EditCapacityModalButton: FunctionComponent<
  EditCapacityModalButtonProps
> = ({ onComplete, problemType, queueTableProps }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        fullWidth
        fillHeight
        variant="outline"
        onClick={() => setShowModal(true)}
      >
        Edit cap
      </Button>

      <EditCapacityModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onComplete={onComplete}
        problemType={problemType}
        queueTableProps={queueTableProps}
      />
    </>
  );
};

type BookingWindowConfigForTable =
  | PractitionerBookingWindowConfigsQuery['practitionerBookingWindowConfigs']
  | undefined
  | null;
const getTableConfig = (bookingWindowConfigs: BookingWindowConfigForTable) => {
  const daysOfWeek: DayOfWeek[] = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
  const intervals = new Set<string>();
  const cells: Record<string, string> = {};
  const defaultValues: Record<string, number> = {};
  const windowConfigs = bookingWindowConfigs ?? [];

  for (const windowConfig of windowConfigs) {
    const { id, duration, dayOfWeek, capacity } = windowConfig;
    const [startAtHours] = windowConfig.startAt.split(':').map(Number);

    const seconds = duration.seconds;

    const hours = Math.floor(seconds / 3600);

    const interval = `${formatTime(startAtHours)} - ${formatTime(
      startAtHours + hours,
    )}`;

    intervals.add(interval);
    cells[`${dayOfWeek}_${interval}`] = windowConfig.id;
    defaultValues[id] = capacity;
  }

  return {
    columnHeadings: daysOfWeek,
    rowHeadings: Array.from(intervals),
    cells,
    defaultValues,
  };
};
