import { gql, useMutation } from '@apollo/client';
import { Checkbox } from 'components/checkbox';
import { ConfirmModal } from 'components/confirm-modal';
import { Input } from 'components/react-hook-form-6/input';
import { InputError } from 'components/input-error';
import React from 'react';
import { useForm } from 'react-hook-form-6';

const reissueEprescriptionTokenMutation = gql`
  mutation reissueEprescriptionToken(
    $scriptId: String!
    $email: String
    $mobile: String
  ) {
    reissueEprescriptionToken(
      scriptId: $scriptId
      email: $email
      mobile: $mobile
    )
  }
`;

export type ReissueTokenModalProps = {
  show: boolean;
  patientEmail?: string;
  patientMobile?: string;
  scriptId: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const ReissueTokenModal = ({
  show,
  patientEmail,
  patientMobile,
  scriptId,
  onConfirm,
  onClose,
}: ReissueTokenModalProps): React.ReactElement => {
  const {
    register,
    watch,
    handleSubmit,
    errors,
    getValues,
    setError,
    clearErrors,
  } = useForm<{
    email: string | null;
    mobile: string | null;
    sendToEmail: boolean;
    sendToMobile: boolean;
    scriptId: string;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: patientEmail,
      mobile: patientMobile,
      sendToEmail: true,
      sendToMobile: false,
      scriptId,
    },
  });
  const [reissueToken] = useMutation<{
    scriptId: string;
    email: boolean;
    mobile: boolean;
  }>(reissueEprescriptionTokenMutation);
  const validate = (): void => {
    validateAtLeastOneOption();
    validateAtLeastOneOptionPopulated();
  };

  const validateAtLeastOneOption = (): void => {
    const errorMessage = 'Please select at least one option';
    const values = getValues();
    const isValid = values.sendToEmail || values.sendToMobile;

    if (!isValid) {
      setError('sendToEmail', { message: errorMessage });
      setError('sendToMobile', { message: errorMessage });
    } else {
      clearErrors('sendToEmail');
      clearErrors('sendToMobile');
    }
  };
  const validateAtLeastOneOptionPopulated = (): void => {
    const errorMessage = 'A value must be provided';
    const values = getValues();
    let isValid = true;
    if (values.sendToEmail && !values.email) {
      isValid = false;
      setError('email', { message: errorMessage });
    }
    if (values.sendToMobile && !values.mobile) {
      isValid = false;
      setError('mobile', { message: errorMessage });
    }
    if (isValid) {
      clearErrors('email');
      clearErrors('mobile');
    }
  };

  const watchedSendToEmail = watch().sendToEmail;
  const watchedSendToMobile = watch().sendToMobile;

  return (
    <ConfirmModal
      show={show}
      onConfirm={async (): Promise<void> => {
        if (
          !(
            errors.email?.message ||
            errors.mobile?.message ||
            errors.sendToEmail?.message ||
            errors.sendToMobile?.message
          )
        ) {
          const submitFn = handleSubmit(
            async ({ sendToEmail, sendToMobile, email, mobile }) => {
              await reissueToken({
                variables: {
                  email: sendToEmail && email ? email : null,
                  mobile: sendToMobile && mobile ? mobile : null,
                  scriptId,
                },
              })
                // eslint-disable-next-line no-console
                .catch((error) => console.error(error));
              onConfirm();
            },
          );
          await submitFn();
        }
      }}
      onClose={onClose}
    >
      <form>
        <h4 className="heading-sm">Re-issue ePrescription Token</h4>
        <fieldset>
          <Checkbox
            name="sendToEmail"
            ref={register()}
            onChange={validate}
            label="Send to email?"
          />
          <Input
            name="email"
            label="Email"
            type="text"
            ref={register}
            onChange={validate}
            errorMessage={errors.email?.message}
            disabled={!watchedSendToEmail}
          />
        </fieldset>
        <fieldset>
          <Checkbox
            name="sendToMobile"
            ref={register()}
            onChange={validate}
            label="Send to mobile?"
          />
          <Input
            name="mobile"
            label="Mobile"
            type="text"
            ref={register}
            onChange={validate}
            errorMessage={errors.mobile?.message}
            disabled={!watchedSendToMobile}
          />
        </fieldset>
        <InputError>
          {errors.sendToEmail?.message || errors.sendToMobile?.message}
        </InputError>
      </form>
    </ConfirmModal>
  );
};
