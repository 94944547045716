import { gql } from '@apollo/client';
import { Copyable } from 'components/copyable';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import {
  ConsignmentFulfillmentVendor,
  ConsignmentsSectionFragment,
} from 'graphql/types';
import { ConsignmentStatusTag } from 'pages/core-order';
import { Link } from 'react-router-dom';
import { formatDateAndTime } from 'utils/misc';
import { routes } from 'utils/routes';

const INTERNAL_VENDORS: ConsignmentFulfillmentVendor[] = [
  'SVC_CORE_ACCESS',
  'CONSULTATION',
];

const ConsignmentsSection = (props: {
  consignments: ConsignmentsSectionFragment['consignments'];
}): React.ReactNode => {
  const consignments = (props.consignments || []).filter(
    (consignment) => !INTERNAL_VENDORS.includes(consignment.fulfillmentVendor),
  );
  consignments.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  );

  return (
    <div className="overflow-hidden rounded shadow my-4 bg-white pt-2 border-b border-gray-200">
      <div className="border-b px-4 py-4 border-gray-200 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Consignments
          <span className="ml-2 text-gray-500">{consignments.length}</span>
        </h3>
      </div>
      <div className="overflow-x-auto">
        <div className="min-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <SmallTableHeadings
                headings={[
                  'ID',
                  'Order',
                  'Products',
                  'Updated',
                  'Created',
                  'Status',
                  'Tracking',
                ]}
              />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {consignments.map((consignment) => (
                <ConsignmentRow
                  key={consignment.id}
                  consignment={consignment}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ConsignmentRow = ({
  consignment,
}: {
  consignment: NonNullable<ConsignmentsSectionFragment['consignments']>[number];
}) => {
  return (
    <tr>
      <td className="px-6 py-2">
        <Copyable text={consignment.id}>
          {(copied) => (
            <pre className="text-xs cursor-pointer leading-5 text-gray-500">
              {copied ? 'Copied' : consignment.id.slice(-6)}
            </pre>
          )}
        </Copyable>
      </td>
      <td className="px-6 py-2">
        {consignment.order ? (
          <Link
            to={`/orders/core/${consignment.order?.id}`}
            className="flex flex-row gap-1 underline text-xs leading-5 text-gray-500"
          >
            {consignment.order.id.slice(-6)}
          </Link>
        ) : (
          '—'
        )}
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <div className="flex flex-col gap-0.5">
          {consignment.lineItems.map(
            (li) =>
              li.variant?.product && (
                <Link
                  key={li.id}
                  to={`${routes.products}/${li.variant.product.id}`}
                  className="flex flex-row gap-1 underline"
                >
                  <span className="text-xs leading-5">{li.variant.name}</span>
                  {li.quantity > 1 && (
                    <span className="text-xs leading-5">x{li.quantity}</span>
                  )}
                </Link>
              ),
          )}
        </div>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {formatDateAndTime(consignment.updatedAt)}
        </span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {formatDateAndTime(consignment.createdAt)}
        </span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <ConsignmentStatusTag status={consignment.status} />
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {consignment.fulfillmentVendorDetails?.trackingUrl ? (
            <a
              href={consignment.fulfillmentVendorDetails.trackingUrl}
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Tracking
            </a>
          ) : (
            '—'
          )}
        </span>
      </td>
    </tr>
  );
};

ConsignmentsSection.fragment = gql`
  fragment ConsignmentsSection on Condition {
    consignments {
      id
      order {
        id
      }
      lineItems {
        id
        quantity
        variant {
          id
          name
          product {
            id
          }
        }
      }
      fulfillmentVendor
      fulfillmentVendorDetails {
        id
        ... on EvermedFulfillmentVendorDetails {
          trackingUrl
        }
        ... on PluripharmFulfillmentVendorDetails {
          trackingUrl
        }
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export default ConsignmentsSection;
