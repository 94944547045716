import {
  Maybe,
  PathologyPanel,
  PathologyPanelTest,
  PathologyRequest,
} from 'graphql/types';
import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import {
  combineRules,
  maxLengthValidation,
  positiveFloatValidation,
  requiredValidation,
} from 'utils/form-validation';
import { FormFields, isResultComplete, showResultInputs } from './valueHelper';

const pathologyResultOptions: Option[] = [
  { label: 'POSITIVE', value: 'POSITIVE' },
  { label: 'NEGATIVE', value: 'NEGATIVE' },
];

const PositiveNegativeInputs = ({
  panel,
  test,
  control,
  register,
  errors,
}: {
  panel: PathologyPanel;
  test: PathologyPanelTest;
  control: ReturnType<typeof useForm>['control'];
  register: ReturnType<typeof useForm>['register'];
  errors: ReturnType<typeof useForm>['errors'];
}): React.ReactElement => {
  return (
    <div className="grid grid-cols-13 gap-2">
      <div className="col-span-4">
        <Dropdown
          name={`panels.${panel.id}.${test.id}.result.value`}
          control={control}
          options={pathologyResultOptions}
          defaultValue={test.result?.result.value ?? undefined}
        />
      </div>
      <div className="col-span-9">
        <Input
          name={`panels.${panel.id}.${test.id}.note`}
          ref={register(maxLengthValidation('note', 500))}
          errorMessage={
            errors?.panels?.[`${panel.id}`]?.[`${test.id}`]?.note?.message
          }
        />
      </div>
    </div>
  );
};

const UnitWithRangeInputs = ({
  panel,
  test,
  register,
  errors,
  currentValues,
}: {
  panel: PathologyPanel;
  test: PathologyPanelTest;
  register: ReturnType<typeof useForm>['register'];
  errors: ReturnType<typeof useForm>['errors'];
  currentValues: FormFields;
}): React.ReactElement => {
  return (
    <div className="grid grid-cols-13 gap-2">
      <div className="col-span-3">
        <Input
          placeholder="Value"
          step={0.01}
          name={`panels.${panel.id}.${test.id}.result.number`}
          type="text"
          ref={register(positiveFloatValidation('number'))}
          errorMessage={
            errors?.panels?.[`${panel.id}`]?.[`${test.id}`]?.result?.number
              ?.message
          }
        />
      </div>
      <div className="col-span-3">
        <Input
          placeholder="Unit"
          name={`panels.${panel.id}.${test.id}.result.unit`}
          readOnly
          ref={register(requiredValidation('Unit'))}
          errorMessage={
            errors?.panels?.[`${panel.id}`]?.[`${test.id}`]?.result?.unit
              ?.message
          }
        />
      </div>
      <div className="col-span-3">
        <Input
          placeholder="Min Range"
          name={`panels.${panel.id}.${test.id}.result.range.low`}
          type="text"
          step={0.01}
          ref={register(
            combineRules(
              requiredValidation('min range'),
              positiveFloatValidation('number'),
              {
                validate: {
                  smallerThanMaxValidation: (
                    value: string,
                  ): boolean | string => {
                    const highString =
                      currentValues?.panels?.[`${panel.id}`]?.[`${test.id}`]
                        ?.result?.range?.high;
                    if (highString && value) {
                      if (parseFloat(value) > parseFloat(highString)) {
                        return `Min range must be smaller than Max range ${highString}`;
                      }
                    }
                    return true;
                  },
                },
              },
            ),
          )}
          errorMessage={
            errors?.panels?.[`${panel.id}`]?.[`${test.id}`]?.result?.range?.low
              ?.message
          }
        />
      </div>
      <div className="col-span-1 h-12 flex items-center justify-center">
        &ndash;
      </div>
      <div className="col-span-3">
        <Input
          placeholder="Max Range"
          step={0.01}
          name={`panels.${panel.id}.${test.id}.result.range.high`}
          type="text"
          ref={register(
            combineRules(
              requiredValidation('max range'),
              positiveFloatValidation('number'),
              {
                validate: {
                  biggerThanMinValidation: (
                    value: string,
                  ): boolean | string => {
                    const minString =
                      currentValues?.panels?.[`${panel.id}`]?.[`${test.id}`]
                        ?.result?.range?.low;
                    if (minString && value) {
                      if (parseFloat(value) < parseFloat(minString)) {
                        return `Max range must be bigger than Min range ${minString}`;
                      }
                    }
                    return true;
                  },
                },
              },
            ),
          )}
          errorMessage={
            errors?.panels?.[`${panel.id}`]?.[`${test.id}`]?.result?.range?.high
              ?.message
          }
        />
      </div>
    </div>
  );
};

const PanelTestsHeader = ({
  panelTests,
}: {
  panelTests: Maybe<PathologyPanelTest[]>;
}): React.ReactElement => (
  <div className="grid grid-cols-12 gap-3 font-semibold text-sm">
    <div className="col-span-2">Test</div>
    <div className="col-span-8 grid grid-cols-13 gap-2">
      {panelTests?.every(
        (test) => test.result?.result.type === 'UNIT_WITH_RANGE',
      ) && (
        <>
          <div className="col-span-3">Result</div>
          <div className="col-span-3">Unit</div>
          <div className="col-span-3">Low range</div>
          <div className="col-span-1"></div>
          <div className="col-span-3">High range</div>
        </>
      )}
      {panelTests?.every(
        (test) => test.result?.result.type === 'POSITIVE_NEGATIVE',
      ) && (
        <>
          <div className="col-span-4">Result</div>
          <div className="col-span-9">Note</div>
        </>
      )}
    </div>
  </div>
);

const PathologyResults = ({
  pathologyRequest,
  control,
  register,
  errors,
  currentValues,
}: {
  pathologyRequest: PathologyRequest;
  control: ReturnType<typeof useForm>['control'];
  register: ReturnType<typeof useForm>['register'];
  errors: ReturnType<typeof useForm>['errors'];
  currentValues: FormFields;
}): React.ReactElement => {
  if (!showResultInputs(pathologyRequest.panels)) {
    return <></>;
  }

  return (
    <section>
      <h2 className="heading-md mb-3">Results</h2>
      <section className="space-y-8">
        {pathologyRequest.panels?.map(
          (panel, index) =>
            panel && (
              <div key={panel.id}>
                <h3 className="heading-sm mb-3">Panel {index + 1}</h3>
                <section className="p-4 space-y-4 shadow-card bg-white divide-y divide-gray-200">
                  <PanelTestsHeader panelTests={panel.tests} />
                  {panel?.tests?.map(
                    (test) =>
                      test && (
                        <section
                          key={test.id}
                          className="grid grid-cols-12 gap-3 pt-4"
                        >
                          <div className="col-span-2 text-sm font-medium">
                            {test.displayName} ({test.formName})
                          </div>
                          <input
                            type="hidden"
                            {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              ...register(
                                `panels.${panel.id}.${test.id}.result.type`,
                              )
                            }
                          />
                          {test.result?.result.type == 'POSITIVE_NEGATIVE' && (
                            <div className="col-span-8">
                              <PositiveNegativeInputs
                                panel={panel}
                                test={test}
                                control={control}
                                register={register}
                                errors={errors}
                              />
                            </div>
                          )}
                          {test.result?.result.type == 'UNIT_WITH_RANGE' && (
                            <div className="col-span-8">
                              <UnitWithRangeInputs
                                panel={panel}
                                test={test}
                                register={register}
                                errors={errors}
                                currentValues={currentValues}
                              />
                            </div>
                          )}
                          <div className="col-span-2 text-xs h-12 flex items-center font-medium">
                            {isResultComplete(
                              currentValues?.panels?.[panel.id]?.[test.id],
                            ) ? (
                              <span className="text-green-700">
                                Complete result
                              </span>
                            ) : (
                              <span className="text-orange-700">
                                Incomplete result will not be saved
                              </span>
                            )}
                          </div>
                        </section>
                      ),
                  )}
                </section>
              </div>
            ),
        )}
      </section>
    </section>
  );
};

export default PathologyResults;
