import type { FunctionComponent, ChangeEventHandler } from 'react';
import type { RequestReviewStepTransitionProps } from './types';
import { RequestReviewLayout } from './request-review-layout';
import { RadioButton } from 'components/radio-button';
import { useForm } from 'react-hook-form-6';
import { Tooltip } from 'components/tooltip';

export type AssignOption = 'doctor' | 'queue';

const isAssignOption = (
  maybeAssignOption: unknown,
): maybeAssignOption is AssignOption => {
  return (
    typeof maybeAssignOption === 'string' &&
    ['doctor', 'queue'].includes(maybeAssignOption)
  );
};

type AssignOptionStepProps = RequestReviewStepTransitionProps & {
  assignOption?: AssignOption;
  setAssignOption: (assignOption: AssignOption) => void;
  customerHasMobileNumber: boolean;
};

export const AssignOptionStep: FunctionComponent<AssignOptionStepProps> = ({
  previousStep,
  nextStep,
  assignOption,
  setAssignOption,
  customerHasMobileNumber,
}) => {
  const options: {
    label: string;
    value: AssignOption;
    disabled: boolean;
    tooltip: string;
  }[] = [
    {
      label: 'Assign directly to a practitioner (bypass sync consult queue)',
      value: 'doctor',
      disabled: false,
      tooltip: '',
    },
    {
      label: 'Move to sync consult queue',
      value: 'queue',
      disabled: !customerHasMobileNumber,
      tooltip: customerHasMobileNumber
        ? ''
        : 'Patient must have a valid mobile number set to be placed in the queue.',
    },
  ];

  const { register, handleSubmit, watch } = useForm<{
    assignOption: AssignOption;
  }>({ defaultValues: { assignOption } });

  const fieldValue = watch('assignOption');

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (!isAssignOption(value)) {
      return;
    }
    setAssignOption(value);
  };

  const onSubmit = handleSubmit((formData) => {
    setAssignOption(formData.assignOption);
    nextStep();
  });

  return (
    <RequestReviewLayout
      title="Confirm practitioner assigning"
      previous={previousStep}
      nextDisabled={!fieldValue}
      onSubmit={onSubmit}
    >
      {options.map(({ label, value, disabled, tooltip }) => (
        <div key={value} className="flex">
          <RadioButton
            key={value}
            ref={register}
            disabled={disabled}
            id={value}
            name="assignOption"
            label={label}
            onChange={onChange}
          />
          {tooltip && <Tooltip hoverText={tooltip} />}
        </div>
      ))}
    </RequestReviewLayout>
  );
};
