import { gql, useMutation } from '@apollo/client';
import { MutationCancelMembershipArgs, ProblemType } from 'graphql/types';
import { Button } from 'components/button';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import { Checkbox } from '../../components/checkbox';
import { onError } from 'logging';

const cancelMutation = gql`
  mutation cancelMembership(
    $userId: String!
    $type: ProblemType!
    $refund: Boolean
  ) {
    cancelMembership(userId: $userId, type: $type, refund: $refund) {
      id
    }
  }
`;

export const CancelModal = ({
  userId,
  type,
  onCancelled,
  close,
}: {
  userId: string;
  type: ProblemType;
  onCancelled: () => void;
  close: () => void;
}): React.ReactElement => {
  const { register, handleSubmit } = useForm<{
    refund: boolean;
  }>();

  const [cancel, { loading }] = useMutation<void, MutationCancelMembershipArgs>(
    cancelMutation,
    {
      onError,
    },
  );

  return (
    <div className="bg-white rounded p-4">
      <p className="mb-3">
        Are you sure you want to cancel the membership? The patient will no
        longer have access to their treatment.
      </p>
      <form
        onSubmit={handleSubmit(async (data) => {
          await cancel({
            variables: {
              userId,
              type,
              refund: !!data.refund,
            },
          });

          onCancelled();
        })}
      >
        <div className="flex items-center">
          <Checkbox
            name={'refund'}
            ref={register()}
            label={'Do you want to refund the consultation?'}
          />
        </div>
        <div className="pt-4 flex flex-row space-x-3">
          <Button fullWidth type="submit" color="danger" loading={loading}>
            Cancel Membership
          </Button>
          <Button
            fullWidth
            variant={'outline'}
            loading={loading}
            onClick={close}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};
