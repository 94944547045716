import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { RadioButton } from 'components/radio-button';
import { AddNoteModalConsultationFragment, ProblemType } from 'graphql/types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form-6';
import { formatDate, upperSnakeCaseToCapitalCase } from 'utils/misc';

export const SelectNoteTarget: React.FC<{
  consultations: AddNoteModalConsultationFragment[];
  consultation: AddNoteModalConsultationFragment | undefined;
  onUpdate: (
    consultation: AddNoteModalConsultationFragment | undefined,
  ) => void;
}> = ({ consultations, consultation, onUpdate }) => {
  const problemTypes = useMemo(
    () => Array.from(new Set(consultations.map((c) => c.type))),
    [consultations],
  );

  const { watch, handleSubmit, control, errors, register } = useForm<{
    target: 'CONSULTATION' | 'CUSTOMER';
    problemType: ProblemType | undefined;
    consultationId: string | undefined;
  }>({
    defaultValues: {
      target: consultation ? 'CONSULTATION' : 'CUSTOMER',
      problemType: problemTypes[0],
    },
  });

  const problemType = watch('problemType');
  const target = watch('target');

  const consultationsForProblemType = useMemo(
    () => consultations.filter((t) => t.type === problemType),
    [consultations, problemType],
  );

  const onSubmit = handleSubmit(({ consultationId }) => {
    const consultation = consultations.find((c) => c.id === consultationId);

    onUpdate(consultation);
  });

  return (
    <li>
      <form onSubmit={onSubmit} className="flex space-x-4">
        <div className="space-y-2">
          <div className="heading-sm block">Target: </div>
          <div className="text-sm">
            <RadioButton
              id="CUSTOMER"
              name="target"
              label="Patient"
              ref={register()}
            />
            <RadioButton
              id="CONSULTATION"
              name="target"
              label="Consultation"
              ref={register()}
            />
          </div>
        </div>
        <div className="w-1/3">
          <Dropdown
            disabled={target === 'CUSTOMER'}
            label="Problem Type"
            name="problemType"
            control={control}
            options={problemTypes.map((pt) => ({
              value: pt,
              label: upperSnakeCaseToCapitalCase(pt),
            }))}
            rules={{ required: true }}
          />
        </div>
        <div className="w-2/3">
          <Dropdown
            disabled={target === 'CUSTOMER'}
            name="consultationId"
            label="Consultation"
            control={control}
            rules={{ required: target === 'CONSULTATION' }}
            options={consultationsForProblemType.map((c) => ({
              value: c.id,
              label: `${upperSnakeCaseToCapitalCase(c.stage)} - ${
                c.id
              } - ${formatDate(c.createdAt)}`,
            }))}
            errorMessage={errors.consultationId?.message}
          />
        </div>
        <div className="my-auto">
          <Button type="submit" variant="outline" size="small">
            Save details
          </Button>
        </div>
      </form>
    </li>
  );
};
