import { FC, ReactElement, ReactNode, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form-6';
import {
  ConfirmStepDoctorQuery,
  ConfirmStepDoctorQueryVariables,
  ProblemType,
  ReopenConsultationMutation,
  ReopenConsultationMutationVariables,
} from 'graphql/types';
import { useAuth } from 'auth';
import { MarkdownEditor } from 'components/markdown-editor';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { requiredValidation } from 'utils/form-validation';
import { Button } from 'components/button';
import { DoctorSelect } from 'components/doctor-select';
import { Markdown } from 'components/markdown';
import { InputError } from 'components/input-error';

type MultiStageModalLayoutProps = {
  title: string;
  previous: () => void;
  onSubmit: () => void;
  children: ReactNode;
  finalStage?: boolean;
  submitting?: boolean;
};

export const MultiStageModalLayout: React.FC<MultiStageModalLayoutProps> = ({
  previous,
  children,
  title,
  onSubmit,
  finalStage = false,
  submitting = false,
}) => {
  return (
    <>
      <h3 className="heading-md mb-2">{title}</h3>
      {children}
      <div className="w-full flex flex-row justify-end gap-4 mt-4">
        <div>
          <Button fullWidth variant="outline" type="button" onClick={previous}>
            Back
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            type="submit"
            loading={submitting}
            onClick={onSubmit}
          >
            {finalStage ? 'Confirm' : 'Next'}
          </Button>
        </div>
      </div>
    </>
  );
};

type AdminNotesStepProps = {
  nextStep: () => void;
  previousStep: () => void;
  notes: string;
  setAdminNotes: (adminNotes: string) => void;
  patientFullName: string;
  problemType: ProblemType;
};

const AdminNotesStep: FC<AdminNotesStepProps> = ({
  previousStep,
  nextStep,
  notes,
  setAdminNotes,
  patientFullName,
  problemType,
}) => {
  const { user } = useAuth();
  const { register, handleSubmit, watch, errors } = useForm<{
    adminNotes: string;
  }>({
    defaultValues: {
      adminNotes: notes,
    },
  });

  const adminNotes = watch().adminNotes;

  return (
    <MultiStageModalLayout
      title="Add a new note"
      previous={previousStep}
      onSubmit={handleSubmit(({ adminNotes }) => {
        setAdminNotes(adminNotes);
        nextStep();
      })}
    >
      <div className="flex flex-row w-full items-start space-x-5">
        <div className="flex text-sm gap-4">
          <div className="space-y-1">
            <p>
              <strong>Author: </strong>
              {user?.fullName}
            </p>
            <p>
              <strong>Author type: </strong>
              <AuthorTypeTag author={user} />
            </p>
          </div>
          <div className="space-y-1">
            <p>
              <strong>Patient: </strong>
              {patientFullName}
            </p>
            <p>
              <strong>Problem type: </strong>
              <ProblemTypeTag problemType={problemType} />
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm space-y-3">
        <p>
          This is for internal use only. Other admins, practitioners and coaches
          will be able to see these notes.
        </p>
      </div>
      <MarkdownEditor
        ref={register(requiredValidation('adminNotes'))}
        name="adminNotes"
        markdown={adminNotes}
        errorMessage={errors.adminNotes?.message}
      />
    </MultiStageModalLayout>
  );
};

type DoctorAssignStepProps = {
  nextStep: () => void;
  previousStep: () => void;
  doctorId?: string;
  setDoctorId: (id: string) => void;
};

export const DoctorAssignStep: React.FC<DoctorAssignStepProps> = ({
  previousStep,
  nextStep,
  doctorId,
  setDoctorId,
}) => {
  const { control, handleSubmit, errors } = useForm<{
    doctorId: string;
  }>({ defaultValues: { doctorId } });

  const onSubmit = handleSubmit(({ doctorId }) => {
    setDoctorId(doctorId);
    nextStep();
  });

  return (
    <MultiStageModalLayout
      title="Choose a practitioner to assign this consultation to"
      previous={previousStep}
      onSubmit={onSubmit}
    >
      <DoctorSelect
        name="doctorId"
        rules={{
          required: 'A doctor must be selected for the reopened consultation',
        }}
        control={control}
        showAllDoctors={false}
      />
      {errors.doctorId?.message && (
        <InputError>{errors.doctorId.message}</InputError>
      )}
    </MultiStageModalLayout>
  );
};

type ConfirmStepProps = {
  nextStep: () => void;
  previousStep: () => void;
  adminNotes: string;
  doctorId: string;
  consultationId: string;
};

export const ConfirmStep: React.FC<ConfirmStepProps> = ({
  previousStep,
  nextStep: onCompleted,
  adminNotes,
  doctorId,
  consultationId,
}) => {
  const { data, loading: confirmStepDoctorLoading } = useQuery<
    ConfirmStepDoctorQuery,
    ConfirmStepDoctorQueryVariables
  >(
    gql`
      query ConfirmStepDoctor($doctorId: String!) {
        user(where: { id: $doctorId }) {
          id
          clinicianName
        }
      }
    `,
    {
      variables: {
        doctorId,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const [reopenConsultation, { loading: reopenConsultationLoading }] =
    useMutation<
      ReopenConsultationMutation,
      ReopenConsultationMutationVariables
    >(
      gql`
        mutation ReopenConsultation($input: ReopenConsultationInput!) {
          reopenConsultation(input: $input) {
            consultation {
              id
              status
              stage
              createdAt
              completedAt
              isApproved
              doctor {
                id
                firstName
                lastName
                fullName
                clinicianName
              }
              prescribedSequences {
                id
                status
              }
              purchasePrompt {
                id
              }
            }
          }
        }
      `,
      {
        onCompleted,
      },
    );

  const onConfirm = (): void => {
    reopenConsultation({
      variables: {
        input: {
          consultationId,
          adminNotes: adminNotes,
          assignToDoctorId: doctorId,
        },
      },
    });
  };

  return (
    <MultiStageModalLayout
      title="Confirm reopen last consultation"
      onSubmit={onConfirm}
      previous={previousStep}
      finalStage={true}
      submitting={reopenConsultationLoading}
    >
      <ul className="space-y-4">
        {!confirmStepDoctorLoading && data?.user?.clinicianName && (
          <li className="space-y-2">
            <h4 className="heading-sm">Assigned to:</h4>
            <div>{data.user.clinicianName}</div>
          </li>
        )}
        <li className="space-y-2">
          <h4 className="heading-sm">Admin notes:</h4>
          <Markdown
            src={adminNotes}
            className="text-gray-700 ml-4 border-t border-b py-4 max-h-96 overflow-auto"
          />
        </li>
      </ul>
    </MultiStageModalLayout>
  );
};

type ReopenLastConsultationStep = 'adminNotes' | 'doctorAssign' | 'confirm';

export type ReopenLastConsultationProps = {
  onCancel: () => void;
  onCompleted: () => void;
  problemType: ProblemType;
  patientFullName: string;
  consultationId: string;
};

export const ReopenLastConsultation: FC<ReopenLastConsultationProps> = ({
  onCancel,
  onCompleted,
  problemType,
  patientFullName,
  consultationId,
}) => {
  const [step, setStep] = useState<ReopenLastConsultationStep>('adminNotes');
  const [adminNotes, setAdminNotes] = useState<string>('');
  const [doctorId, setDoctorId] = useState<string>('');

  const renderStep = (): ReactElement => {
    switch (step) {
      case 'adminNotes':
        return (
          <AdminNotesStep
            previousStep={onCancel}
            nextStep={(): void => setStep('doctorAssign')}
            patientFullName={patientFullName}
            setAdminNotes={setAdminNotes}
            notes={adminNotes}
            problemType={problemType}
          />
        );
      case 'doctorAssign':
        return (
          <DoctorAssignStep
            previousStep={(): void => setStep('adminNotes')}
            nextStep={(): void => setStep('confirm')}
            doctorId={doctorId}
            setDoctorId={setDoctorId}
          />
        );
      case 'confirm':
        return (
          <ConfirmStep
            previousStep={(): void => setStep('doctorAssign')}
            nextStep={onCompleted}
            adminNotes={adminNotes}
            doctorId={doctorId}
            consultationId={consultationId}
          />
        );
    }
  };

  return (
    <div className="bg-white flex flex-col rounded p-4 space-y-4 w-full">
      {renderStep()}
    </div>
  );
};
