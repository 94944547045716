import { gql, useMutation, useQuery } from '@apollo/client';
import { Input } from 'components/react-hook-form-6/input';
import {
  ConvertEScriptToPaperModalQuery,
  ConvertEScriptToPaperModalQueryVariables,
  ConvertEScriptToPaperMutation,
} from 'graphql/types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-6';
import {
  requiredValidation,
  positiveIntegerValidation,
} from 'utils/form-validation';
import { useNotifications } from 'notifications';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { Modal } from 'components/modal';

const convertEScriptToPaperMutation = gql`
  mutation convertEScriptToPaper($scriptId: String!, $repeats: Int!) {
    convertEscriptToPaper(scriptId: $scriptId, repeats: $repeats)
  }
`;

const query = gql`
  query ConvertEScriptToPaperModal($scriptId: String!) {
    script(where: { id: $scriptId }) {
      id
      repeats
      type
    }
  }
`;

export const ConvertEScriptToPaperModal = ({
  scriptId,
  show,
  onClose,
  onConfirm,
}: {
  show: boolean;
  scriptId: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const [convert, { loading: converting }] =
    useMutation<ConvertEScriptToPaperMutation>(convertEScriptToPaperMutation);

  const { data: scriptData, loading: loadingScriptData } = useQuery<
    ConvertEScriptToPaperModalQuery,
    ConvertEScriptToPaperModalQueryVariables
  >(query, {
    variables: {
      scriptId,
    },
  });

  const { register, handleSubmit, errors, reset } = useForm<{
    repeats: string;
  }>();

  const { repeats } = scriptData?.script ?? {};

  useEffect(() => {
    reset({
      repeats: repeats?.toString() ?? '0',
    });
  }, [reset, repeats]);

  const showNotification = useNotifications();

  const handleTriggerConversion = handleSubmit(async (formData) => {
    try {
      await convert({
        variables: {
          scriptId,
          repeats: parseInt(formData.repeats),
        },
      });

      showNotification({
        type: 'success',
        message: 'Script converted',
      });

      onConfirm();
    } catch (error) {
      showNotification({
        type: 'error',
        message: `Unable to convert script: ${
          error instanceof Error ? error.message : 'no error message'
        }`,
      });
    }
  });

  if (loadingScriptData) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  if (scriptData?.script?.type !== 'ESCRIPT') {
    return null;
  }

  if (!scriptData.script) {
    return <div>There was an issue loading the script</div>;
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="bg-white rounded p-4">
        <p>
          This will cancel the patient&apos;s existing ePrescription and
          generate a paper script. It should only be used if the patient has
          requested it. Please set the number of repeats for the script.
        </p>
        <form onSubmit={handleTriggerConversion} className="pt-6">
          <Input
            label="Repeats"
            type="text"
            defaultValue={scriptData.script.repeats.toString()}
            ref={register({
              ...requiredValidation('Repeats'),
              ...positiveIntegerValidation('repeats'),
            })}
            errorMessage={errors?.repeats?.message}
            name="repeats"
          />
          <div className="pt-4 flex space-x-4">
            <Button
              fullWidth
              variant="outline"
              onClick={onClose}
              disabled={converting}
            >
              Cancel
            </Button>
            <Button fullWidth loading={converting} color="danger" type="submit">
              Convert
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
