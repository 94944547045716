import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { Modal } from 'components/modal';
import {
  InputMaybe,
  CreateGoalModalPillarTemplatesFragment,
} from 'graphql/types';
import { useForm } from 'react-hook-form-6';
import { useGoalsSearchParams, usePillarAndLevelOptions } from './hooks';
import { gql } from '@apollo/client';

export const CreateGoalModalPillarTemplatesFragmentDoc = gql`
  fragment CreateGoalModalPillarTemplates on LevelTreePillarTemplate {
    id
    name
    levels {
      id
      levelNumber
    }
  }
`;

export const CreateGoalModal = ({
  pillars,
  onClose,
  onCreateGoal,
}: {
  pillars: CreateGoalModalPillarTemplatesFragment[] | null | undefined;
  onCreateGoal: (levelId: InputMaybe<string>) => Promise<void>;
  onClose: () => void;
}): React.ReactElement => {
  const searchParams = useGoalsSearchParams();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{
    pillarId: InputMaybe<string>;
    levelId: InputMaybe<string>;
  }>({
    mode: 'onChange',
    defaultValues: searchParams,
  });
  const selectedPillarId = watch().pillarId;

  const { pillarOptions, levelOptions } = usePillarAndLevelOptions(
    pillars,
    selectedPillarId,
  );

  const onSubmit = handleSubmit(async (data) => {
    await onCreateGoal(data.levelId);
  });

  return (
    <Modal isAutoOverflow={false} show={true} onClose={onClose}>
      <form
        onSubmit={onSubmit}
        className="bg-white rounded p-4 flex flex-col space-y-8"
      >
        <div className="flex-col space-y-6">
          <div className="flex flex-col space-y-2">
            <p className="text-lg text-gray-900 font-semibold">Create a goal</p>
            <p className="text-gray-800">
              Get started by selecting the pillar and level this goal will exist
              in. Once confirmed, you cannot change this.
            </p>
          </div>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <Dropdown
                name="pillarId"
                label="Pillar"
                options={pillarOptions}
                control={control}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                name="levelId"
                label="Level"
                options={levelOptions}
                control={control}
                disabled={!selectedPillarId}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <Button fullWidth color="danger" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            fullWidth
            variant="solid"
            type="submit"
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};
