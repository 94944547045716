import { useForm } from 'react-hook-form-6';
import React from 'react';
import { Input } from '../../../components/react-hook-form-6/input';

export const ShopifySection = ({
  register,
}: {
  register: ReturnType<typeof useForm>['register'];
}): React.ReactElement => {
  return (
    <div>
      <h2 className="heading-md mb-5">Shopify</h2>
      <Input
        label="Shopify product ID"
        ref={register}
        name="shopifyProductId"
      />
    </div>
  );
};
