import { gql, useQuery, useMutation } from '@apollo/client';
import { validate } from 'uuid';
import { PathologyRequest } from 'graphql/types';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form-6';
import { useParams } from 'react-router-dom';
import PathologyAside from './pathology-aside';
import PathologyLab from './pathology-lab';
import PathologyResults from './pathology-results';
import PathologyConfirm from './pathology-confirm';
import { onError } from 'logging';
import { pathologyRequestFragment } from '../../fragments';
import {
  FormFields,
  formatPathologyResultData,
  resultToFormResultValue,
} from './valueHelper';
import { canReleasePathologyResults } from 'utils/pathology';
import PathologyReleaseWarning from './pathology-release-warning';

const pathologyRequestDocument = gql`
  query PathologyResult($pathologyRequestId: String!) {
    pathologyRequest(id: $pathologyRequestId) {
      id
      ...pathologyRequestInfo
    }
  }
  ${pathologyRequestFragment}
`;

const handleReleaseResultsDocument = gql`
  mutation HandleReleasePathologyResults(
    $consultationId: String!
    $doctorId: String!
  ) {
    handleReleasePathologyResults(
      consultationId: $consultationId
      doctorId: $doctorId
    ) {
      id
    }
  }
`;

const createPathologyResultDocument = gql`
  mutation CreatePathologyResult(
    $lab: String!
    $url: String!
    $collectedDate: String!
    $pathologyRequestId: String!
    $results: [PathologyResultValueInput!]!
  ) {
    createPathologyResult(
      lab: $lab
      url: $url
      collectedDate: $collectedDate
      pathologyRequestId: $pathologyRequestId
      results: $results
    ) {
      ...pathologyRequestInfo
    }
  }
  ${pathologyRequestFragment}
`;

const Pathology = (): React.ReactElement => {
  const showNotification = useNotifications();
  const { pathologyRequestId } = useParams<{ pathologyRequestId: string }>();
  const [isFertilityHormone, setIsFertilityHormone] = useState<boolean>(false);

  const { handleSubmit, control, register, watch, trigger, errors, reset } =
    useForm<FormFields>();

  const { data, loading, error, refetch } = useQuery<{
    pathologyRequest: PathologyRequest;
  }>(pathologyRequestDocument, {
    variables: { pathologyRequestId },
    skip: !validate(pathologyRequestId),
    onCompleted: (data) => {
      if (!data.pathologyRequest) {
        showNotification({
          type: 'error',
          message: 'No matching pathology request was found',
        });
        return;
      }

      const values: FormFields = {};

      if (data.pathologyRequest.consultation?.type === 'FERTILITY_HORMONE') {
        setIsFertilityHormone(true);
        values.lab = 'Healius';
      }

      if (data?.pathologyRequest?.result?.file?.url) {
        values.resultsUrl = data.pathologyRequest.result.file.url;
      }

      if (data?.pathologyRequest?.result?.lab) {
        values.lab = data.pathologyRequest.result.lab;
      }

      if (data.pathologyRequest.consultation?.doctor?.id) {
        values.doctorId = data.pathologyRequest.consultation.doctor.id;
      } else if (data.pathologyRequest.doctor?.id) {
        values.doctorId = data.pathologyRequest.doctor.id;
      }

      if (data.pathologyRequest?.result?.collectedDate) {
        values.collectedDate = data.pathologyRequest?.result?.collectedDate;
      } else if (data.pathologyRequest.customerStatedCollectedDate) {
        values.collectedDate =
          data.pathologyRequest.customerStatedCollectedDate;
      }

      // Sets form note + result values on GraphQL query completion
      if (data?.pathologyRequest?.panels) {
        data.pathologyRequest.panels?.forEach(
          (panel) =>
            panel?.tests?.forEach((test) => {
              if (test.result) {
                if (!values.panels) {
                  values.panels = {};
                }
                values.panels[panel.id] = {
                  ...values.panels[panel.id],
                  [test.id]: {
                    note: test?.result?.note ?? null,
                    result: resultToFormResultValue(test.result.result),
                  },
                };
              }
            }),
        );
      }
      reset(values);
    },

    onError: () => {
      showNotification({
        type: 'error',
        message:
          'There was an error attempting to retrieve this pathology request',
      });
    },
  });

  const [handleReleaseResultsMutation] = useMutation(
    handleReleaseResultsDocument,
  );

  const [createPathologyResultMutation] = useMutation(
    createPathologyResultDocument,
  );

  const [loadingReleaseResults, setLoadingReleaseResults] =
    React.useState(false);

  const [loadingSaveResults, setLoadingSaveResults] = React.useState(false);

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  if (loading) {
    return <Loading />;
  }

  const pathologyRequest = data?.pathologyRequest;
  const consultation = pathologyRequest?.consultation;
  const treatment = consultation?.treatment;

  if (error || !pathologyRequest) {
    return <div>No matching pathology request was found</div>;
  }

  const hasResults = !!pathologyRequest?.result;

  const allowReleasePathologyResults = canReleasePathologyResults({
    problemType: consultation?.type,
    consultationStatus: consultation?.status,
    treatmentStatus: treatment?.status,
  });

  const handleReleaseToDoctor = async (): Promise<void> => {
    // Validate required fields - lab and results file
    const result = await trigger();

    if (!result) {
      // Error messages surfaced under input fields
      return;
    }

    // Validate all results have a value
    const formattedData = formatPathologyResultData(watch(), pathologyRequest);

    if (
      formattedData.length !==
      data?.pathologyRequest.panels?.reduce(
        (sum, panel) => sum + (panel?.tests?.length || 0),
        0,
      )
    ) {
      showNotification({
        type: 'error',
        message: 'Incomplete pathology results',
      });
      return;
    }

    setShowConfirmModal(true);
  };

  const handleSubmitReleaseToDoctor = handleSubmit(async (data) => {
    setLoadingReleaseResults(true);

    try {
      await createPathologyResultMutation({
        variables: {
          lab: data.lab,
          url: data.resultsUrl,
          pathologyRequestId: pathologyRequest.id,
          collectedDate: data.collectedDate,
          results: formatPathologyResultData(data, pathologyRequest),
        },
      });

      showNotification({
        type: 'success',
        message: 'Pathology results saved',
      });

      await handleReleaseResultsMutation({
        variables: {
          consultationId: pathologyRequest?.consultation?.id,
          doctorId: data.doctorId,
        },
      });

      showNotification({
        type: 'success',
        message: 'Results released to practitioner',
      });
    } catch (err) {
      showNotification({
        type: 'error',
        message: 'Unable to save and release results',
      });
      if (err instanceof Error) {
        onError(err);
      }
    } finally {
      setLoadingReleaseResults(false);
      setShowConfirmModal(false);
      await refetch();
    }
  });

  const handleSubmitSaveResults = handleSubmit(async (data) => {
    setLoadingSaveResults(true);

    const partialResults = formatPathologyResultData(data, pathologyRequest);

    try {
      await createPathologyResultMutation({
        variables: {
          lab: data.lab,
          url: data.resultsUrl,
          pathologyRequestId: pathologyRequest.id,
          results: partialResults,
          collectedDate: data.collectedDate,
        },
      });
      showNotification({
        type: 'success',
        message: 'Pathology results saved',
      });
    } catch (err) {
      showNotification({
        type: 'error',
        message: 'Unable to save pathology results',
      });
      onError(err);
    } finally {
      setLoadingSaveResults(false);
    }
  });

  return (
    <section className="flex space-x-4">
      <form className="w-2/3">
        <div className="space-y-8">
          <PathologyLab
            control={control}
            labDisabled={hasResults || isFertilityHormone}
            errors={errors}
            register={register}
          />
          <PathologyResults
            pathologyRequest={pathologyRequest}
            control={control}
            register={register}
            errors={errors}
            currentValues={watch()}
          />
          <div className="mb-32 flex space-x-5">
            <Button
              fullWidth
              variant="outline"
              onClick={handleSubmitSaveResults}
              loading={loadingSaveResults}
            >
              Save results
            </Button>
            <Button
              fullWidth
              onClick={handleReleaseToDoctor}
              disabled={!allowReleasePathologyResults}
            >
              Save & release to practitioner
            </Button>
          </div>
          {!allowReleasePathologyResults && (
            <PathologyReleaseWarning pathologyRequest={pathologyRequest} />
          )}
        </div>

        <Modal
          show={showConfirmModal}
          isAutoOverflow={true}
          onClose={(): void => setShowConfirmModal(false)}
        >
          <PathologyConfirm
            control={control}
            pathologyRequest={pathologyRequest}
            localInputResults={watch()}
            loading={loadingReleaseResults}
            onSubmit={handleSubmitReleaseToDoctor}
          />
        </Modal>
      </form>
      <div className="w-1/3 border-l-2 px-4 border-gray-300">
        <PathologyAside pathologyRequest={pathologyRequest} />
      </div>
    </section>
  );
};

export default Pathology;
