import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import {
  CreateOfferingMutation,
  CreateOfferingMutationVariables,
  CreateOfferingPageQuery,
  CreateOfferingPageQueryVariables,
  ProblemType,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { renderToStaticMarkup } from 'react-dom/server';
import { useEffect } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
  UseFormMethods,
} from 'react-hook-form-6';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { problemTypeOptions } from 'utils/dropdown-options';
import { requiredValidation } from 'utils/form-validation';
import { routes } from 'utils/routes';
import { icons } from './icons';
import SharedOfferingForm from 'pages/offering/offering-form';
import { v4 } from 'uuid';
import { Checkbox } from 'components/checkbox';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

const createOfferingMutation = gql`
  mutation CreateOffering($input: CreateOfferingInput!) {
    createOffering(input: $input) {
      offering {
        id
      }
    }
  }
`;

type FormSequenceSet = {
  name: string;
  prescribable: 'true' | 'false' | 'both';
  problemTypes: ProblemType[];
  sequenceIds: string[];
  synced: boolean;
  pinTarget: boolean;
};

type FormValues = {
  friendlyName: string;
  advertisedName: string;
  description: string;
  problemTypes: ProblemType[];
  photo: string;
  sequenceSets: FormSequenceSet[];
  contents: { icon: string; description: string; tooltip: string | null }[];
  advertisedShippingCadence: string;
  advertisedPrice: {
    header: string;
    qualifier: string;
    cost: number;
    cadenceDescription: string;
  };
  tags: { label: string; value: string }[];
};

const isPrescribableOptions = [
  {
    label: 'Only Prescription Sequences',
    value: 'true',
  },
  {
    label: 'Only Non-Prescription Sequences (e.g. OTC, Access)',
    value: 'false',
  },
  {
    label: 'Both Prescription and Non-Prescription Sequences',
    value: 'both',
  },
];

const contentIconOptions = icons.map((icon) => ({
  label: icon,
  value: renderToStaticMarkup(icon),
}));

const optionsToBoolean = (
  value: 'both' | 'true' | 'false',
): boolean | undefined => {
  switch (value) {
    case 'both':
      return undefined;
    case 'true':
      return true;
    case 'false':
      return false;
  }
};

const CreateSequenceSetFields = ({
  control,
  register,
  index,
  numSequenceSets,
  remove,
  errors,
  sequenceOptions,
}: {
  control: Control<FormValues>;
  register: UseFormMethods<FormValues>['register'];
  index: number;
  remove: (idx: number) => void;
  errors?: DeepMap<FormSequenceSet, FieldError>;
  numSequenceSets: number;
  sequenceOptions: Array<{ label: string; value: string }>;
}): JSX.Element => {
  const syncGroupsEnabled = useFeatureFlagClient().getBoolean(
    'ff_sync_groups_create',
    { defaultValue: false },
  );

  return (
    <div className="p-4 bg-white shadow-card">
      {numSequenceSets > 1 && (
        <div className="float-right w-min">
          <Button fullWidth onClick={(): void => remove(index)} color="danger">
            <FaRegTrashAlt />
          </Button>
        </div>
      )}
      <fieldset className="flex flex-col gap-4">
        <div>
          <Input
            placeholder={'e.g. RX Weight'}
            name={`sequenceSets[${index}].name`}
            label="Name"
            errorMessage={errors?.name?.message}
            ref={register(requiredValidation(`sequenceSets[${index}].name`))}
          />
        </div>
        <div>
          <Dropdown
            name={`sequenceSets[${index}].prescribable`}
            label="Prescribable"
            options={isPrescribableOptions}
            control={control}
            errorMessage={errors?.prescribable?.message}
            rules={requiredValidation(`sequenceSets[${index}].prescribable`)}
            tooltipProps={{
              hoverText:
                'This filters out sequences that are: RX, OTC or both.',
            }}
          />
        </div>
        <div>
          <Dropdown
            name={`sequenceSets[${index}].sequenceIds`}
            label="Specified Sequences"
            isMulti
            errorMessage={errors?.sequenceIds
              ?.map((ptErr) => ptErr?.message)
              .join('. ')}
            options={sequenceOptions}
            control={control}
            tooltipProps={{
              hoverText:
                'These are the exact sequences this Sequence Set will display',
            }}
          />
        </div>
        <div>
          {numSequenceSets !== 1 && (
            <Dropdown
              name={`sequenceSets[${index}].problemTypes`}
              label="Problem Types"
              isMulti
              errorMessage={errors?.problemTypes
                ?.map((ptErr) => ptErr?.message)
                .join('. ')}
              options={problemTypeOptions}
              control={control}
              tooltipProps={{
                hoverText:
                  'These are the problem that are used to determine what sequences this Sequence Set will display',
              }}
            />
          )}
        </div>
        <div className="flex gap-8">
          <div className="flex-1">
            <Checkbox
              label="Sync with other sequence sets"
              description="Sequences selected from synced sequence sets will be connected. Any actions (e.g. reschedules, pauses, immediate refills) are performed against all synced sequences."
              ref={register()}
              disabled={!syncGroupsEnabled}
              name={`sequenceSets[${index}].synced`}
              onChange={() => {
                const { sequenceSets } = control.getValues();
                for (const [ssIdx] of sequenceSets.entries()) {
                  control.setValue(`sequenceSets[${ssIdx}].pinTarget`, false);
                }
              }}
            />
          </div>
          <div className="flex-1">
            <Checkbox
              label="Pin target"
              description="Separately-purchased OTC-addons will be bundled with orders originating from this sequence set."
              ref={register()}
              disabled={!syncGroupsEnabled}
              onChange={(e) => {
                const { sequenceSets } = control.getValues();
                const self = sequenceSets[index];
                for (const [ssIdx, ss] of sequenceSets.entries()) {
                  if (ssIdx !== index) {
                    control.setValue(`sequenceSets[${ssIdx}].pinTarget`, false);
                  }

                  if (ss.synced && self.synced) {
                    control.setValue(
                      `sequenceSets[${ssIdx}].pinTarget`,
                      e.target.checked,
                    );
                  }
                }
              }}
              name={`sequenceSets[${index}].pinTarget`}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export const CreateOffering = (): JSX.Element => {
  const showNotification = useNotifications();
  const history = useHistory();
  const { register, control, handleSubmit, errors, watch, formState } =
    useForm<FormValues>({
      mode: 'onChange',
      shouldUnregister: false,
      defaultValues: {
        problemTypes: [],
        sequenceSets: [
          {
            name: '',
            prescribable: 'both',
            problemTypes: [],
            synced: false,
          },
        ],
        advertisedPrice: {
          header: '',
          qualifier: '',
          cost: 0,
          cadenceDescription: '',
        },
        advertisedShippingCadence: '',
        contents: [],
      },
    });

  const {
    fields: sequenceSetFields,
    append: appendSequenceSetField,
    remove: removeSequenceSetField,
  } = useFieldArray<FormValues['sequenceSets'][number]>({
    control,
    name: 'sequenceSets',
  });
  const {
    fields: offeringContentsFields,
    append: appendOfferingContentsField,
    remove: removeOfferingContentsField,
  } = useFieldArray<FormValues['contents'][number]>({
    control,
    name: 'contents',
  });

  // theres no way to set defaults in a field array, this just ensures that
  // on load at least one field is added to the "sequenceSets" field array
  useEffect(() => {
    if (sequenceSetFields.length === 0) {
      appendSequenceSetField({
        name: undefined,
        prescribable: undefined,
        pinTarget: undefined,
        problemTypes: [],
        sequenceIds: [],
      });
    }
    // append doesn't need to be part of deps, also causes render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceSetFields]);

  const [createOffering] = useMutation<
    CreateOfferingMutation,
    CreateOfferingMutationVariables
  >(createOfferingMutation, {
    onCompleted: (data) => {
      history.push(`${routes.offerings}/${data.createOffering?.offering.id}`);

      showNotification({
        type: 'success',
        message: 'Offering created successfully',
      });
    },
    onError: () => {
      showNotification({
        type: 'error',
        message: 'Error creating offering',
      });
    },
  });

  const problemTypes = watch().problemTypes;

  const { data } = useQuery<
    CreateOfferingPageQuery,
    CreateOfferingPageQueryVariables
  >(
    gql`
      query CreateOfferingPage($problemTypes: [ProblemType!]) {
        sequences(problemTypes: $problemTypes) {
          id
          friendlyName
        }
      }
    `,
    {
      variables: {
        problemTypes,
      },
    },
  );

  const sequenceOptions = (data?.sequences ?? []).map((s) => ({
    label: s.friendlyName,
    value: s.id,
  }));

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const photoUpload = JSON.parse(values.photo);
    if (
      typeof photoUpload !== 'object' ||
      !photoUpload ||
      !('url' in photoUpload)
    ) {
      showNotification({
        type: 'error',
        message: 'Failed to decode offering photo, contact #help-technology',
      });
      return;
    }
    const ssProblemTypes = values.sequenceSets
      .filter((ss) => ss.problemTypes?.length)
      .flatMap((ss) => ss.problemTypes);
    if (!ssProblemTypes.every((ssPt) => values.problemTypes.includes(ssPt))) {
      showNotification({
        type: 'error',
        message:
          'Some sequence sets contain problem types not in the offering. Please confirm all sequence sets only contain problem types included in the offering.',
      });
      return;
    }

    if (
      values.sequenceSets.filter((ss) => ss.prescribable !== 'false').length > 1
    ) {
      showNotification({
        type: 'error',
        message: 'Only one sequence set may contain prescription sequences.',
      });
      return;
    }

    let pinTargetSyncGroupId = '';
    const sharedSyncGroupId = v4();
    const syncGroupIds = values.sequenceSets.map(({ synced, pinTarget }) => {
      const id = synced ? sharedSyncGroupId : v4();
      if (pinTarget) {
        pinTargetSyncGroupId = id;
      }
      return id;
    });

    if (!pinTargetSyncGroupId) {
      showNotification({
        type: 'error',
        message: 'One sequence set group must have pin target set to true.',
      });
      return;
    }

    await createOffering({
      variables: {
        input: {
          description: values.description.trim(),
          friendlyName: values.friendlyName.trim(),
          advertisedName: values.advertisedName.trim(),
          syncGroups: Array.from(new Set(syncGroupIds)).map((id) => ({
            id,
            pinTarget: id === pinTargetSyncGroupId,
          })),
          photoUrl: photoUpload.url,
          problemTypes: values.problemTypes,
          sequenceSets: values.sequenceSets.map((formSs, idx) => ({
            name: formSs.name.trim(),
            problemTypes:
              values.sequenceSets.length === 1
                ? values.problemTypes
                : formSs.problemTypes ?? [],
            prescribable: optionsToBoolean(formSs.prescribable),
            offeringSyncGroupId: syncGroupIds[idx],
            sequenceIds: (formSs.sequenceIds || [])
              .map((x) => x.trim())
              .filter((x) => !!x),
          })),
          tags: values.tags
            .map((tag) => tag.value.trim())
            .filter((tag) => !!tag),
          contents: values.contents
            ? values.contents.map((contentItem, i) => ({
                icon: contentItem.icon,
                description: contentItem.description,
                rank: i,
                tooltip: contentItem.tooltip,
              }))
            : [],
          advertisedShippingCadence: values.advertisedShippingCadence,
          advertisedPrice: {
            header: values.advertisedPrice.header,
            cost: Number(values.advertisedPrice.cost),
            cadenceDescription: values.advertisedPrice.cadenceDescription,
            qualifier: values.advertisedPrice.qualifier,
          },
        },
      },
    });
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <SharedOfferingForm
          register={register}
          control={control}
          errors={errors}
        />
        <div>
          <div className="text-lg font-sm py-5 border-b border-grey-200">
            Offering Contents
          </div>
          <div className="flex flex-col gap-4">
            {offeringContentsFields.map((_field, index) => (
              <div className="p-2 flex gap-4" key={index}>
                <div className="w-1/12">
                  <Dropdown
                    name={`contents[${index}].icon`}
                    label="Icon"
                    placeholder=""
                    options={contentIconOptions}
                    control={control}
                    rules={requiredValidation(`contents[${index}].icon`)}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    name={`contents[${index}].description`}
                    label="Content Description"
                    ref={register(
                      requiredValidation(`contents[${index}].description`),
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    name={`contents[${index}].tooltip`}
                    label="Content Tooltip"
                    ref={register}
                  />
                </div>
                <div className="self-end w-1/12">
                  <Button
                    fullWidth
                    color="danger"
                    onClick={() => removeOfferingContentsField(index)}
                  >
                    ✕
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="w-1/6">
            <Button
              fullWidth
              onClick={() =>
                appendOfferingContentsField({
                  icon: undefined,
                  description: undefined,
                })
              }
            >
              Add Content Item
            </Button>
          </div>
        </div>

        <div>
          <div className="text-lg font-medium py-5 border-b border-grey-200">
            Sequence Set{sequenceSetFields.length > 1 && 's'}
          </div>

          <div className="flex flex-col gap-4">
            {sequenceSetFields.map((field, index) => (
              <CreateSequenceSetFields
                control={control}
                register={register}
                numSequenceSets={sequenceSetFields.length}
                index={index}
                remove={removeSequenceSetField}
                errors={errors.sequenceSets?.[index]}
                key={field.id}
                sequenceOptions={sequenceOptions}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <div className="w-1/4">
            <Button
              fullWidth
              color="primary"
              type="submit"
              loading={formState.isSubmitting}
            >
              Create Offering
            </Button>
          </div>
          <div className="w-1/4">
            <Button
              fullWidth
              color="primary"
              variant="outline"
              onClick={(): void => {
                appendSequenceSetField({
                  name: '',
                  prescribable: 'both',
                  problemTypes: [],
                });
              }}
            >
              Add Sequence Set
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
