import { useMutation, useQuery } from '@apollo/client';
import { Loading } from 'components/loading';
import {
  UpdateHealthCoachMutation,
  UpdateHealthCoachMutationVariables,
  HealthCoachQuery,
  HealthCoachQueryVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { DeepMap } from 'react-hook-form-6';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Fields } from './types';
import {
  healthCoachQuery,
  deleteHealthCoachMutation,
  mapFieldsToGraphQLVariables,
  mapGraphQLSchemaToFields,
  updateHealthCoachMutation,
} from './utils';
import HealthCoachForm from './components/health-coach-form';

const HealthCoach = (): React.ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();
  const { healthCoachId } = useParams<{ healthCoachId: string }>();
  const { data, loading, error } = useQuery<
    HealthCoachQuery,
    HealthCoachQueryVariables
  >(healthCoachQuery, {
    variables: { healthCoachId },
    onCompleted: (data) =>
      !data.user &&
      showNotification({
        type: 'error',
        message: 'No matching Health Coach was found',
      }),
    onError: () => {
      showNotification({
        type: 'error',
        message: 'There was an error attempting to retrieve this healthCoach ',
      });
    },
  });

  const [updateHealthCoach, { loading: processing }] = useMutation<
    UpdateHealthCoachMutation,
    UpdateHealthCoachMutationVariables
  >(updateHealthCoachMutation, {
    onError: () =>
      showNotification({
        type: 'error',
        message: 'There was an error updating this practitioner',
      }),
  });

  const [deleteHealthCoach] = useMutation(deleteHealthCoachMutation, {
    onCompleted: () => {
      showNotification({
        type: 'success',
        message: 'Health Coach successfully deleted',
      });
      history.replace(routes.healthCoaches);
    },
    onError: () =>
      showNotification({
        type: 'error',
        message: 'Failed to delete Health Coach',
      }),
  });

  const onSubmit = async (
    fields: Fields,
    dirtyFields: DeepMap<Fields, true>,
    reset: (values?: Fields) => void,
  ): Promise<void> => {
    const updateResponse = await updateHealthCoach({
      variables: {
        healthCoach: mapFieldsToGraphQLVariables(
          healthCoachId,
          fields,
          dirtyFields,
        ),
      },
    });

    showNotification({
      type: 'success',
      message: 'Health Coach successfully updated.',
    });

    if (updateResponse?.data?.updateHealthCoach) {
      reset(mapGraphQLSchemaToFields(updateResponse.data.updateHealthCoach));
    }
  };

  const healthCoach = data?.user;
  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && !error && healthCoach && (
        <HealthCoachForm
          initialValues={mapGraphQLSchemaToFields(healthCoach)}
          onSubmit={onSubmit}
          onDelete={deleteHealthCoach}
          submitting={processing}
          type="update"
        />
      )}
      {!loading && (error || !healthCoach) && (
        <div>No matching health coach was found</div>
      )}
    </React.Fragment>
  );
};

export default HealthCoach;
