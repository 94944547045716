import { gql, useMutation, useQuery } from '@apollo/client';
import { Plan, PlanUpdateInput } from 'graphql/types';
import { useAlert } from 'alert';
import { Loading } from 'components/loading';
import { useNotifications } from 'notifications';
import PlanForm, { Fields } from 'pages/plans/new/plan-form';
import { ReactElement, useMemo } from 'react';
import { DeepMap } from 'react-hook-form-6';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';

const getPlanQuery = gql`
  query Plan($planId: String!) {
    plan(where: { id: $planId }) {
      id
      name
      amount
      description
      interval
      intervalCount
      repeats
      slug
    }
    treatmentsCount(where: { planId: { equals: $planId } })
  }
`;

const updatePlanMutation = gql`
  mutation UpdatePlan($id: String!, $planData: PlanUpdateInput!) {
    updateOnePlan(where: { id: $id }, data: $planData) {
      id
      name
      amount
      description
      interval
      intervalCount
      repeats
      slug
    }
  }
`;

const deletePlanMutation = gql`
  mutation DeletePlan($id: String!) {
    deleteOnePlan(where: { id: $id }) {
      id
    }
  }
`;

const mapGraphQLPlanToFields = (plan: Plan): Fields => ({
  name: plan.name,
  amount: plan.amount,
  description: plan.description || '',
  interval: plan.interval,
  intervalCount: plan.intervalCount,
  repeats: plan.repeats || 0,
  slug: plan.slug || '',
});

const mapFieldsToGraphQLUpdateData = (fields: Fields): PlanUpdateInput => ({
  name: { set: fields.name },
  amount: { set: Number(fields.amount) },
  description: { set: fields.description },
  slug: { set: fields.slug },
  interval: { set: fields.interval },
  intervalCount: { set: Number(fields.intervalCount) },
  repeats: { set: Number(fields.repeats) },
});

const PlanComponent = (): ReactElement => {
  const showNotification = useNotifications();
  const showAlert = useAlert();
  const history = useHistory();
  const { planId } = useParams<{ planId: string }>();

  const { data, loading, error, refetch } = useQuery<{
    plan: Plan;
    treatmentsCount: number;
  }>(getPlanQuery, {
    variables: { planId },
  });

  const [updatePlan, { loading: updatingInProgress }] = useMutation<{
    updateOnePlan: Plan;
  }>(updatePlanMutation, {
    onCompleted: () =>
      showNotification({
        type: 'success',
        message: 'Plan successfully updated.',
      }),
    onError: () =>
      showNotification({ type: 'error', message: 'Error updating plan' }),
  });
  const treatmentsCount = data?.treatmentsCount ?? 0;
  const existingAmount = data?.plan.amount;
  const handleSubmitUpdate = useMemo(
    () =>
      async (
        fields: Fields,
        dirtyFields: DeepMap<Fields, true>,
        reset: (values?: Fields) => void,
      ): Promise<void> => {
        if (treatmentsCount > 0 && fields.amount + '' !== existingAmount + '') {
          showNotification({
            type: 'error',
            message: `You can not update the price since it is being used in ${treatmentsCount} treatments!`,
          });
          return;
        }
        const updateResponse = await updatePlan({
          variables: {
            id: planId,
            planData: mapFieldsToGraphQLUpdateData(fields),
          },
        });

        reset(
          mapGraphQLPlanToFields(
            updateResponse?.data?.updateOnePlan || (await refetch()).data.plan,
          ),
        );
      },
    [
      planId,
      refetch,
      updatePlan,
      treatmentsCount,
      showNotification,
      existingAmount,
    ],
  );

  const [deletePlan, { loading: deletingInProgress }] = useMutation(
    deletePlanMutation,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Plan successfully deleted',
        });
        history.replace(routes.plans);
      },
      onError: () =>
        showNotification({ type: 'error', message: 'Failed to delete plan' }),
    },
  );
  const handleDelete = useMemo(
    () => async (): Promise<void> => {
      if (treatmentsCount > 0) {
        showNotification({
          type: 'error',
          message: `You can not delete the plan since it is being used in ${treatmentsCount} treatments!`,
        });
        return;
      }
      if (
        await showAlert({
          content: 'Are you sure you want to delete this plan?',
        })
      ) {
        await deletePlan({ variables: { id: planId } });
      }
    },
    [deletePlan, planId, showAlert, treatmentsCount, showNotification],
  );

  if (loading) {
    return <Loading />;
  }

  const plan = data?.plan;

  if (error || !plan) {
    return <div>Failed to retrieve plan.</div>;
  }

  return (
    <PlanForm
      type="update"
      initialValues={mapGraphQLPlanToFields(plan)}
      onSubmit={handleSubmitUpdate}
      submitting={updatingInProgress || deletingInProgress}
      delete={handleDelete}
    />
  );
};

export default PlanComponent;
