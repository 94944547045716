import React, { useState } from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';
import { validate } from 'uuid';
import { Link, useParams } from 'react-router-dom';
import {
  FaChevronDown,
  FaChevronUp,
  FaChevronCircleRight,
} from 'react-icons/fa';

import {
  ActivateOfferingMutation,
  ActivateOfferingMutationVariables,
  DeactivateOfferingMutation,
  DeactivateOfferingMutationVariables,
  UpdateOfferingMutation,
  GetOfferingQuery,
  GetOfferingQueryVariables,
  Maybe,
  OfferingStatus as OfferingStatusType,
  ProblemType,
  UpdateOfferingMutationVariables,
  SetOfferingVerticalsMutation,
  SetOfferingVerticalsMutationVariables,
  VerticalsComponentFragment,
  UpdateOfferingContentItemsMutation,
  UpdateOfferingContentItemsMutationVariables,
} from 'graphql/types';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { DataField } from '../../components/data-field';
import { Tag } from 'components/tag';
import { upperSnakeCaseToCapitalCase, getProblemTypeEmoji } from 'utils/misc';
import { useNotifications } from 'notifications';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { buildRoute } from 'utils/routes';
import SharedOfferingForm, { SharedFormValues } from './offering-form';
import { useFieldArray, useForm } from 'react-hook-form-6';
import { useSet } from 'react-use';
import Select from 'react-select';
import { Label } from 'components/label';
import { Dropdown } from 'components/dropdown';
import { icons } from 'pages/offerings/icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { Input } from 'components/react-hook-form-6/input';
import { requiredValidation } from 'utils/form-validation';

const headingStyle = 'flex px-4 py-5 border-b border-grey-200';

const updateOfferingContentsMutation = gql`
  mutation UpdateOfferingContentItems(
    $input: UpdateOfferingContentItemsInput!
  ) {
    updateOfferingContentItems(input: $input) {
      offering {
        id
        contents {
          id
          icon
          description
          rank
          tooltip
        }
      }
    }
  }
`;

export default function Offering(): React.ReactElement {
  const notifications = useNotifications();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { data, loading } = useQuery<
    GetOfferingQuery,
    GetOfferingQueryVariables
  >(
    gql`
      query GetOffering($id: ID!) {
        offering(id: $id) {
          id
          friendlyName
          status
          problemTypes
          description
          photoUrl
          tags
          sequenceSets {
            id
            name
            prescribableFilter
            problemTypesFilter
            offeringSyncGroup {
              id
              pinTarget
            }
            defaultSequence {
              id
              friendlyName
              internalName
            }
            sequences {
              id
              friendlyName
              status
            }
          }
          advertisedPrice {
            id
            header
            qualifier
          }
          advertisedCostCadence
          advertisedCost
          advertisedShippingCadence
          advertisedName
          contents {
            id
            rank
            icon
            description
            tooltip
          }
          ...VerticalsComponent
        }
      }
      ${verticalsComponentFragment}
    `,
    {
      variables: {
        id: offeringId,
      },
      skip: !validate(offeringId),
    },
  );

  const [updateOffering, { loading: loadingUpdateOffering }] = useMutation<
    UpdateOfferingMutation,
    UpdateOfferingMutationVariables
  >(
    gql`
      mutation UpdateOffering($input: UpdateOfferingInput!) {
        updateOffering(input: $input) {
          offering {
            id
            friendlyName
            description
            photoUrl
            tags
            advertisedCostCadence
            advertisedCost
            advertisedPrice {
              id
              header
              qualifier
            }
            advertisedName
          }
        }
      }
    `,
    {
      onCompleted: () =>
        notifications({
          type: 'success',
          message: 'Offering successfully updated.',
        }),
      onError: () =>
        notifications({ type: 'error', message: 'Error updating offering' }),
    },
  );

  const handleFormSubmit = async (values: SharedFormValues): Promise<void> => {
    const photoUpload = JSON.parse(values.photo);
    if (
      typeof photoUpload !== 'object' ||
      !photoUpload ||
      !('url' in photoUpload)
    ) {
      notifications({
        type: 'error',
        message: 'Failed to decode offering photo, contact #help-technology',
      });
      return;
    }

    await updateOffering({
      variables: {
        input: {
          id: offeringId,
          description: values.description.trim(),
          friendlyName: values.friendlyName.trim(),
          advertisedName: values.advertisedName.trim(),
          photoUrl: photoUpload.url,
          tags: values.tags
            .map((tag) => tag.value.trim())
            .filter((tag) => !!tag),
          advertisedPrice: {
            header: values.advertisedPrice.header,
            cost: Number(values.advertisedPrice.cost),
            cadenceDescription: values.advertisedPrice.cadenceDescription,
            qualifier: values.advertisedPrice.qualifier,
          },
          advertisedShippingCadence: values.advertisedShippingCadence,
        },
      },
    });
  };

  const [activateOffering, { loading: loadingActivateOffering }] = useMutation<
    ActivateOfferingMutation,
    ActivateOfferingMutationVariables
  >(
    gql`
      mutation ActivateOffering($id: ID!) {
        activateOffering(input: { id: $id }) {
          offering {
            id
            status
          }
        }
      }
    `,
    {
      variables: { id: offeringId },
      onError: (err) => {
        notifications({ type: 'error', message: err.message });
      },
    },
  );

  const [deactivateOffering, { loading: loadingDeactivateOffering }] =
    useMutation<
      DeactivateOfferingMutation,
      DeactivateOfferingMutationVariables
    >(
      gql`
        mutation DeactivateOffering($id: ID!) {
          deactivateOffering(input: { id: $id }) {
            offering {
              id
              status
            }
          }
        }
      `,
      {
        variables: { id: offeringId },
        onError: (err) => {
          notifications({ type: 'error', message: err.message });
        },
      },
    );

  const offering = data?.offering;

  if (loading) {
    return <Loading />;
  }

  if (!offering) {
    return (
      <div>No offering with the id: &quot;{offeringId}&quot; was found.</div>
    );
  }

  const sequenceSetsBySyncGroup = new Map<
    string,
    NonNullable<GetOfferingQuery['offering']>['sequenceSets']
  >();

  for (const ss of offering.sequenceSets) {
    if (!ss.offeringSyncGroup) {
      throw new Error(
        `expected offering sync group id on sequence set with id: "${ss.id}"`,
      );
    }

    const existing = sequenceSetsBySyncGroup.get(ss.offeringSyncGroup.id) ?? [];
    existing.push(ss);
    sequenceSetsBySyncGroup.set(ss.offeringSyncGroup.id, existing);
  }

  const syncedSequenceSets: NonNullable<
    GetOfferingQuery['offering']
  >['sequenceSets'] = [];

  const unsyncedSequenceSets: NonNullable<
    GetOfferingQuery['offering']
  >['sequenceSets'] = [];

  for (const ss of sequenceSetsBySyncGroup.values()) {
    if (ss.length > 1) {
      if (syncedSequenceSets.length) {
        throw new Error('expected only a single sync group');
      }
      syncedSequenceSets.push(...ss);
    } else {
      unsyncedSequenceSets.push(ss[0]);
    }
  }

  return (
    <>
      <div className="mb-5">
        <div className="flex justify-end space-x-2">
          <div>
            <Button
              fullWidth
              onClick={activateOffering}
              loading={loadingActivateOffering}
            >
              Activate
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              loading={loadingDeactivateOffering}
              onClick={deactivateOffering}
              disabled={offering.status !== 'AVAILABLE'}
              variant="outline"
              color="danger"
            >
              Deactivate
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="bg-white rounded shadow">
            <div>
              <DataField label="ID">
                <CopyToClipboardButton value={offering.id} />{' '}
                <span className="text-xs">{offering.id}</span>
              </DataField>
              <DataField label="Status">
                <OfferingStatus status={offering.status} />
              </DataField>
            </div>
          </div>
        </div>

        <div className="py-4">
          <UpdateOfferingForm
            handleFormSubmit={handleFormSubmit}
            loading={loadingUpdateOffering}
            offering={offering}
          />
        </div>

        <Verticals offering={offering} />

        <ContentItems
          offeringId={offeringId}
          contentItems={
            [...offering.contents].sort((i1, i2) => i1.rank - i2.rank) ?? []
          }
        />

        <div className="pt-8">
          {syncedSequenceSets.length > 0 && (
            <div className="border-2 border-purple-300 bg-purple-100 rounded-md">
              <div className="p-3">
                <h2 className={`text-lg font-medium`}>
                  Synced Sequence Set{offering.sequenceSets.length > 1 && 's'}
                </h2>
                <p className="text-gray-500 pb-2">
                  Sequences selected from synced sequence sets will be
                  connected. Any actions (e.g. reschedules, pauses, immediate
                  refills) are performed against all synchronised sequences.
                </p>
                <div className="flex gap-8 flex-col">
                  {syncedSequenceSets.map((ss) => (
                    <SequenceSet key={ss.id} sequenceSet={ss} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {unsyncedSequenceSets.length > 0 && (
            <>
              <h2 className={`text-lg font-medium pb-2 pt-8`}>
                Sequence Set{offering.sequenceSets.length > 1 && 's'}
              </h2>
              <div className="flex gap-8 flex-col">
                {unsyncedSequenceSets.map((ss) => (
                  <SequenceSet key={ss.id} sequenceSet={ss} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

type OfferingContentsFormValues = {
  contents?: { icon: string; description: string; tooltip: string | null }[];
};

const ContentItems = ({
  offeringId,
  contentItems,
}: {
  offeringId: string;
  contentItems: {
    icon: string;
    id: string;
    description: string;
    rank: number;
    tooltip?: string | null | undefined;
  }[];
}): JSX.Element => {
  const notifications = useNotifications();
  const {
    formState: { isValid },
    handleSubmit,
    register,
    control,
  } = useForm<OfferingContentsFormValues>({
    mode: 'onChange',
    defaultValues: { contents: contentItems },
  });

  const {
    fields: offeringContentsFields,
    append: appendOfferingContentsField,
    remove: removeOfferingContentsField,
  } = useFieldArray({
    control,
    name: 'contents',
  });

  const [
    updateOfferingContentItems,
    { loading: loadingUpdateOfferingContentItems },
  ] = useMutation<
    UpdateOfferingContentItemsMutation,
    UpdateOfferingContentItemsMutationVariables
  >(updateOfferingContentsMutation, {
    onCompleted: () =>
      notifications({
        type: 'success',
        message: 'Offering contents successfully updated.',
      }),
    onError: () =>
      notifications({
        type: 'error',
        message: 'Error updating offering contents',
      }),
  });

  const handleFormSubmit = async (
    values: OfferingContentsFormValues,
  ): Promise<void> => {
    await updateOfferingContentItems({
      variables: {
        input: {
          offeringId,
          contentItems:
            values.contents?.map((contentItem, index) => ({
              icon: contentItem.icon,
              description: contentItem.description,
              rank: index,
              tooltip: contentItem.tooltip,
            })) ?? [],
        },
      },
    });
  };

  const contentIconOptions = icons.map((icon) => ({
    label: icon,
    value: renderToStaticMarkup(icon),
  }));

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col space-b-3 gap-5">
        <div className="text-lg font-medium py-5 border-b border-grey-200">
          Content Item{contentItems.length > 1 && 's'}
        </div>

        <div className="p-4 bg-white shadow-card">
          {offeringContentsFields.length === 0 && (
            <div>There are no content items associated with this offering.</div>
          )}

          {offeringContentsFields.map((field, index) => (
            <div className="p-2 flex gap-4" key={field.id}>
              <div className="w-1/12">
                <Dropdown
                  name={`contents.[${index}].icon`}
                  label="Icon"
                  placeholder=""
                  rules={{ required: true }}
                  options={contentIconOptions}
                  control={control}
                />
              </div>
              <div className="w-1/2">
                <Input
                  name={`contents[${index}].description`}
                  label="Content Description"
                  ref={register(
                    requiredValidation(`contents.[${index}].description`),
                  )}
                  defaultValue={field.description}
                />
              </div>
              <div className="w-1/2">
                <Input
                  name={`contents[${index}].tooltip`}
                  label="Content Tooltip"
                  ref={register()}
                  defaultValue={field.tooltip}
                />
              </div>
              <div className="self-end w-1/12">
                <Button
                  fullWidth
                  color="danger"
                  onClick={() => removeOfferingContentsField(index)}
                >
                  ✕
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row justify-between gap-4">
          <Button
            fillHeight
            onClick={() =>
              appendOfferingContentsField({
                icon: undefined,
                description: undefined,
              })
            }
          >
            Add Content Item
          </Button>
          <Button
            fillHeight
            loading={loadingUpdateOfferingContentItems}
            disabled={!isValid}
            type="submit"
          >
            Save Content Items
          </Button>
        </div>
      </div>
    </form>
  );
};

const UpdateOfferingForm = ({
  offering,
  loading,
  handleFormSubmit,
}: {
  offering: NonNullable<GetOfferingQuery['offering']>;
  loading: boolean;
  handleFormSubmit: (values: SharedFormValues) => Promise<void>;
}): JSX.Element => {
  const tagOptions = offering.tags.map((tag) => ({
    label: tag,
    value: tag,
  }));

  const {
    formState: { isValid },
    handleSubmit,
    register,
    control,
    errors,
  } = useForm<SharedFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...offering,
      tags: tagOptions,
      photo: JSON.stringify({ url: offering.photoUrl }),
      advertisedPrice: {
        ...offering.advertisedPrice,
        cost: offering.advertisedCost ?? undefined,
        cadenceDescription: offering.advertisedCostCadence ?? undefined,
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <SharedOfferingForm
        disableProductTypes={true}
        register={register}
        control={control}
        errors={errors}
        defaultTagOptions={offering.tags.map((tag) => ({
          label: tag,
          value: tag,
        }))}
      />
      <div className="flex justify-end">
        <div className="w-1/4 py-4">
          <Button
            fullWidth
            color="primary"
            type="submit"
            loading={loading}
            disabled={loading || !isValid}
          >
            Update Offering
          </Button>
        </div>
      </div>
    </form>
  );
};

const SequenceSet = ({
  sequenceSet,
}: {
  sequenceSet: NonNullable<
    GetOfferingQuery['offering']
  >['sequenceSets'][number];
}): JSX.Element => {
  const activeSequences: NonNullable<
    GetOfferingQuery['offering']
  >['sequenceSets'][number]['sequences'] = [];

  const inactiveSequences: NonNullable<
    GetOfferingQuery['offering']
  >['sequenceSets'][number]['sequences'] = [];

  for (const sequence of sequenceSet.sequences ?? []) {
    if (sequence.status === 'AVAILABLE') {
      activeSequences.push(sequence);
    } else {
      inactiveSequences.push(sequence);
    }
  }

  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="overflow-hidden bg-white rounded shadow">
      <h2 className={`text-lg font-medium ${headingStyle}`}>
        {sequenceSet.name}
      </h2>

      <div>
        <DataField label="ID">
          <CopyToClipboardButton value={sequenceSet.id} />
          <span className="text-xs">{sequenceSet.id}</span>
        </DataField>
        <DataField label="Sync Group ID">
          <CopyToClipboardButton
            value={sequenceSet.offeringSyncGroup?.id ?? '-'}
          />
          <span className="text-xs">{sequenceSet.offeringSyncGroup?.id}</span>
        </DataField>
        <DataField label="Pin Target">
          <pre className="text-xs">
            {JSON.stringify(sequenceSet.offeringSyncGroup?.pinTarget)}
          </pre>
        </DataField>
        <DataField label="Prescribable">
          <span className="text-xs">
            {getPrescribableFilterCopy(sequenceSet.prescribableFilter)}
          </span>
        </DataField>
        <DataField label="Problem Types">
          {sequenceSet.problemTypesFilter.map((pt) => (
            <ProblemTypeIcon key={pt} pt={pt} />
          ))}
        </DataField>

        <div
          className="even:bg-white-100 odd:bg-gray-100 px-3 py-3 h-14 flex items-center justify-between cursor-pointer"
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
          <div>
            <div className="text-sm leading-5 font-medium text-gray-500">
              Selectable Sequences
            </div>
            <div className="text-xs text-gray-400">
              Sequences that fit within the filters for this sequence set
            </div>
          </div>
          {accordionOpen ? (
            <FaChevronUp className="text-zinc-500 hover:text-zinc-600" />
          ) : (
            <FaChevronDown className="text-zinc-500 hover:text-zinc-600" />
          )}
        </div>

        {accordionOpen && (
          <>
            <div className="border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
              <h3>Active</h3>
            </div>
            <ul className="divide-y divide-gray-100">
              {activeSequences.map((s) => (
                <li key={s.id}>
                  <Link
                    className="flex justify-between items-center px-3 py-3 hover:bg-gray-50 cursor-pointer"
                    to={buildRoute.sequence(s.id)}
                  >
                    <div className="flex flex-row items-center gap-6">
                      <div className="flex flex-col">
                        <div className="text-sm">{s.friendlyName}</div>
                        <pre className="text-xs text-stone-400">{s.id}</pre>
                      </div>
                      {sequenceSet.defaultSequence?.id === s.id &&
                        // showing RX sequence set defaults would be confusing as patients don't make this choice
                        // and will be purchasing the rx sequence from the problem type default
                        !sequenceSet.prescribableFilter && (
                          <div className="tooltip px-2 py-1 border border-gray-600 bg-primary text-white rounded text-xs">
                            Default
                            <span className="tooltiptext text-xs !bg-primary-300 !text-black p-2 rounded-md">
                              This sequence will be used in purchase flows in
                              lieu of the customer making a selection (i.e.,
                              Tiers, Add-ons)
                            </span>
                          </div>
                        )}
                    </div>
                    <FaChevronCircleRight className="h-5 w-5 flex-none text-gray-400" />
                  </Link>
                </li>
              ))}
            </ul>
            {inactiveSequences.length > 0 && (
              <>
                <div className="border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                  <h3>Inactive</h3>
                </div>
                <ul className="divide-y divide-gray-100">
                  {inactiveSequences.map((s) => (
                    <li key={s.id}>
                      <Link
                        className="flex justify-between items-center px-3 py-3 hover:bg-gray-50 cursor-pointer"
                        to={buildRoute.sequence(s.id)}
                      >
                        <div className="flex flex-col">
                          <div className="text-sm">{s.friendlyName}</div>
                          <pre className="text-xs text-stone-400">{s.id}</pre>
                        </div>
                        <FaChevronCircleRight className="h-5 w-5 flex-none text-gray-400" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const OfferingStatus = ({
  status,
}: {
  status: OfferingStatusType;
}): JSX.Element => {
  switch (status) {
    case 'AVAILABLE':
      return (
        <Tag size="small" color="green">
          Available
        </Tag>
      );
    case 'DRAFT':
      return (
        <Tag size="small" color="gray">
          Draft
        </Tag>
      );
    case 'UNAVAILABLE':
      return (
        <Tag size="small" color="blue">
          Unavailable
        </Tag>
      );
    default:
      return <div>{status}</div>;
  }
};

const ProblemTypeIcon = ({ pt }: { pt: ProblemType }): JSX.Element => (
  <div className="inline-block px-3 py-1 mx-1 border border-gray-600 rounded">
    {getProblemTypeEmoji(pt)} {upperSnakeCaseToCapitalCase(pt)}
  </div>
);

const getPrescribableFilterCopy = (
  prescribableFilter: Maybe<boolean>,
): string => {
  if (prescribableFilter === true) {
    return 'Only contains prescription sequences';
  }
  if (prescribableFilter === false) {
    return 'Only contains non-prescription sequences (e.g. OTC, access)';
  }

  return 'Contains both prescription and non-prescription sequences';
};

const offeringVerticalsFragment = gql`
  fragment OfferingVerticals on Offering {
    id
    upsells {
      id
      friendlyName
    }
    downsells {
      id
      friendlyName
    }
  }
`;

const verticalsComponentFragment = gql`
  fragment VerticalsComponent on Offering {
    ...OfferingVerticals
    switchCandidates {
      id
      friendlyName
    }
  }
  ${offeringVerticalsFragment}
`;

function Verticals(props: { offering: VerticalsComponentFragment }) {
  const [setVerticalsMutation, { loading }] = useMutation<
    SetOfferingVerticalsMutation,
    SetOfferingVerticalsMutationVariables
  >(gql`
    mutation SetOfferingVerticals($input: SetOfferingVerticalsInput!) {
      setOfferingVerticals(input: $input) {
        offering {
          ...OfferingVerticals
        }
      }
    }
    ${offeringVerticalsFragment}
  `);

  const [upsellOfferingIdsSet, upsellOfferingIdsSetOperations] = useSet(
    new Set(props.offering.upsells?.map((o) => o.id) ?? []),
  );
  const [downsellOfferingIdsSet, downsellOfferingIdsSetOperations] = useSet(
    new Set(props.offering.downsells?.map((o) => o.id) ?? []),
  );

  const upsellOptions =
    props.offering.switchCandidates
      ?.filter((offering) => !downsellOfferingIdsSetOperations.has(offering.id))
      .map((offering) => ({
        label: offering.friendlyName,
        value: offering.id,
      })) ?? [];
  const downsellOptions =
    props.offering.switchCandidates
      ?.filter((offering) => !upsellOfferingIdsSetOperations.has(offering.id))
      .map((offering) => ({
        label: offering.friendlyName,
        value: offering.id,
      })) ?? [];

  const showNotification = useNotifications();

  let hasChanges = false;
  const upsellOfferingIdsArray = Array.from(upsellOfferingIdsSet);
  for (
    let i = 0;
    i <
    Math.max(props.offering.upsells?.length ?? 0, upsellOfferingIdsSet.size);
    i++
  ) {
    if (props.offering.upsells?.[i]?.id !== upsellOfferingIdsArray[i]) {
      hasChanges = true;
      break;
    }
  }
  const downsellOfferingIdsArray = Array.from(downsellOfferingIdsSet);
  for (
    let i = 0;
    i <
    Math.max(
      props.offering.downsells?.length ?? 0,
      downsellOfferingIdsSet.size,
    );
    i++
  ) {
    if (props.offering.downsells?.[i]?.id !== downsellOfferingIdsArray[i]) {
      hasChanges = true;
      break;
    }
  }

  return (
    <div className="flex flex-col space-y-3 py-4">
      <h2 className="text-lg font-medium">Verticals</h2>
      <p>
        Upsells and downsells will be shown to the customer during purchase
        activation and on the profile screen.
      </p>
      <VerticalSelect
        label="Upsells"
        tooltip="Upsells are presented to users in the order configured here"
        defaultValue={
          props.offering.upsells?.map((o) => ({
            label: o.friendlyName,
            value: o.id,
          })) ?? null
        }
        options={upsellOptions}
        onChange={(e) => {
          for (const v of upsellOfferingIdsSet) {
            upsellOfferingIdsSetOperations.remove(v);
          }
          if (!e) {
            return;
          }
          for (const item of e) {
            upsellOfferingIdsSetOperations.add(item.value);
          }
        }}
      />
      <VerticalSelect
        label="Downsells"
        tooltip="Downsells are presented to users in the order configured here"
        defaultValue={
          props.offering.downsells?.map((o) => ({
            label: o.friendlyName,
            value: o.id,
          })) ?? null
        }
        options={downsellOptions}
        onChange={(e) => {
          for (const v of downsellOfferingIdsSet) {
            downsellOfferingIdsSetOperations.remove(v);
          }
          if (!e) {
            return;
          }
          for (const item of e) {
            downsellOfferingIdsSetOperations.add(item.value);
          }
        }}
      />
      <div className="pt-2 flex justify-end">
        <Button
          type="button"
          fullWidth={false}
          loading={loading}
          disabled={!hasChanges || loading}
          onClick={async () => {
            try {
              await setVerticalsMutation({
                variables: {
                  input: {
                    offeringId: props.offering.id,
                    upsellOfferingIds: Array.from(upsellOfferingIdsSet),
                    downsellOfferingIds: Array.from(downsellOfferingIdsSet),
                  },
                },
              });
              showNotification({
                message: 'Verticals updated succesfully',
                type: 'success',
              });
            } catch {
              showNotification({
                message: 'Something went wrong updating offering verticals',
                type: 'error',
              });
            }
          }}
        >
          Update verticals
        </Button>
      </div>
    </div>
  );
}

type Option = {
  label: string;
  value: string;
};

function VerticalSelect(props: {
  label: string;
  tooltip: string;
  defaultValue: Array<Option> | null;
  options: Array<Option>;
  onChange(e?: ReadonlyArray<Option> | null): void;
}) {
  return (
    <div>
      <div className="mb-3 flex flex-col gap-1">
        <Label tooltip={{ hoverText: props.tooltip }}>{props.label}</Label>
      </div>
      <Select
        isMulti
        defaultValue={props.defaultValue}
        options={props.options}
        onChange={(e) => props.onChange(e)}
        menuPlacement="top"
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
}
