import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form-6';
import { requiredValidation } from 'utils/form-validation';
import { format, isPast } from 'date-fns';
import clsx from 'clsx';
import { Tag } from '../tag';
import {
  DiscountCode,
  DiscountCodeAllowedCustomerType,
  DiscountStage,
  OtcDiscountProductsQuery,
  OtcDiscountProductsQueryVariables,
} from 'graphql/types';
import { config } from 'config';

export type DiscountCodeFormFields = {
  name: string;
  code: string;
  type: DiscountCode['type'];
  amount: number;
  maxAllowedUses: string;
  allowedCustomerType: DiscountCodeAllowedCustomerType | '';
  stages: DiscountCode['stages'];
  products: string[];
  startsAt?: string;
  expiresAt?: string;
};

type SharedProps = {
  onSubmit: (
    fields: DiscountCodeFormFields,
    reset: (values?: DiscountCodeFormFields) => void,
  ) => Promise<void>;
  submitting: boolean;
  initialValues?: DiscountCodeFormFields;
};

type UpdateProps = {
  type: 'update';
  initialValues: DiscountCodeFormFields;
  delete: (fields: DiscountCodeFormFields) => Promise<void>;
};

type CreateProps = {
  type: 'create';
};

type Props = SharedProps & (UpdateProps | CreateProps);

const discountTypes: (Option & { value: DiscountCodeFormFields['type'] })[] = [
  { label: 'Fixed', value: 'FIXED' },
  { label: 'Percentage', value: 'PERCENTAGE' },
];

const discountStages: (Option & { value: DiscountStage })[] = [
  {
    label: 'Order payment',
    value: 'ORDER_PAID',
  },
  {
    label: 'Consultation Payment',
    value: 'CONSULTATION_PAID',
  },
  {
    label: 'Recurring Order Payment',
    value: 'RECURRING_ORDER_DISCOUNT',
  },
  {
    label: 'OTC Cart (Profile)',
    value: 'OTC_CART',
  },
];

const DATETIME_PICKER_FORMAT = `yyyy-MM-dd'T'HH:mm`;

const ALLOWED_CUSTOMER_TYPE_OPTIONS: Option[] = [
  { label: 'All patients', value: '' },
  {
    label: 'New patients only (Patients with NO paid RX order)',
    value: 'NEW',
  },
  {
    label: 'Existing patients only (Patients with at least one paid RX order)',
    value: 'EXISTING',
  },
];

export const DiscountCodeForm = ({
  onSubmit,
  submitting,
  ...props
}: Props): ReactElement => {
  const { data } = useQuery<
    OtcDiscountProductsQuery,
    OtcDiscountProductsQueryVariables
  >(
    gql`
      query OtcDiscountProducts {
        products(where: { productType: { equals: OTC } }) {
          id
          name
        }
      }
    `,
    {
      skip: ['juniper-uk', 'compound-uk', 'juniper-de', 'juniper-jp'].includes(
        config.brand,
      ),
    },
  );

  const productOptions: Option[] =
    data?.products?.map((product) => ({
      label: product.name,
      value: product.id,
    })) ?? [];
  const formatDate = (dateString: string | undefined): string | undefined => {
    return dateString
      ? format(Date.parse(dateString), DATETIME_PICKER_FORMAT)
      : undefined;
  };

  const {
    control,
    errors,
    formState: { isDirty, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<DiscountCodeFormFields>({
    defaultValues:
      props.type === 'update'
        ? {
            ...props.initialValues,
            expiresAt: formatDate(props.initialValues?.expiresAt),
            startsAt: formatDate(props.initialValues?.startsAt),
          }
        : {},
    mode: 'onChange',
  });

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={handleSubmit((fields: DiscountCodeFormFields) => {
        onSubmit(fields, reset);
      })}
    >
      <div className="flex gap-4">
        <div className="w-full">
          {props?.initialValues?.expiresAt &&
            isPast(Date.parse(props?.initialValues?.expiresAt)) && (
              <Tag size="small" color="red">
                Expired
              </Tag>
            )}
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2">
          <Input
            name="name"
            label="Name"
            autoFocus
            ref={register(requiredValidation('name'))}
            errorMessage={errors.name?.message}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="code"
            label="code"
            autoFocus
            ref={register(requiredValidation('code'))}
            errorMessage={errors.code?.message}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <Dropdown
            name="type"
            label="Type"
            options={discountTypes}
            control={control}
            rules={requiredValidation('type')}
            errorMessage={errors.type?.message}
          />
        </div>
        <div className="w-1/2">
          <Input
            name="amount"
            label="amount"
            autoFocus
            ref={register(requiredValidation('amount'))}
            errorMessage={errors.amount?.message}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <Dropdown
            name="stages"
            label="Discount stages"
            isMulti
            options={discountStages}
            control={control}
            rules={{ required: true }}
          />
        </div>
        <div className="w-1/2">
          <Dropdown
            name="products"
            label="Products (Applied to all products if not specified)"
            isMulti
            options={productOptions}
            control={control}
            placeholder="All products"
          />
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2">
          <Input
            type="datetime-local"
            name="startsAt"
            label={'starts date'}
            ref={register({ required: false })}
            defaultValue={formatDate(props?.initialValues?.startsAt)}
          />
        </div>
        <div className="w-1/2">
          <Input
            type="datetime-local"
            name="expiresAt"
            label={'Expiry Date'}
            ref={register({ required: false })}
            defaultValue={formatDate(props?.initialValues?.expiresAt)}
          />
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2">
          <Input
            type="number"
            step={1}
            name="maxAllowedUses"
            label={'How many times can a patient use this discount code?'}
            ref={register({
              required: false,
              validate: {
                maxAllowedUses: (value: string): boolean | string => {
                  return (
                    Number(value) >= 1 ||
                    !value ||
                    'The value must be empty or great than zero'
                  );
                },
              },
            })}
            errorMessage={errors.maxAllowedUses?.message}
            placeholder="Unlimited"
          />
        </div>
        <div className="w-1/2">
          <Dropdown
            name="allowedCustomerType"
            placeholder="All patients"
            label="Allowed Patient Types (Based on the number of paid RX orders)"
            options={ALLOWED_CUSTOMER_TYPE_OPTIONS}
            control={control}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className={clsx({ hidden: props.type === 'create' })}>
          <Button
            fullWidth
            variant="outline"
            loading={submitting}
            onClick={
              props.type === 'update'
                ? handleSubmit((fields: DiscountCodeFormFields) => {
                    props.delete(fields);
                  })
                : undefined
            }
          >
            Deactivate
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            type="submit"
            loading={submitting}
            disabled={!isDirty || !isValid}
          >
            {props.type === 'create' ? 'Create' : 'Save changes'}
          </Button>
        </div>
      </div>
    </form>
  );
};
