import { gql, useApolloClient } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { format, addMinutes, max } from 'date-fns';
import { formatDate } from '@eucalyptusvc/lib-localization';
import {
  ReschedulePurchaseSyncGroupFragment,
  ResumePurchaseSyncGroupMutation,
  ResumePurchaseSyncGroupMutationVariables,
} from 'graphql/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form-6';
import { config } from 'config';
import { brandFromEngineBrand } from '@eucalyptusvc/lib-adapters';
import clsx from 'clsx';

interface Props {
  syncGroup: ReschedulePurchaseSyncGroupFragment;
  onClose: () => void;
}

const resumePurchaseSyncGroupDocument = gql`
  mutation resumePurchaseSyncGroup($input: ResumePurchaseSyncGroupInput!) {
    resumePurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        status
        nextOrderDueAt
        actions {
          id
        }
        sequenceContexts {
          id
          status
          nextMoveDue
          nextOrderDue
          mermaid
          overrides {
            id
          }
        }
      }
    }
  }
`;

function toInputString(date: Date): string {
  return format(date, `yyyy-MM-dd'T'HH:mm`);
}

function ResumePurchaseSyncGroup(props: Props) {
  const apollo = useApolloClient();
  const [loading, setLoading] = useState(false);

  let minScheduleNextOrderAt = new Date();
  if (props.syncGroup.nextOrderDueAt) {
    minScheduleNextOrderAt = max([
      new Date(props.syncGroup.nextOrderDueAt),
      minScheduleNextOrderAt,
    ]);
  }

  const form = useForm<{ scheduleNextOrderAt: string }>({
    mode: 'onChange',
    defaultValues: {
      scheduleNextOrderAt: toInputString(
        // Add some extra time to ensure default value passes
        // field validation without needing user input.
        addMinutes(minScheduleNextOrderAt, 15),
      ),
    },
  });

  const resumable = !!props.syncGroup.actions?.some(
    (a) => a.__typename === 'ResumePurchaseSyncGroupAction',
  );

  const resume = form.handleSubmit(async (fields): Promise<void> => {
    setLoading(true);

    try {
      await apollo.mutate<
        ResumePurchaseSyncGroupMutation,
        ResumePurchaseSyncGroupMutationVariables
      >({
        mutation: resumePurchaseSyncGroupDocument,
        variables: {
          input: {
            purchaseSyncGroupId: props.syncGroup.id,
            scheduleNextOrderAt: new Date(
              fields.scheduleNextOrderAt,
            ).toISOString(),
          },
        },
      });
    } finally {
      setLoading(false);
      props.onClose();
    }
  });

  const pluralisedSequence =
    (props.syncGroup.sequenceContexts?.length ?? 0) > 1
      ? 'sequences'
      : 'sequence';

  return (
    <Modal show onClose={props.onClose}>
      <form
        onSubmit={resume}
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mx-auto text-gray-800"
      >
        <h3 className="text-lg leading-6 font-medium mb-3">
          Resume {pluralisedSequence}
        </h3>
        {resumable ? (
          <p className="mb-3">
            The {pluralisedSequence} will be resumed and scheduled so that the
            next order will happen at{' '}
            <span className="font-medium">
              {formatDate(
                brandFromEngineBrand(config.brand),
                new Date(form.watch('scheduleNextOrderAt')),
                {
                  dateStyle: 'medium',
                  timeStyle: 'full',
                },
              )}
            </span>
            .
          </p>
        ) : (
          <p className="mb-3">
            <span className="capitalize">{pluralisedSequence}</span> cannot be
            resumed. It is either already completed or active.
          </p>
        )}
        <input
          className={clsx(
            'text-lg border border-gray-400 hover:border-gray-500 rounded p-2 w-full',
            !resumable && 'hidden',
          )}
          ref={form.register({
            required: 'Scheduled date is required.',
            validate: (val) => {
              if (new Date(val) < minScheduleNextOrderAt) {
                return `Scheduled date must be after ${formatDate(
                  brandFromEngineBrand(config.brand),
                  new Date(minScheduleNextOrderAt),
                  { dateStyle: 'medium', timeStyle: 'full' },
                )}.`;
              }
            },
          })}
          type="datetime-local"
          name="scheduleNextOrderAt"
          min={toInputString(minScheduleNextOrderAt)}
          disabled={!resumable}
        />
        <div
          className={clsx(
            'mt-1.5 text-sm text-red-700',
            Object.keys(form.errors).length === 0 && 'hidden',
          )}
        >
          {form.errors.scheduleNextOrderAt?.message}
        </div>
        <div className="flex gap-2 mt-8 justify-end">
          <Button variant="outline" onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            disabled={!form.formState.isValid || !resumable}
            color="success"
            type="submit"
            loading={loading}
          >
            Resume
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ResumePurchaseSyncGroup.fragment = gql`
  fragment ResumePurchaseSyncGroup on PurchaseSyncGroup {
    id
    nextOrderDueAt
    sequenceContexts {
      id
    }
    actions {
      id
    }
  }
`;

export { ResumePurchaseSyncGroup };
