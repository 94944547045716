import { gql } from '@apollo/client';
import { ExperimentPaymentPlanVariant } from 'graphql/types';
import { Dropdown } from 'components/dropdown';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import { CustomerTreatmentView } from '../types';
import { ActionButton } from '../../../components/actionButton';

const cancelExperimentPaymentPlan = gql`
  mutation CancelExperimentPaymentPlan($treatmentId: String!) {
    cancelExperimentPaymentPlan(treatmentId: $treatmentId) {
      id
    }
  }
`;

const changeExperimentPaymentPlan = gql`
  mutation ChangeExperimentPaymentPlan(
    $treatmentId: String!
    $paymentPlan: ExperimentPaymentPlanVariant!
  ) {
    changeExperimentPaymentPlan(
      treatmentId: $treatmentId
      paymentPlan: $paymentPlan
    ) {
      id
      experimentPaymentPlan {
        id
        paymentPlan
        discountPercentage
        numberOfOrdersRemaining
      }
    }
  }
`;

// For now we want to hide the dropdown as we're not ready to deal with
// the eng/product repercussions of changing a user's payment plan while
// they're still on an existing plan.
const SHOULD_SHOW_PAYMENT_PLAN_DROPDOWN = false;

export const PaymentPlanActions = ({
  treatment,
  refetchCustomer,
}: {
  treatment: CustomerTreatmentView;
  refetchCustomer: () => void;
}): React.ReactElement | null => {
  const { watch, control } = useForm<{
    paymentPlan: ExperimentPaymentPlanVariant;
  }>({
    mode: 'onChange',
    defaultValues: {
      paymentPlan: treatment.experimentPaymentPlan?.paymentPlan,
    },
  });

  if (!treatment.experimentPaymentPlan) {
    return null;
  }

  const selectedPaymentPlan = watch().paymentPlan;

  let actionButtons: ActionButton[] = [
    {
      label: '❗️ Cancel plan',
      disabled: false,
      mutationArgs: { treatmentId: treatment.id },
      mutationGql: cancelExperimentPaymentPlan,
      refetchCustomer,
      confirmMsg:
        'Are you sure you want to cancel this payment plan? This will not cancel any existing orders, but future orders will return to the normal treatment payment plan.',
      successMsg: 'Successfully cancelled plan',
    },
  ];

  if (SHOULD_SHOW_PAYMENT_PLAN_DROPDOWN) {
    actionButtons = [
      {
        label: '✏️ Change plan',
        disabled:
          treatment.experimentPaymentPlan.paymentPlan === selectedPaymentPlan,
        mutationArgs: {
          treatmentId: treatment.id,
          paymentPlan: selectedPaymentPlan,
        },
        mutationGql: changeExperimentPaymentPlan,
        refetchCustomer,
        confirmMsg: `Are you sure you want to change this payment plan? Given the patient has paid upfront we may need to partially or fully refund their upfront payment if we go ahead with this action.`,
        successMsg: 'Successfully changed plan',
      },
      ...actionButtons,
    ];
  }

  return (
    <div className="flex justify-center items-center gap-2 p-2 border-t border-gray-200">
      {SHOULD_SHOW_PAYMENT_PLAN_DROPDOWN && (
        <div className="w-1/5">
          <Dropdown
            name="paymentPlan"
            options={treatment.experimentAvailablePaymentPlans.map((plan) => ({
              label: `${plan.cadence} (${plan.discountPercentage}%)`,
              value: plan.paymentPlan,
            }))}
            control={control}
          />
        </div>
      )}
      {actionButtons.map((button) => (
        <ActionButton
          label={button.label}
          disabled={button.disabled}
          mutationArgs={button.mutationArgs}
          mutationGql={button.mutationGql}
          refetchCustomer={refetchCustomer}
          confirmMsg={button.confirmMsg}
          successMsg={button.successMsg}
          key={button.label}
        />
      ))}
    </div>
  );
};
