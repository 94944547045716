import { gql, useQuery } from '@apollo/client';
import { Dropdown } from 'components/dropdown';
import { ReactElement } from 'react';
import { Product } from 'graphql/types';
import { Loading } from 'components/loading';
import { Control } from 'react-hook-form-6';

const getOTCProductQuery = gql`
  query ProductIdsAndNames {
    products(where: { productType: { equals: OTC } }) {
      id
      name
    }
  }
`;

type Props = {
  control: Control;
  disabled: boolean;
};

const LineItemDropDown = ({ control, disabled }: Props): ReactElement => {
  const {
    data: otcProductsData,
    loading,
    error,
  } = useQuery<{
    products: Product[];
  }>(getOTCProductQuery);

  if (loading) {
    return <Loading />;
  }

  if (error || !otcProductsData) {
    return <div>Failed to retrieve products.</div>;
  }
  const products = otcProductsData?.products.map((product) => {
    return {
      label: product.name,
      value: product.id,
    };
  });

  return (
    <Dropdown
      name="lineItem"
      label="Product"
      options={products}
      control={control}
      rules={{ required: true }}
      disabled={disabled}
    />
  );
};

export default LineItemDropDown;
