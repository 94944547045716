import React from 'react';
import { useCallback, useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory, generatePath } from 'react-router-dom';
import { useForm } from 'react-hook-form-6';
import { Cell, CellProps, Column } from 'react-table';

import {
  GetPackingBundlesListQuery,
  GetPackingBundlesListQueryVariables,
  PharmacyEnum,
} from 'graphql/types';
import { ProtectedButton } from 'components/button';
import { routes } from 'utils/routes';
import { Loading } from 'components/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { formatDateAndTime } from 'utils/misc';
import { Tag } from 'components/tag';
import { subDays } from 'date-fns/esm';
import { Dropdown } from 'components/dropdown';

const bundlesQuery = gql`
  query GetPackingBundlesList($input: PackingBundleFilterInput!) {
    packingBundles(input: $input) {
      id
      createdAt
      updatedAt
      status
      pharmacy {
        id
        name
      }
    }
  }
`;

const fulfilmentCentreFilteringOptions: Array<{
  value: PharmacyEnum | 'all';
  label: string;
}> = [
  { value: 'all', label: 'ALL' },
  {
    value: 'CLOUD',
    label: 'Cloud Brisbane',
  },
  {
    value: 'IWG',
    label: 'IWG Adelaide',
  },
  {
    value: 'UK_PHARMACY',
    label: 'UK Pharmacy',
  },
  {
    value: 'YSCP_BNE',
    label: 'YSCP Brisbane',
  },
  {
    value: 'YSCP_MEL',
    label: 'YSCP Melbourne',
  },
];

export default function PackingBundles(): React.ReactElement {
  const history = useHistory();

  const { control, watch } = useForm<{
    fulfilmentCentre: (typeof fulfilmentCentreFilteringOptions)[number]['value'];
  }>({
    mode: 'onChange',
    defaultValues: {
      fulfilmentCentre: 'all',
    },
  });

  const createdAfter = useMemo(() => subDays(new Date(), 7), []);
  const pharmacy = watch().fulfilmentCentre;

  const { data, loading, error, refetch } = useQuery<
    GetPackingBundlesListQuery,
    GetPackingBundlesListQueryVariables
  >(bundlesQuery, {
    variables: {
      input: {
        createdAfter: createdAfter.toISOString(),
      },
    },
  });

  useEffect(() => {
    refetch({
      input: {
        createdAfter: createdAfter.toISOString(),
        pharmacyId: pharmacy === 'all' ? null : pharmacy,
      },
    });
  }, [pharmacy, refetch, createdAfter]);

  const onBundleClick = useCallback(
    (cell: Cell<PackingBundle, unknown>) => {
      if (cell.column.id !== 'selection') {
        history.push(
          generatePath(routes.packingBundle, {
            bundleId: cell.row.original.id,
          }),
        );
      }
    },
    [history],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.packingBundles ?? [],
    pageNumber: 1,
    disableSortBy: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error. Please contact #help-technology.</div>;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-5">
        <div>
          Only the last 7 days of packing bundles are displayed on this page.
        </div>
        <div>
          <ProtectedButton
            variant="outline"
            requiredPermissions={['TRIGGER_PACKING_BUNDLE_CREATION']}
            onClick={(): void => history.push(routes.createPackingBundle)}
          >
            Create Bundle
          </ProtectedButton>
        </div>
      </div>
      <div className="mb-5 w-1/3">
        <Dropdown
          label="Fulfilment Centre"
          name="fulfilmentCentre"
          options={fulfilmentCentreFilteringOptions}
          control={control}
        />
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={onBundleClick}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
    </>
  );
}

type PackingBundle = NonNullable<
  GetPackingBundlesListQuery['packingBundles']
>[number];

const columns: Column<PackingBundle>[] = [
  {
    Header: 'Bundle ID',
    accessor: 'id',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/4',
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateCell,
    className: 'w-1/6',
  },
  {
    Header: 'Updated',
    accessor: 'updatedAt',
    Cell: DateCell,
    className: 'w-1/6',
  },
  {
    Header: 'Fulfillment Centre',
    accessor: 'pharmacy',
    Cell: (c) => <div>{c.value.name}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell,
    className: 'w-1/5',
  },
];

function DateCell(cell: CellProps<PackingBundle, string>): React.ReactElement {
  return <div>{formatDateAndTime(cell.value)}</div>;
}

function StatusCell(
  cell: CellProps<PackingBundle, PackingBundle['status']>,
): React.ReactElement {
  if (cell.value === 'COMPLETE') {
    return (
      <Tag size="small" color="green">
        Completed
      </Tag>
    );
  }
  if (cell.value === 'FAILED') {
    return (
      <Tag size="small" color="red">
        Failed
      </Tag>
    );
  }
  if (cell.value === 'IN_PROGRESS') {
    return (
      <Tag size="small" color="blue">
        In Progress
      </Tag>
    );
  }
  if (cell.value === 'JOB_PUBLISHED') {
    return (
      <Tag size="small" color="blue">
        Job Published
      </Tag>
    );
  }
  return <div>{cell.value}</div>;
}
