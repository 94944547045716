import React from 'react';
import { PathologyRequest } from 'graphql/types';
import { FormFields, FormResultValue, showResultInputs } from './valueHelper';
import { Control } from 'react-hook-form-6';
import { Button } from 'components/button';
import { DoctorSelect } from 'components/doctor-select';
import { parseFileUrl } from 'utils/misc';
import { FaExclamationTriangle } from 'react-icons/fa';
import clsx from 'clsx';

function isOutOfRange(result: FormResultValue): boolean {
  if (!result.number || !result.range?.high || !result.range?.low) {
    return false;
  }
  const value = parseFloat(result.number);
  const low = parseFloat(result.range.low);
  const high = parseFloat(result.range.high);
  return value < low || value > high;
}

const PathologyConfirm = ({
  pathologyRequest,
  localInputResults,
  control,
  loading,
  onSubmit,
}: {
  pathologyRequest: PathologyRequest;
  localInputResults: FormFields;
  control: Control<FormFields>;
  loading?: boolean;
  onSubmit: () => Promise<void>;
}): React.ReactElement => {
  return (
    <div className="bg-white rounded p-8 relative overflow-visible">
      <div className="heading-md text-center">
        <span className="inline-flex items-center whitespace-nowrap">
          <span className="mr-2">
            <FaExclamationTriangle className="text-yellow-500" />
          </span>{' '}
          Are you sure?
        </span>
      </div>
      <div className="text-center mb-4">
        This will be released to the practitioner
      </div>
      <div className="flex flex-row">
        <div>
          <h2 className="font-bold">Reference</h2>
          <p>{pathologyRequest.reference}</p>
        </div>
        <div className="ml-auto">
          <h2 className="font-bold">Patient</h2>
          <p>{pathologyRequest.patient?.fullName}</p>
        </div>
      </div>
      <table className="mb-4 w-full">
        <tbody>
          {showResultInputs(pathologyRequest.panels) && (
            <>
              <tr className="h-14 font-bold">
                <td>Test Name</td>
                <td>Value</td>
                <td>Note</td>
              </tr>
              {pathologyRequest?.panels?.map((panel) => {
                if (!panel) {
                  return null;
                }

                const localValues = localInputResults.panels?.[panel.id];
                if (!localValues) {
                  return null;
                }

                return Object.entries(localValues).map(([key, result]) => {
                  const testName = panel.tests?.find((test) => test?.id === key)
                    ?.name;
                  return (
                    <tr key={key} className="h-14 border-t-2">
                      <td>{testName}</td>
                      <td>
                        {result.result.type === 'POSITIVE_NEGATIVE' && (
                          <span
                            className={clsx(
                              'font-medium',
                              {
                                'text-green-500':
                                  result.result?.value === 'NEGATIVE',
                              },
                              {
                                'text-red-600':
                                  result.result?.value === 'POSITIVE',
                              },
                            )}
                          >
                            {result.result?.value}
                          </span>
                        )}
                        {result.result.type === 'UNIT_WITH_RANGE' && (
                          <span
                            className={clsx(
                              'font-medium',
                              isOutOfRange(result.result)
                                ? 'text-red-600'
                                : 'text-green-500',
                            )}
                          >
                            {result.result.number} {result.result.unit} (
                            {result.result.range?.low} -{' '}
                            {result.result.range?.high})
                          </span>
                        )}
                      </td>
                      <td>{result.note}</td>
                    </tr>
                  );
                });
              })}
            </>
          )}
          <tr className="h-8" />
          <tr className="h-14 font-bold">
            <td className="w-1/3">Lab</td>
            <td className="w-1/3">File</td>
          </tr>
          <tr className="h14">
            <td className="w-1/3 border-t-2">{localInputResults?.lab}</td>
            <td className="w-1/3 break-all border-t-2 pt-2" colSpan={2}>
              {parseFileUrl(localInputResults?.resultsUrl)?.filename}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="font-bold mb-4">Assign To</div>
      <DoctorSelect
        name="doctorId"
        control={control}
        hasAllOption={false}
        menuPlacement="top"
        showAllDoctors={false}
      />
      <div className="pt-4">
        <Button
          fullWidth
          onClick={onSubmit}
          loading={loading}
          disabled={!localInputResults.doctorId}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default PathologyConfirm;
